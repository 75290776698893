import {
  StylizedTabMainDiv,
  StylizedTabBar_horizontal,
  StylizedTabBar_div,
  StylizedTabBar_Section,
  StylizedTabBar_vertical
} from "../../styles/dashboardStyledTabStyles";
import { SubHeaderText_H2 } from "../../styles/reusable/TextStyles";

const DashboardStyledTab = ({ text=[""],position="", vertical = "false" ,active,handleClick=()=>{console.log("no func");}}) => {
  
  return (
    <StylizedTabMainDiv>
      <StylizedTabBar_div position={position} variant={vertical}>
        {text.map((value, i) => {
          return (
            <StylizedTabBar_Section variant={vertical} color={ active == i?"true":"false" } onClick={()=>handleClick(i)}>
               {vertical == "true" ? <StylizedTabBar_vertical />: <></>}
              <SubHeaderText_H2 className="noMarginBottom "   >
              
               
                
                {value}
              </SubHeaderText_H2>

              { 
                vertical == "false" && active == i ?  
                <StylizedTabBar_horizontal   />: <></>
              }
            </StylizedTabBar_Section>
          );
        })}
      </StylizedTabBar_div>
    </StylizedTabMainDiv>
  );
};

export default DashboardStyledTab;
