import { DocumentationNavMainDiv, NavMainDivLogo, NavMainDivLogoAndName, NavMainDivName, NavMainDivSecNavDiv, SecMainDivItem } from "../../styles/documentationStyles/documentationNav.styles";
import waves from "../../assets/waves.png";
import { Link, useNavigate } from 'react-router-dom';

const DocumentationNav=()=>{
    const navigate=useNavigate();
    return(
        <DocumentationNavMainDiv>
            <NavMainDivLogoAndName>
                <NavMainDivLogo src={waves}/>
                <NavMainDivName>Sixth</NavMainDivName>
            </NavMainDivLogoAndName>
            <NavMainDivSecNavDiv>
                <SecMainDivItem onClick={(e)=>{
                    window.location.href="https://trysixth.com"
                }}>Home</SecMainDivItem>
                <SecMainDivItem onClick={(e)=>{
                   navigate("/dashboard")
                }}>DashBoard</SecMainDivItem>
            </NavMainDivSecNavDiv>
        </DocumentationNavMainDiv>
    );
};
export default DocumentationNav;