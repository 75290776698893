import styled from "styled-components";


export const PenTestDetailMainDiv=styled.div`
width:100%;
flex-direction:column;
align-items:center;
justify-content:flex-start;
display:flex;
gap:32px;
margin-top: 105px;
`;

export const PenTestDetailItem=styled.div`
width:90%;
display:flex;
align-item:center;
justify-content:center;
flex-direction:column;
gap:50px;
`;

export const PenTestSection=styled.div
`
display:flex;
height:40px;
justify-content:
width:100%;
justify-content:flex-start;
align-items:center;
overflow:hidden;

gap:24px;
padding:0px;
margin:0px;


`


export const PenTestHeaderSection=styled.div
`
display:flex;
justify-content:
width:100%;
justify-content:flex-start;
align-items:start;
text-align:top;
overflow:hidden;
text-wrap: wrap;
gap:24px;
padding:0px;
margin:10px;


`

export const PenTestSection_Text=styled.div
`
display:flex;
justify-content:
width:100%;
justify-content:left;
align-items:left;
flex-direction:column;
gap:5px;
padding:0px;
margin:0px;
height:100%;

`

export const PenTestVid = styled.video `
width: 100%;
height: 100%;
object-fit:contain;
border-radius:10px;
margin:0px;
padding:0px;
`


export const PenTestImg = styled.img `
width: 100%;
height: 100%;
object-fit:contain;
border-radius:10px;
margin:0px;

padding:0px;
`


export const PenTestVid_div = styled.div`
width:100%;
height:500px;
display:flex;
justify-content:center;
align-item:center;
overflow-hidden;
flex-direction:column;
@media (max-width: 550px) {
    height:auto;
   }




`
export const DetailItemHeader=styled.div`
width:90%;
text-align:center;
display:flex;
justify-content:center;
align-item:center;
gap:18px;
flex-direction:column;

`;

export const DetailItemSummary = styled.div`
padding:15px 0px;
display:flex;
`;
