import styled from "styled-components";

const burger_menu_radius = "3.6em"

export const MainHeaderCont = styled.div`
  width: 100%;
  height: 12vh;
  background: #ffffff;
  padding: 0 3.5%;
  display: flex;
  align-items: Center;
  justify-content: space-between;
  box-sizing: border-box;

  @media (max-width: 728px) {
    padding: 0.5rem 5%;
  }
`;

export const LeftCont = styled.div`
  width: 35%;
  position: relative;

  > input {
    width: 77%;
    background: #fafafa;
    border-radius: 46px;
    padding: 1rem 5% 1rem 18%;
  }

  > i {
    position: Absolute;
    left: 1.5rem;
    top: 55%;
    transform: translate(0, -55%);
  }

  @media (max-width: 550px) {
    display: none;
  }
`;
export const Burger_Menu = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display:flex;
justify-content:left:
background: white;
transform: translateX(${(props)=> (props.clicked ? "1px":"1000px")});
overflow-x: hidden;
transition: 0.6s;
z-index:1;


`;

export const Burger_toggle_line = styled.div`
  position: absolute;
  left: 25%;
  width:${(props) =>(props.clicked  ? "3px":"30px")} ;
  height: 4px;
  background: hsla(210, 29%, 24%, 0.3);
  border-radius: 10px;
  overflow: hidden;
  transition: 0.5s;
  ::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #8C30F5;
    transform: translateX(-100%);
    transition: 0.25s;
  }
`;

export const Burger_toggle_Div = styled.div`
  position: sticky;
  top: 10px;
  left: 5vw;
  z-index: 100;
  display: block;
  width: 6em;
  height: ${(props) =>(props.clicked  ? "30px":"45px")};
  outline: none;
  cursor: pointer;

  & > :nth-child(1) {
    top: 30%;
    transform: translateY(calc(${ burger_menu_radius} *(${(props) =>(props.clicked  ? "1":"0")}) / 10)) rotate( ${(props) =>(props.clicked  ? "45deg":"0deg")});
    
  }

  & > :nth-child(2) {
    top: 50%;
    transform:scaleX(${(props) =>(props.clicked  ? "0":"1")});
    ::after {
        transition-delay: 0.1s;
      }
  }

  & > :nth-child(3) {
    top: 70%;
    transform: translateY(calc(${ burger_menu_radius} *(${(props) =>(props.clicked  ? "1":"0")}) / -5)) rotate( ${(props) =>(props.clicked  ? "-45deg":"0deg")});
    ::after {
        transition-delay: 0.2s;
      }
  }

  :hover {
    ${Burger_toggle_line}
    {
        ::after {
            transform: translateX(0);
          }

    }
  }
`;

export const RightCont = styled.div`
  display: flex;
  align-items: Center;
  gap: 60px;

  @media (max-width: 728px) {
    gap: 10px;
  }
`;
export const IconSection = styled.div`
  display: flex;
  align-items: Center;
  gap: 20px;

  > i {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #edf2f6;
    border-radius: 60px;
  }

  @media (max-width: 728px) {
    display: none;
    // visibility:hidden;
  }
`;

export const LoggedSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;

  > div:nth-child(2) {
    line-height: 15px;

    > h3 {
      width: 6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
    }
    > p {
      font-size: 12px;
    }
  }
`;

export const LoggedAvatar = styled.div`
  padding: 0px;
  background: #f2f2f2;
  color: #b3b3b3;
  border-radius: 100%;
  height: 40px;
  width: 40px;
`;

export const NotificationCount = styled.p`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: Center;
  justify-content: Center;
  position: absolute;
  font-style: normal;
  top: -1.3rem;
  right: -0.5rem;
  font-size: 10px;
  color: #fff;
  background: ${(p) => p.bg || "#216FED"};
  border-radius: 5rem;
  border: 3px solid #edf2f6;
`;

export const MobileMenuController = styled.div`
  display: none;

  > i {
    font-size: 20px;
  }

  @media (max-width: 550px) {
    display: block;
  }
`;

export const AccountPopUp = styled.div`
  position: absolute;
  top: 4rem;
  right: 0;
  width: 8rem;
  padding: 0.5rem 1rem;
  background: #fff;
  z-index: 1000;
  box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.16);
  border-radius: 4px;

  div {
    display: flex;
    align-items: Center;
    gap: 10px;
    cursor: pointer;

    :hover {
      opacity: 0.6;
    }

    > img {
      width: 1.2rem;
    }

    > p {
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      color: #808080;
    }
  }
`;
