import { useEffect, useState } from "react";
import api from "../../API/baseURL";
import useTokenExpired from "../../apiLayer/expirationEvent";



const useDashBoardHook=()=>{
    //for github
    const [baseUrl,setBaseUrl]=useState("");
    const [storageValue, setStorageValue] = useState(localStorage.getItem('token'));
    const [checkTokenExpiration]=useTokenExpired();

    const [toggle,setToggle]=useState({
        "overview":true,
        "encryption":false,
        "penTesting":false,
        "rateLimit":false, 
        "documentation": false,
        "integrations":false,
        "billing":false,
        "secureLogs":false,
        "manageKey":false,
        "developer-report":false,
        "developer_integrations":false,
    });

    const setToggleValue=(value)=>{
        const newToggle={"overview":false,
        "encryption":false,
        "penTesting":false,
        "rateLimit":false, 
        "documentation": false,
        "integrations":false,
        "billing":false,
        "secureLogs":false,
        "manageKey":false,
        "developer-report":false,
        "developer_integrations":false,
    }

        for(const key in toggle){
            if(key==value){
                newToggle[value]=true
            }
        }
        setToggle(newToggle);
    };

    const getBaseUrl=async()=>{
        try{
            const response=await api.get("/project-config/get-base-url",{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            setBaseUrl(response.data.data)
        }catch(err){
            checkTokenExpiration(err);
            console.log(err);
        }
    };

    useEffect(()=>{
        getBaseUrl();
    },[]);

    

  


    return [baseUrl,toggle,setBaseUrl,setToggle,setToggleValue,storageValue];

};
export default useDashBoardHook;