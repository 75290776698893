import styled from "styled-components";


export const DocumentationMainDiv=styled.div`
width:100vw;
height:100vh;
box-sizing: border-box;
`;

export const DocInnerDiv=styled.div`
width:100%;
height:88%;
background:#FAFAFA;
display:flex;
justify-content:center;
box-sizing: border-box;
overflow-y:auto;
overflow-x:hidden;
`;

