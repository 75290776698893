import styled from "styled-components";

export const ManageKeyMainDiv = styled.div`
  width: 83%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
`;

export const ManageKeyBodyDiv = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
`;

export const ManageKeyApiDiv = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 550px) {
    width: 100%;
  }
`;
export const ManageKeyApiHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

export const ManageKeyApiHeader_left = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: left;
  flex-direction: column;
`;

export const ManageKeyApiHeader_right = styled.div`
  width: 55%;
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: left;
  flex-direction: column;
`;

export const ManageKeyApiBodyBorder = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba(241, 228, 255, 1);
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);
`;

export const ManageKeyApiBodyBorder_head = styled.div`
  width: 100%;
  background: rgba(241, 228, 255, 1);
  display: flex;
  justify-content: space-evenly;
  align-items: left;
`;

export const ManageKeyApiField = styled.div`
  width: 35%;
  display: flex;
  justify-content: start;
  align-items: left;
  padding-left: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ManageKeyApiField_center = styled.div`
  width: 20%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-wrap:nowrap;
  text-overflow: ellipsis;
`;

export const ManageKeyApiField_end = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;

  @media (max-width: 550px) {
    width: 35%;
    gap: 5px;
  }
`;

export const ManageKeyApiBodyBorder_entry = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: left;
`;

export const ManageKeyApiCreateKeyField = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: left;
  flex-direction: column;
`;

export const ManageKeyApiCreateKeyField_head = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const ManageKeyApiCreateKeyClose = styled.div`
  color: rgba(140, 48, 245, 1);
  cursor: pointer;
`;

export const ManageKeyApiCreateBtn = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: start;
  align-items: left;
  flex-direction: column;
  margin-top: 50px;
`;

export const ManageKeyApiCreateDiv = styled.div`
  width: 70%;
  max-width: 550px;

  border-radius: 15px;
  padding: 95px 105px;
  margin-top: 50px;
  box-shadow: 4px 4px 4px 8px rgba(0, 0, 0, 0.05);
`;
