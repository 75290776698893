import styled from "styled-components";

export const SecureLogContent = styled.div`
  display: ${(props) => (props.clicked ? "block" : "none")};
  padding: 20px;
  
overflow-x:hidden;
overflow-y:scroll;

  @media (max-width: 950px) {
    padding: 0px;
  }
`;
export const CreateLogDiv = styled.div`
  width: 65%;
  border-radius: 10px;
  padding: 6px;
  margin-bottom: 10px;
  box-sizing: border-box;
  overflow-x:hidden @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 950px) {
    width: 90%;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;
export const TabDiv = styled.div`

width: 100%;
height: 100%;
margin-top: 16px;
 
width: "100%",
margin-left: "16px",

@media (max-width: 550px) {
    margin-left: "0px",
  }

`;

export const CreateLogNormDiv = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid rgba(140, 48, 245, 0.4);
  box-sizing: border-box;
  margin-top: 8px;
`;

export const CreateLogField = styled.div`
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

export const CreateLogDropDownDiv = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  margin-top: 6px;
  width: 100%;
  padding: 14px;
  border-radius: 10px;
  box-sizing: border-box;
  transition: all 0.3s ease;
`;
