
import styled from 'styled-components';


export const InnerDivBorder = styled.div`
  width: 37%;
  height: 65%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 16px;
  border: 5px solid rgba(30, 30, 30, 0.05);
  padding: 25px;
  box-sizing: border-box;

  @media (max-width: 1100px) {
    width: 45%;
    height: 75%;
    padding: 15px;
  }

  @media (max-width: 900px) {
    width: 65%;
    height: 85%;
  }

  @media (max-width: 788px) {
    justify-content: flex-start;
  }

  @media (max-width: 500px) {
    padding: 0px;
    width: 95%;
    height: 95%;
  }

  

  @media (max-height: 700px) {
    height: 90%;
  }
`;

export const DashBoardElementBackground = styled.div`
  width: 83%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const CardBorder = styled.div`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  width: 100%;
  border-radius: 8px;
  background: white;

  &.api {
    box-shadow: 2px 4px 4px 0px #00000026;
    max-width: 544px;
  }
`;

export const dashBoardElementInnerDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DashboardAlertElementStatusDiv = styled.div`
user-select:none;
font-size: 14px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
border-radius:5px;
padding:5px;
margin :5px;




background:${(props) =>
  props.variant === "pending"
    ? "rgba(217, 81, 44, 1)"
    : "rgba(241, 228, 255, 0.65)"} ;
color:${(props) =>
  props.variant === "pending" ? "white" : "rgba(24, 25, 31, 0.65)"} ;

min-width:85px;

&.noMargin
{
padding:0px;
margin :0px;

}


&.sdk {

  background: #F9F9F9;


}
&.thick{
  font-weight:600;
}


&.primary {
  
  background:${(props) =>
    props.variant === "pending"
      ? "rgba(217, 81, 44, 1)"
      : "rgba(241, 228, 255, 0.65)"} ;
  color:${(props) =>
    props.variant === "pending" ? "white" : "rgba(24, 25, 31, 0.65)"} ;
  
  }
  &.security
   {
  
    background:${(props) =>
      props.variant === "pending" ? " #D9512C" : "#34B233"} ;
    color:white} ;
    
    }
  
  
  &.primary {
    
    background:${(props) =>
      props.variant === "pending"
        ? "rgba(217, 81, 44, 1)"
        : "rgba(241, 228, 255, 0.65)"} ;
    color:${(props) =>
      props.variant === "pending" ? "white" : "rgba(24, 25, 31, 0.65)"} ;
    
    }
  
  
    
  &.payment {
    
    background:${(props) =>
      props.variant === "pending" ? "rgba(217, 81, 44, 1)" : " #8C30F5  "} ;
    color:white;
   
    
    }


&.POST
{
    background:#49cc90;
    color:white;
}


&.GET
{
    background:  #61affe;
    color:white;
}

&.Patch
{
    background: #50e3c2;
    color:white;
}

&.Delete
{
   background:#f93e3e;
   color:white;

}


@media (max-width: 550px) {
font-size: 11px;
font-weight: 400;
line-height: 14px;
 }


`;
export const DefaultDiv = styled.div``;
