import { ArrowDropDown } from "@mui/icons-material";
import {
  RegularText,
  SubHeaderText_H2,
  SubHeaderText_H3,
} from "../../styles/reusable/TextStyles";
import {
  GraphComponentGraphDropdown, 
  GraphComponentGraphDropdownButton,
  GraphComponentGraphDropdownItem,
  GraphComponentHead,
  GraphComponentMainDiv,
} from "../../styles/reusable/graphComponentsStyle";
import React, { PureComponent, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

const GraphComponent = ({
  name,
  options = [
    { duration: "Daily", data: dailyDefaultata },
    { duration: "Monthly", data: monthlyDefaultData },
    { duration: "Yearly", data: YearlyDefaultData },
  ],
}) => {
  const [graphDuration, setGraphDuration] = useState(options[0].duration);
  const [data, setGraphData] = useState(options[0].data);

  const setGraph = (graph, duration) => {
    setGraphDuration(duration);
    setGraphData(graph);
  };

  return (
    <GraphComponentMainDiv>
      <SubHeaderText_H3> {name} </SubHeaderText_H3>
      <GraphComponentHead>
        {" "}
        <SubHeaderText_H2> {name} analytics </SubHeaderText_H2>
        <GraphComponentGraphDropdownButton>
          <RegularText> {graphDuration} </RegularText>
          <ArrowDropDown />
          <GraphComponentGraphDropdown>
            {options.map((item, index) => (
              <GraphComponentGraphDropdownItem
                onClick={(e) => setGraph(item.data, item.duration)}
                id={index}
              >
                <RegularText>{item.duration} </RegularText>
              </GraphComponentGraphDropdownItem>
            ))}
          </GraphComponentGraphDropdown>
        </GraphComponentGraphDropdownButton>
      </GraphComponentHead>
      <ResponsiveContainer width="50%" height="70%">
        <AreaChart
          width={10}
          height={10}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="amount"
            stroke="#8884d8"
            fill="var(--sixth-purple)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </GraphComponentMainDiv>
  );
};

export default GraphComponent;

const dailyDefaultata = [
  {
    name: "MON",
    amount: 0,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "TUE",
    amount: 0,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "WED",
    amount: 0,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "THU",
    amount: 0,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "FRI",
    amount: 0,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "SAT",
    amount: 0,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "SUN",
    amount: 0,
    pv: 4300,
    amt: 2100,
  },
];

const monthlyDefaultData = [
  {
    name: "JAN",
    amount: 0,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "FEB",
    amount: 0,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "MAR",
    amount: 0,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "APR",
    amount: 0,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "MAY",
    amount: 0,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "JUN",
    amount: 0,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "JUL",
    amount: 0,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "AUG",
    amount: 0,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "SEP",
    amount: 0,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "OCT",
    amount: 0,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "NOV",
    amount: 0,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "DEC",
    amount: 0,
    pv: 4300,
    amt: 2100,
  },
];

const YearlyDefaultData = [
  {
    name: "2023",
    amount: 0,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "2024",
    amount: 0,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "2025",
    amount: 0,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "2026",
    amount: 0,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "2027",
    amount: 0,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "2028",
    amount: 0,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "2029",
    amount: 0,
    pv: 4300,
    amt: 2100,
  },
];
