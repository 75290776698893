import {
  ApiKeyInput,
  BoxWrap,
  BoxWrap_content,
  CopyIcon,
  SdkDocsDivItem,
  SdkDocsDivItemDiv,
  SdkDocsDivItemImg,
  SdkDocsDivItemText,
  SdkPageApiKeyDesDiv,
  SdkPageContent,
  SdkPageCopyApiKeyDiv,
  SdkPageMainDiv,
  BoxWrap_contentDiv,
  BoxWrap_contentDiv2,
  ShowIcon,
  IntegrateWrap,
  Spacer,
  IntegrateWrapDiv,
  SdkIconItemImg,
} from "../../../styles/dashBoardStyles/OverviewComponents/sdkPage.styles";
import { useState, useRef, useEffect } from "react";
import nodeJs from "../../../assets/icon _js_.png";
import python from "../../../assets/icon _python_.png";
import client from "../../../assets/Group.png";
import { AlertStyles } from "../../../styles/registerComponent.styles";
import { Alert} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import emptyAnimation from "../../../assets/empty_animation.mp4";
import {
  UserText,
  SubHeaderText_H2,
} from "../../../styles/reusable/TextStyles";
import {
  CardBorder,
  DashboardAlertElementStatusDiv,
} from "../../../styles/reusable/BorderStyles";

import api from "../../../API/baseURL";
import VsReportComponent from "./vsReportComponents";
import PropTypes from "prop-types";
import { MainButtonStyle } from "../../../styles/reusable/inputTypes";
import { ReportLinkText } from "../../../styles/dashBoardStyles/developerReport/developerReport.Styles";
import GraphComponent from "../../ReuseableComponent/graphComponent";

const SdkPage = ({ developerMode, mode }) => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);
  const [allVsReport, setAllVsReport] = useState([]);
  const [no_of_attacks, setNoOfAttack] = useState(0);
  const [no_of_Pending_Vulnerabilites, setNoOfPendingVulnerabilites] =
    useState(0);
  const [reportViewLength, setReportViewLength] = useState(9);
  const sensitiveDataResults = useRef([]);
  const mobilePenTestResults = useRef([]);
  const backendPenTestResults = useRef([]);
  const viewMore = () => {
    setReportViewLength(reportViewLength + 10);
  };

  const apiKeyInputRef = useRef(null);

  const openVsExtension = () => {
    window.open(
      "https://marketplace.visualstudio.com/items?itemName=Sixth.sixth&ssr=false#review-details",
      "_blank"
    );
  };
  const openDeveloperMode = (value) => {
    developerMode?.(value);
  };

  const getAllVsReport = async () => {
    try {
      const response = await api.get(`/vs-code/get-all-diagnostics`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "x-api-key": localStorage.getItem("user_id"),
        },
      });
      if (response.status === 200) {
        setAllVsReport([]);
        setAllVsReport((prevJsonList) => [
          ...prevJsonList,
          ...response.data["data"],
        ]);

        for (let i = 0; i < response.data["data"].length; i++) {
          if (response.data["data"][i].category == "SENSITIVE_DATA_EXPOSURE") {
            sensitiveDataResults.current.push(response.data["data"][i]);
          } else if (response.data["data"][i].category == "MOBILE") {
            mobilePenTestResults.current.push(response.data["data"][i]);
          } else if (response.data["data"][i].category == "BACKEND") {
            backendPenTestResults.current.push(response.data["data"][i]);
          }
        }
      } else {
        await getAllVsReport();
      }
    } catch (e) {
      /*setTimeout(() => {
        getAllVsReport();
      }, 3000);*/
    }
  };

  const getUserReport = async () => {
    try {
      const response = await api.get(`/pen-test/get-pentest-count`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "api-key": localStorage.getItem("user_id"),
        },
      });
      if (response.status === 200) {
        setNoOfPendingVulnerabilites(
          response.data["number of identified attacks"]
        );
        setNoOfAttack(response.data["number of mitigated attacks"]);
      }
    } catch (e) {
      setTimeout(() => {
        
      }, 3000);
    }
  };

  const copyApiKey = () => {
    let apiKeyVal = apiKeyInputRef.current.select();
    document.execCommand("copy");
    navigator.clipboard.writeText(apiKeyVal)
    setCopied(true);
  };

  const showDocumentation = (url) => {
    window.open(url, "_blank");
  };

  useEffect(() => {
    async function loadAllVsReport() {
      const response = await getAllVsReport();
      const userresponse = await getUserReport();
    }

    loadAllVsReport();
  }, []);

  return (
    <SdkPageMainDiv>
      <Header />
      <SdkPageContent>
        {copied ? (
          <Alert
            sx={AlertStyles}
            onClose={() => setCopied(false)}
            severity="success"
          >
            apiKey copied to clipboard!
          </Alert>
        ) : (
          <></>
        )}

        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Spacer>
            {" "}
            <UserText>
              Hello There!, {localStorage.getItem("user")}
            </UserText>{" "}
            {
              //<FormControlLabel
              //  control={
              //    <MaterialUISwitch
              //      onChange={(e) => openDeveloperMode(mode ? false : true)}
              //      checked={mode}
              //      theme={mode}
              //      sx={{ m: 2 }}
              //    />
              //  }
              //for vs
              //  label="Switch to company account"
              //  style={{ color: "rgba(0, 1, 3, 0.6)", fontWeight: "600" }}
              ///>
            }{" "}
          </Spacer>

          {localStorage.getItem("user_type") != "developer" ? (
            <>
              <BoxWrap>
              <GraphComponent name={"Rate limit Usage"}></GraphComponent>
                <BoxWrap_contentDiv>
                
                  <CardBorder className="api">
                    <BoxWrap_content>
                      <SubHeaderText_H2>MY API KEY</SubHeaderText_H2>

                      <SdkPageCopyApiKeyDiv>
                        <ApiKeyInput
                          ref={apiKeyInputRef}
                          value={localStorage.getItem("user_id")}
                          type={show ? "text" : "password"}
                        />
                        <ShowIcon
                          onClick={() =>
                            setShow((prev) => (prev == true ? false : true))
                          }
                        >
                          {show ? (
                            <SdkIconItemImg
                              src="eye_open.svg"
                              alt="Example PNG"
                            />
                          ) : (
                            <SdkIconItemImg
                              src="icon_eye_closed.svg"
                              alt="Example PNG"
                            />
                          )}
                        </ShowIcon>
                        <CopyIcon onClick={() => copyApiKey()}>
                          <SdkIconItemImg
                            src="icon _copy.svg"
                            alt="Example PNG"
                          />
                        </CopyIcon>
                      </SdkPageCopyApiKeyDiv>
                    </BoxWrap_content>
                  </CardBorder>

                  <CardBorder className="api">
                    <BoxWrap_content>
                      <SubHeaderText_H2>
                        No. Of Mitigated Attacks
                      </SubHeaderText_H2>

                      <SdkPageCopyApiKeyDiv>
                        <ApiKeyInput value={no_of_attacks} type={"text"} />
                      </SdkPageCopyApiKeyDiv>
                    </BoxWrap_content>
                  </CardBorder>
                </BoxWrap_contentDiv>
                <BoxWrap_contentDiv2>
                  <CardBorder className="api">
                    <BoxWrap_content>
                      <SubHeaderText_H2>
                        Pending Vulnerabilites
                      </SubHeaderText_H2>

                      <SdkPageCopyApiKeyDiv>
                        <ApiKeyInput
                          className="api"
                          value={no_of_Pending_Vulnerabilites}
                          type={"text"}
                        />
                      </SdkPageCopyApiKeyDiv>
                    </BoxWrap_content>
                  </CardBorder>
                </BoxWrap_contentDiv2>
              </BoxWrap>
              
              <IntegrateWrapDiv>
                <IntegrateWrap>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <SubHeaderText_H2>SDKs</SubHeaderText_H2>
                    <SdkPageApiKeyDesDiv>
                      Copy the apikey above to get your installed SDKs to
                      work,you can check below for links to various technology
                      specific SDK implementations to know how to integrate
                      properly to your apps.
                    </SdkPageApiKeyDesDiv>
                  </div>

                  <SdkDocsDivItemDiv>
                    <DashboardAlertElementStatusDiv className="sdk">
                      <SdkDocsDivItem
                        onClick={() =>
                          showDocumentation(
                            "https://docs.trysixth.com/installation/python"
                          )
                        }
                      >
                        <SdkDocsDivItemImg src={python} />
                        <SdkDocsDivItemText>Python</SdkDocsDivItemText>
                      </SdkDocsDivItem>
                    </DashboardAlertElementStatusDiv>

                    <DashboardAlertElementStatusDiv className="sdk">
                      <SdkDocsDivItem
                        onClick={() =>
                          showDocumentation(
                            "https://docs.trysixth.com/installation/node-js"
                          )
                        }
                      >
                        <SdkDocsDivItemImg src={nodeJs} width="70px" />
                        <SdkDocsDivItemText>nodeJs</SdkDocsDivItemText>
                      </SdkDocsDivItem>
                    </DashboardAlertElementStatusDiv>
                    <DashboardAlertElementStatusDiv className="sdk">
                      <SdkDocsDivItem
                        onClick={() =>
                          showDocumentation("https://docs.trysixth.com")
                        }
                      >
                        <SdkDocsDivItemImg src={client} />
                        <SdkDocsDivItemText>Js</SdkDocsDivItemText>
                      </SdkDocsDivItem>
                    </DashboardAlertElementStatusDiv>
                  </SdkDocsDivItemDiv>
                </IntegrateWrap>
              </IntegrateWrapDiv>
            </>
          ) : (
            <>
              <SdkPageCopyApiKeyDiv>
                <SubHeaderText_H2>MY API KEY</SubHeaderText_H2>
                <ApiKeyInput
                  ref={apiKeyInputRef}
                  value={localStorage.getItem("user_id")}
                  type={show ? "text" : "password"}
                />
                <ShowIcon
                  onClick={() =>
                    setShow((prev) => (prev == true ? false : true))
                  }
                >
                  {show ? (
                    <SdkIconItemImg src="eye_open.svg" alt="Example PNG" />
                  ) : (
                    <SdkIconItemImg
                      src="icon_eye_closed.svg"
                      alt="Example PNG"
                    />
                  )}
                </ShowIcon>
                <CopyIcon onClick={() => copyApiKey()}>
                  <SdkIconItemImg src="icon _copy.svg" alt="Example PNG" />
                </CopyIcon>
              </SdkPageCopyApiKeyDiv>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingTop: "70px",
                }}
              >
                {sensitiveDataResults.current.length > 0 ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SubHeaderText_H2>
                        Sensitive Data Exposure
                      </SubHeaderText_H2>
                    </div>
                    {sensitiveDataResults.current.map((item, index) =>
                      index <= reportViewLength ? (
                        <VsReportComponent
                          report={item}
                          filename={item.file_name}
                          owner={item.owner}
                          snippet={item.three_line_snippet}
                          error={item.error}
                        />
                      ) : (
                        <></>
                      )
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: "24px", fontWeight: "800" }}>
                        Nothing to see here
                      </p>
                      <video
                        autoPlay
                        loop
                        style={{ width: "40%", height: "50%" }}
                      >
                        <source
                          src={emptyAnimation}
                          autoPlay="true"
                          type="video/mp4"
                          style={{ width: "100%" }}
                        />
                        Your browser does not support the video tag.
                      </video>
                      <ReportLinkText>
                        <MainButtonStyle
                          onClick={(e) => {
                            openVsExtension();
                          }}
                        >
                          Link with vs-code
                        </MainButtonStyle>{" "}
                      </ReportLinkText>
                    </div>
                  </>
                )}
                {sensitiveDataResults.current.length > reportViewLength ? (
                  <div
                    style={{
                      marginTop: "30px",
                      marginBottom: "30px",
                      height: "400px",
                      width: "150px",
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MainButtonStyle onClick={viewMore}>
                      <p>view more</p>
                    </MainButtonStyle>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </SdkPageContent>
    </SdkPageMainDiv>
  );
};

SdkPage.prototype = {
  developerMode: PropTypes.func,
  mode: PropTypes.bool,
};
export default SdkPage;
