import styled from "styled-components";


export const RegisterMainDiv=styled.div`
width:100%;
min-height:100vh;
color:white;
padding:15px;
box-sizing: border-box;
background: url("/main-bg.svg");
background-size: cover;
background-repeat: no-repeat;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
    background: url("/main-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
> input {
    background:red;
}
`;


export const RegisterHeadertext = styled.div`
  font-size: 25px;
  font-weight: 800;
`;

export const RegisterHeaderImage = styled.img`
  width: 100px;
  border-radius: 8px;
  color:white;
  background: transparent;
`;

export const RegisterHeaderLogo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
  align-items: center;
  box-sizing: border-box;
  justify-content: flex-start;
>>>>>>> main
`;

export const RegisterInnerDiv=styled.div`
width:100%;
display:flex;
flex-direction:column;
align-items: center;
box-sizing: border-box;
padding-top:50px;
padding-bottom:30px;

@media (max-width:728px){
    width:100%;
}
`;
export const ErrorFieldInput = styled.div`
color:red;
padding-bottom:15px;
padding-left:2px;
font-weight:600;
`
export const RegisterHeader=styled.div`
font-size:24px;
text-align:center;
margin-bottom:10px;
margin-top:48px;
font-weight: 600;
`;


export const RegisterForm=styled.div`
width:70%;
margin-top:15px;
box-sizing: border-box;
display: flex;
justify-content: start;
align-items: left;
flex-direction: column;
max-width:500px;
@media (max-width:728px){
    width:90%;
}
`;

export const PasswordInput={
    width: '60%',
    outline:"none",
    borderRadius:"5px",
    boxSizing: "border-box",
    fontSize:"15px",
    marginBottom:"20px",
    border: "1px solid #813DE8",
    '&:focus':{
        color: 'white',
        outline:"none",
        border: "1px solid #813DE8",
    },

    '&:hover':{
        color: 'white',
        outline:"none",
        border: "1px solid #813DE8",
    },
    '@media (max-width: 728px)': {
        color: 'white',
        width: '90%',
    }

   
};

export const RegisterInputButton=styled.div`
width:60%;
height:50px;
display:flex;
justify-content:center;
align-items:center;

margin-bottom:20px;
margin-top:20px;

`;

export const AlertStyles={
    
        position: 'fixed',
        top: '2%',
        left: '15%',
        width:'75%',
        borderRadius:'5px',
        zIndex: 9999,
        
        
        boxShadow: 'none',
        padding: '10px',
        
        animation: 'slide-in 0.3s ease',
      
}