import styled from "styled-components";


export const NotFoundMainDiv = styled.div`
width:100%;
display:flex;
flex-direction:column;
overflow-x:hidden;
`

export const NotFoundText = styled.div`
display:flex;
align-items:center;
text-align:center;
justify-content:center;
flex-direction:column;

`

export const NotFoundOtherText = styled.div`

color: #8c8c8c;
display:flex;
align-items:center;
text-align:center;
justify-content:center;
flex-direction:column;
margin-bottom:30px;

`

export const NotFoundReturn = styled.div`
min-width:100px;
max-width:300px;
height:50px;
width:40%;
`



export const NotFoundContentDiv = styled.div`
overflow-x:none;
height:90Vh;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;

`