import { useState } from "react";
import api from "../../../API/baseURL";
import { useEffect } from "react";
import useTokenExpired from "../../expirationEvent";



const useRateLimitStart=()=>{

    const [rateLimitExists,setRateLimitExists]=useState(false);
    const [checkTokenExpiration]=useTokenExpired();

    const getExistingRateLimit=async()=>{
        try{
            const response=await api.get('/project-config/config-exists',{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setRateLimitExists(true);
        }catch(err){
            checkTokenExpiration(err);
            if(err.response.data.detail=="Config doesn't exist"){
                setRateLimitExists(false);
            }
        }

    };
    useEffect(()=>{
        getExistingRateLimit();
    },[])
    return [rateLimitExists];
};
export default useRateLimitStart;