import styled from "styled-components";


export const CloseButton = styled.div`
width:50px;
height:50px;
border-radius:50px;
background: #8C30F5;
float:right;
top:-55px;
position:relative;
left:50px;
align-items:center;
display:flex;
justify-content:center;
cursor: ${(props) => (props.disable ? "not-allowed " : " pointer")} ;
`;



export const CancelButton = styled.div`
width:30%;
height:50px;
align-items:center;
display:flex;
justify-content:center;
min-width:100px;
`;



export const ConfirmButton=styled.div`
width:30%;
height:50px;
align-items:center;
display:flex;
justify-content:center;
min-width:100px;
`;

export const OptionField  = styled.div`
width:100%;
height:auto;
align-items:center;
display:flex;
justify-content:space-evenly;
`;

export const ConfirmDeleteContent = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
box-sizing: border-box;
clear:right;
`;



export const ConfirmDeleteInnerDiv=styled.div`
width:30%;
border-radius:10px;
padding:30px;
background:white;
min-width:200px;
`;


