import {
  AlertStyles,
  ErrorFieldInput,
  RegisterForm,
  RegisterHeader,
  RegisterInnerDiv,
  RegisterInputButton,
  RegisterMainDiv,
} from "../styles/registerComponent.styles";
import { Alert } from "@mui/material";
import { useState } from "react";
import { UserAuth } from "../contextApis/authContext";
import api from "../API/baseURL";
import { Link, useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import {
  LoginHeaderImage,
  LoginHeaderLogo,
  LoginHeaderText,
  LoginHeadertext,
  LoginInnerDiv,
  LoginInnerDivBorder,
  OtherText,
  OtherTextSpan,
} from "../styles/loginComponent.styles";
import {
  InputStyle,
  InputStyle_Large,
  InputStyle_White,
  InputStyle_White_large,
  MainButtonStyle,
} from "../styles/reusable/inputTypes";
import sixthLogo from "../assets/sixth_logo_white.png";
import DashboardStyledTab from "./ReuseableComponent/dashboardStyledTab";

const SignUpComponent = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyDes, setCompanyDes] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword,  setConfirmPassword] = useState("");

  const [isDeveloper, setDeveloper] = useState(false);

  const [btnActive, setBtnActive] = useState(false);
  const navigate = useNavigate();
  const [alertForm, setAlertForm] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const { createUser } = UserAuth();
  const clearInputs = () => {
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setFullName("");
    setCompanyDes("");
    setCompanyName("");
  };

  const handleChange = (newValue) => {
    if (btnActive == true) return;
    clearInputs();

    if (newValue == 1) setDeveloper(true);
    else setDeveloper(false);
  };

  const handleSubmit = async () => {
    setBtnActive(true);
    

    if (
      ! /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/.test(password)
    ) {
      setAlertForm({
        message:
          "password should be more than 8 characters long with upper and lower case values and a symbol",
        severity: "error",
        show: true,
      });
      setBtnActive(false);
      return;
    }

    if (password != confirmPassword) {
      setAlertForm({
        message: "password and confirm password do not match",
        severity: "error",
        show: true,
      });
      setBtnActive(false);
      return;
    }

    if (! /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setAlertForm({
        message: "invalid email",
        severity: "error",
        show: true,
      });
      setBtnActive(false);
      return;
    }

    if (isDeveloper == true) {
      
      if (fullName.length > 0) {
        try {
          const newUser = await createUser(email, password);
          try {
            const response = await api.post("/auth/register", {
              id: newUser.user.uid,
              email: email,
              fullname: fullName,
              password: password,
              user_type: "developer",
            });
            clearInputs()

           
          
            navigate("/login");
          } catch (err) {
          }
        } catch (err) {
          if (err.message == "Firebase: Error (auth/email-already-in-use).") {
            setAlertForm({
              message: "email already in use",
              severity: "error",
              show: true,
            });
          } else {
            setAlertForm({
              message: "something went wrong",
              severity: "error",
              show: true,
            });
          }
        }
        setBtnActive(false);
      } else {
        setAlertForm({
          message: "full name cannot be empty",
          severity: "error",
          show: true,
        });
        setBtnActive(false);
        return;
      }
    } else {
      if (companyDes.length > 0 && companyName.length > 0) {
        try {
          const newUser = await createUser(email, password);
          try {
            console.log(newUser.user.uid,"uuid")
            const response = await api.post("/auth/register", {
              id: newUser.user.uid,
              email: email,
              company_name: companyName,
              company_description: companyDes,
              password: password,
              user_type: "company",
            });
            clearInputs();
            
           
            navigate("/login");
            console.log(response, "response");
          } catch (err) {
            console.log(err);
          }
        } catch (err) {
          if (err.message == "Firebase: Error (auth/email-already-in-use).") {
            setAlertForm({
              message: "email already in use",
              severity: "error",
              show: true,
            });
          } else {
            setAlertForm({
              message: "something went wrong",
              severity: "error",
              show: true,
            });
          }
        }
        setBtnActive(false);
      } else {
        setAlertForm({
          message: "company name and company description can't be empty",
          severity: "error",
          show: true,
        });
        setBtnActive(false);
      }
    }
  };

  return (
    <RegisterMainDiv>
      {alertForm.show ? (
        <Alert
          sx={AlertStyles}
          onClose={() => setAlertForm((prev) => ({ ...prev, show: false }))}
          severity={alertForm.severity}
        >
          {alertForm.message}
        </Alert>
      ) : (
        <></>
      )}
      <LoginHeaderLogo>
        <LoginHeaderImage src={sixthLogo} alt="sixth logo" />
        
      </LoginHeaderLogo>
      <LoginInnerDiv>
        <LoginInnerDivBorder>
          <RegisterHeader>Create &nbsp;Account</RegisterHeader>
          <LoginHeaderText>sign up with Sixth </LoginHeaderText>
          <DashboardStyledTab
            text={["Sign up as a company", "Sign up as a developer"]}
            position="space-evenly"
            active={isDeveloper == true ? 1 : 0}
            handleClick={handleChange}
          ></DashboardStyledTab>
          <RegisterInnerDiv>
            {isDeveloper ? (
              <>
                <RegisterForm>
                  <InputStyle_White
                    label="full name "
                    onChange={(e) => setFullName(e.target.value)}
                    value={fullName}
                    autoComplete="off"
                    placeholder="Full name"
                    
                  />
                </RegisterForm>
                <RegisterForm>
                  <InputStyle_White
                    label="email"
                    id="custom-css-outlined-input"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    autoComplete={"off"}
                    placeholder="Email"
                  />
                </RegisterForm>

                <RegisterForm>
                  <InputStyle_White
                    label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type={showPassword ? "text" : "password"}
                    placeholder="password"
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },

                    }}
                  />
                </RegisterForm>

                <RegisterForm>
                  {confirmPassword != "" && confirmPassword != password ? (
                    <ErrorFieldInput>Password dose'nt match</ErrorFieldInput>
                  ) : (
                    <></>
                  )}

                  <InputStyle_White
                    label="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    placeholder="password"
                  />
                </RegisterForm>
              </>
            ) : (
              <>
                <RegisterForm>
                  <InputStyle_White
                    label="email"
                    id="custom-css-outlined-input"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    autoComplete={"off"}
                    placeholder="Email"
                  />
                </RegisterForm>

                <RegisterForm>
                  <InputStyle_White
                    label="Company name "
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                    autoComplete="off"
                    placeholder="Company name"
                  />
                </RegisterForm>

                <RegisterForm>
                  <InputStyle_White_large
                    label="Company description"
                    onChange={(e) => setCompanyDes(e.target.value)}
                    value={companyDes}
                    multiline
                    autoComplete="off"
                    placeholder="Company description"
                  />
                </RegisterForm>

                <RegisterForm>
                  <InputStyle_White
                    label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type={showPassword ? "text" : "password"}
                    placeholder="password"
                    inputProps={{
                      autocomplete: "new-password",
                      form: {
                        autocomplete: "off",
                      },
                    }}
                  />
                </RegisterForm>

                <RegisterForm>
                  {confirmPassword != "" && confirmPassword != password ? (
                    <ErrorFieldInput>Password doesn't match</ErrorFieldInput>
                  ) : (
                    <></>
                  )}
                  <InputStyle_White
                    label="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    placeholder="password"
                  />
                </RegisterForm>
              </>
            )}
          </RegisterInnerDiv>

          <RegisterInputButton>
            <MainButtonStyle
              variant={"main"}
              disabled={btnActive}
              onClick={(e) => handleSubmit()}
              className="purple"
            >
              {btnActive ? (
                <Oval
                  height="20"
                  width="20"
                  color="#757575"
                  secondaryColor="#BDBDBD"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                <>Create Account</>
              )}
            </MainButtonStyle>
          </RegisterInputButton>

          <OtherText>
            Already have an account? &nbsp; &nbsp;{" "}
            <OtherTextSpan onClick={(e) => navigate("/login")}>
              Login
            </OtherTextSpan>{" "}
          </OtherText>
        </LoginInnerDivBorder>
      </LoginInnerDiv>
    </RegisterMainDiv>
  );
};
export default SignUpComponent;