import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PenResultHeader,
  PenStartMobileDiv,
  PenResultCard,
  
  PenStartInnerDiv,
  PenStartSecMainDiv,

  PenStartContentHolder,
  PenStartContentHeader,
  PenStartCompanyIcon,
  PenStartCompanyIconText,
  PenStartDropDown,
  PenStartDropDownArrow,
  PenStartDropDownHead,
  PenStartMainContentDiv,
  PenStartDropDownBody,
  PenResultCardHead,
} from "../../../styles/dashBoardStyles/penTesting/penStart.styles";
import Header from "../../header";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import PenTestLoading from "./penTestLoading";
import api from "../../../API/baseURL";
import { AiFillAppstore } from "react-icons/ai";
import { SiFastapi } from "react-icons/si";
import {
  MdOutlineArrowDropDownCircle,
  MdOutlineSecurityUpdateWarning,
} from "react-icons/md";
import DashboardStyledTab from "../../ReuseableComponent/dashboardStyledTab";
import {
  SubHeaderText,
  SubHeaderText_H2,
} from "../../../styles/reusable/TextStyles";
import {
  DashboardAlertElementStatusDiv,
} from "../../../styles/reusable/BorderStyles";
import PenTestDetail from "./penTestDetail";
import { Status } from "iconsax-react";
import {
  
  PenTestSection,
  PenTestHeaderSection,
} from "../../../styles/dashBoardStyles/penTesting/penTestDetail.styles";
import PenTestApiComponent from "./penTestEndpointComponent";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 70,
    width: "100%",
    backgroundColor: "#F1E4FF",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#F1E4FF",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 50,
      width: "100%",
      backgroundColor: "#F1E4FF",
    },
  })
);

const PenStart = () => {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [openApis, setOpenApi] = useState(false);
  const [openVulnerability, setOpenVulnerability] = useState(false);
  const [appName, setAppName] = useState("");

  const [addedApps, setAddedApps] = useState([]);
  const [dataObj, setDataObj] = useState({});
  const [value, setValue] = useState(0);
  const [backendAPIS, setBackendAPIS] = useState({});
  const [loadingPenTestResult, setLoadingPenTestResult] = useState(true);
  const webPenTestResults = useRef([]);
  const mobilePenTestResults = useRef([]);
  const backendPenTestResults = useRef([]);
  const endpointResults = useRef([]);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const addItemToObj = (index, val, field) => {
    const newObj = {};

    for (const key in dataObj) {
      newObj[key] = dataObj[key];
    }

    const updatedKey = addedApps[index];
    if (updatedKey in newObj) {
      if (field == "url") {
        newObj[updatedKey] = { ...newObj[updatedKey]["ip"], url: val };
      } else {
        newObj[updatedKey] = { ...newObj[updatedKey]["url"], ip: val };
      }
    } else {
      if (field == "url") {
        newObj[updatedKey] = { ip: "", url: val };
      } else {
        newObj[updatedKey] = { ip: val, url: "" };
      }
    }

    setDataObj(newObj);
  };

  let count = 0;
  const getPenTestingResults = async () => {
    try {
      const response = await api.get("/pen-test/get-pen-tests", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "api-key": `${localStorage.getItem("user_id")}`,
        },
      });
      let body = response.data;
      

      if (body != null) {
        setLoadingPenTestResult(false);
        for (let i = 0; i < body.data.length; i++) {
          if (body.data[i].app == "WEB") {
            webPenTestResults.current.push(body.data[i]);
          } else if (body.data[i].app == "MOBILE") {
            mobilePenTestResults.current.push(body.data[i]);
          } else if (body.data[i].app == "BACKEND") {
            backendPenTestResults.current.push(body.data[i]);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPenTestVunrebility = async () => {
    try {
     
      const response = await api.get("/pen-test/get-user-vulnerability-scan", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "api-key": `${localStorage.getItem("user_id")}`,
        },
      });
      if (response.status === 200) {
        endpointResults.current = [];
        setLoadingPenTestResult(false);
        for (const key in response.data["vulnerability_scan_result"]["backend"][
          "exposed_endpoints"
        ]) {
          if (
            response.data["vulnerability_scan_result"]["backend"][
              "exposed_endpoints"
            ].hasOwnProperty(key)
          ) {
            var path = key;
            path = path.replace(/\|/g, ".");
            path = path.replace(/~/g, "/");

            endpointResults.current.push({
              url: path,
              endPoint:
                response.data["vulnerability_scan_result"]["backend"][
                  "exposed_endpoints"
                ][key],
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setAppName("");
  }, [addedApps]);

  useEffect(() => {
    async function loadAllReport() {
      const response = await getPenTestingResults();
      const userresponse = await getPenTestVunrebility();
    }
    loadAllReport();
  }, []);

  // console.log(webPenTestResults.current)
  // console.log(mobilePenTestResults.current)
  // console.log(backendPenTestResults)

  return (
    <PenStartSecMainDiv>
      <Header />
      <PenResultHeader>
        <PenResultCardHead>
          <div>
            <PenStartCompanyIcon>
              <PenStartCompanyIconText>
                {localStorage.getItem("user").charAt(0)}
              </PenStartCompanyIconText>
            </PenStartCompanyIcon>
          </div>
          <div>
            <PenStartContentHeader>
              <AiFillAppstore style={{ padding: "0px" }} size={"10%"} />
              <SubHeaderText_H2 className="logLabel  des">
                {" "}
                App Information{" "}
              </SubHeaderText_H2>
            </PenStartContentHeader>

            <PenTestHeaderSection>
              {" "}
              <DashboardAlertElementStatusDiv className="primary thick">
                Company name
              </DashboardAlertElementStatusDiv>
              <SubHeaderText_H2 className="noMarginBottom">
                {localStorage.getItem("user")}{" "}
              </SubHeaderText_H2>
            </PenTestHeaderSection>

            <PenTestHeaderSection>
              {" "}
              <DashboardAlertElementStatusDiv  className="primary thick">
                Company Description
              </DashboardAlertElementStatusDiv>
              <SubHeaderText_H2 className="noMarginBottom">
                {" "}
                {localStorage.getItem("user_des")} 
              </SubHeaderText_H2>
            </PenTestHeaderSection>
          </div>
        </PenResultCardHead>
      </PenResultHeader>
      <PenResultHeader>
        <PenResultCard style={{ padding: "2px", overflow: "hidden" }}>
          <DashboardStyledTab
            text={["Web", "Mobile", "Backend"]}
            active={value}
            handleClick={handleChange}
          />
        </PenResultCard>
      </PenResultHeader>

      <PenStartMainContentDiv>
        <CustomTabPanel value={value} index={0}>
          <PenStartMobileDiv>
            {loadingPenTestResult ? (
              <PenTestLoading />
            ) : (
              <>
                {webPenTestResults.current.length == 0 ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    <p style={{ fontSize: "24px", fontWeight: "800" }}>
                      Loading....
                    </p>
                    <video
                      autoPlay
                      loop
                      style={{ width: "100%", boxSizing: "border-box" }}
                    >
                      <source
                        src="/pen_test_loading.mp4"
                        type="video/mp4"
                        style={{ width: "100%" }}
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ) : (
                  <PenStartInnerDiv>
                    <PenStartContentHolder>
                      {webPenTestResults.current.map((result) => (
                        <PenTestDetail
                          title={result.name}
                          attackCount={result.no_of_attacks}
                          pending={result.status === "PENDING" ? true : false}
                          Status={result.status}
                          Medias={result.media_link}
                          Category={[result.category]}
                          description={result.description}
                          mitigation={result.mitigation}
                        />
                      ))}
                    </PenStartContentHolder>
                  </PenStartInnerDiv>
                )}
              </>
            )}
          </PenStartMobileDiv>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <PenStartMobileDiv>
            <PenStartMobileDiv>
              {loadingPenTestResult ? (
                <PenTestLoading />
              ) : (
                <>
                  {mobilePenTestResults.current.length == 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: "24px", fontWeight: "800px" }}>
                        Loading...
                      </p>
                      <video
                        autoPlay
                        loop
                        style={{ width: "400px", height: "400px" }}
                      >
                        <source
                          src="/pen_test_loading.mp4"
                          type="video/mp4"
                          style={{ width: "300px", height: "300px" }}
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : (
                    <PenStartInnerDiv>
                      <PenStartContentHolder>
                        {mobilePenTestResults.current.map((result) => (
                          <PenTestDetail
                            title={result.name}
                            attackCount={result.no_of_attacks}
                            pending={result.status === "PENDING" ? true : false}
                            Status={result.status}
                            Medias={result.media_link}
                            Category={[result.category]}
                            description={result.description}
                            mitigation={result.mitigation}
                          />
                        ))}
                      </PenStartContentHolder>
                    </PenStartInnerDiv>
                  )}
                </>
              )}
            </PenStartMobileDiv>
          </PenStartMobileDiv>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <PenStartMobileDiv>
            {loadingPenTestResult ? (
              <PenTestLoading></PenTestLoading>
            ) : (
              <>
                <PenStartDropDown>
                  <PenStartDropDownHead>
                    <PenTestSection style={{ gap: "5px" }}>
                      <SiFastapi style={{ color: "grey" }} size={"60%"} />
                      <SubHeaderText> Exposed API</SubHeaderText>
                    </PenTestSection>
                    <PenStartDropDownArrow
                      active={openApis}
                      onClick={() => setOpenApi(!openApis)}
                    >
                      {openApis ? (
                        <MdOutlineArrowDropDownCircle size={"50px"} />
                      ) : (
                        <MdOutlineArrowDropDownCircle size={"50px"} />
                      )}
                    </PenStartDropDownArrow>
                  </PenStartDropDownHead>

                  {openApis ? (
                    <>
                      <PenTestSection>
                        {" "}
                        <DashboardAlertElementStatusDiv className="primary thick">
                          Base-url
                        </DashboardAlertElementStatusDiv>
                        <SubHeaderText_H2>
                          {" "}
                        
                        </SubHeaderText_H2>
                      </PenTestSection>
                      {endpointResults.current.map((item) => (
                        <PenTestApiComponent
                          endpoint={item.endPoint}
                          url={item.url}
                        >
                          {" "}
                        </PenTestApiComponent>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </PenStartDropDown>
                <PenStartDropDown>
                  <PenStartDropDownHead>
                    <PenTestSection style={{ gap: "5px" }}>
                      <MdOutlineSecurityUpdateWarning
                        style={{ color: "grey" }}
                        size={"60%"}
                      />
                      <SubHeaderText> Vulnerability </SubHeaderText>
                    </PenTestSection>
                    <PenStartDropDownArrow
                      active={openVulnerability}
                      onClick={() => setOpenVulnerability(!openVulnerability)}
                    >
                      {openVulnerability ? (
                        <MdOutlineArrowDropDownCircle size={"50px"} />
                      ) : (
                        <MdOutlineArrowDropDownCircle size={"50px"} />
                      )}
                    </PenStartDropDownArrow>
                  </PenStartDropDownHead>

                  {openVulnerability ? (
                    <PenStartDropDownBody>
                      {backendPenTestResults.current.map((result) =>
                         <PenTestDetail
                          title={result.name}
                          attackCount={result.no_of_attacks}
                          pending={result.status === "PENDING" ? true : false}
                          Status={result.status}
                          Medias={result.media_link}
                          Category={[result.category]}
                          description={result.description}
                          mitigation={result.mitigation}
                        />
                      )}
                    </PenStartDropDownBody>
                  ) : (
                    <></>
                  )}
                </PenStartDropDown>
                {backendPenTestResults.current.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontSize: "24px", fontWeight: "800px" }}>
                      Loading...
                    </p>
                    <video
                      autoPlay
                      loop
                      style={{ width: "400px", height: "400px" }}
                    >
                      <source
                        src="/pen_test_loading.mp4"
                        type="video/mp4"
                        style={{ width: "300px", height: "300px" }}
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </PenStartMobileDiv>
        </CustomTabPanel>
      </PenStartMainContentDiv>
    </PenStartSecMainDiv>
  );
};
export default PenStart;
