import firebase from 'firebase/compat/app';
import "firebase/compat/storage";
import {getAuth} from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyDpqU2toL-ZNzAYN7fflqqtYamWXp22Lm0",
  authDomain: "sixth-v2.firebaseapp.com",
  databaseURL: "https://sixth-v2-default-rtdb.firebaseio.com",
  projectId: "sixth-v2",
  storageBucket: "sixth-v2.appspot.com",
  messagingSenderId: "971026243533",
  appId: "1:971026243533:web:a047ecc7d6e60a8a42ee84",
  measurementId: "G-2W6SFLPJ7B"
};


firebase.initializeApp(firebaseConfig);

export const firebaseStorageRef=firebase.storage();
export const firebaseAuth=getAuth();
