import styled from "styled-components";
import { keyframes } from "styled-components";

export const GraphComponentMainDiv = styled.div`
 text-align:center;

` 


export const GraphComponentHead = styled.div`
display:flex;
justify-content:space-between;

` 



export const rotateZKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateZ(290px);
  }
  80% {
    transform: translateZ(10px);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
`;

export const GraphComponentGraphDropdownButton = styled.div`
  position: relative;
  background: var(--sixth-purple-2);
  color: var(--sixth-purple);
  min-width: 140px;
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;

  

  
  

  &:hover {
    > div {
      display: block;
    }
  }
`;

export const GraphComponentGraphDropdown = styled.div`
  position: absolute;
  display: none;
  top:100%;
  z-index: 2;
  
  color: var(--sixth-purple);
  min-width: 140px;
  justify-content: center;
  height: 50px;
  align-items: center;
  cursor: pointer;
  -webkit-perspective: 1000;
  &:hover {
    display: block;
  }


`;


export const GraphComponentGraphDropdownItem = styled.div`
  display: flex;
  background: var(--sixth-purple-2);
  color: var(--sixth-purple);
  min-width: 140px;
  justify-content: center;
  height: 50px;
  align-items: center;
  cursor: pointer;
  -webkit-perspective: 1000;

  transform-origin: top center;
  :nth-child(1) {
    animation: ${rotateZKeyframes} .3s ease-in-out forwards;
  }

  
  :nth-child(2) {
    animation: ${rotateZKeyframes} .5s ease-in-out forwards;
  }

  
  :nth-child(3) {
    animation: ${rotateZKeyframes} .7s ease-in-out forwards;
  }

  :nth-child(4) {
    animation: ${rotateZKeyframes} .8s ease-in-out forwards;
  }
  &:hover {
    color: white;
    background: var(--sixth-purple);
  }
`;
