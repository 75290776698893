import { useState } from "react";
import sixthLogo from "../assets/waves.png";
import { BodyDivBackToLoginBtn, BodyDivEmailForm, BodyDivHeader, BodyDivRequestLinkBtn, BodyDivSecHeader, ForgetPasswordBodyDiv, ForgetPasswordInnerBodyDiv, ForgetPasswordLogoDiv, ForgetPasswordMainDiv, LogoDivImg, LogoDivText } from "../styles/forgetPassword.styles";
import { Link, useNavigate } from "react-router-dom";
import api from "../API/baseURL";
import { AlertStyles} from "../styles/registerComponent.styles";
import { Alert} from "@mui/material";
import { Oval } from "react-loader-spinner";

const ForgetPassword=()=>{

    const navigate = useNavigate();
    const [btnActive, setBtnActive] = useState(false);

    const resetPassword=async()=>{
        setBtnActive(true);
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setAlertForm({
              message: "invalid email",
              severity: "error",
              show: true,
            });
            setBtnActive(false);
            return;
          }else{
            try{
                const response= await api.post("/auth/send-reset-password",{"email":email});
                setAlertForm({
                    message: "Password reset link has been sent to your email.",
                    severity: "success",
                    show: true,
                  });
                  setBtnActive(false);
            }catch(err){
                setAlertForm({
                    message: "something went wrong,try again later",
                    severity: "error",
                    show: true,
                  }); 
                  setBtnActive(false);
            }
          }
    };

    const [alertForm, setAlertForm] = useState({
        message: "",
        severity: "success",
        show: false,
      });

    const [email,setEmail]=useState("");

    return(
        <ForgetPasswordMainDiv>
                {alertForm.show ? (
                <Alert
                sx={AlertStyles}
                onClose={() => setAlertForm((prev) => ({ ...prev, show: false }))}
                severity={alertForm.severity}
                >
                {alertForm.message}
                </Alert>
            ) : (
                <></>
            )}
            <ForgetPasswordLogoDiv>
                <LogoDivImg src={sixthLogo} alt="logo" />
                <LogoDivText>Sixth</LogoDivText>
            </ForgetPasswordLogoDiv>
            <ForgetPasswordInnerBodyDiv>
                <ForgetPasswordBodyDiv>
                    <BodyDivHeader>Forgot Password?</BodyDivHeader>
                    <BodyDivSecHeader>Enter the email address you used to sign up below and we will send you a reset link</BodyDivSecHeader>
                    <BodyDivEmailForm
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    autoComplete="off"
                    placeholder="Email"
                    />
                    <BodyDivRequestLinkBtn disabled={btnActive} onClick={resetPassword} >
                                {btnActive ? (
                        <Oval
                            height="20"
                            width="20"
                            color="white"
                            secondaryColor="#BDBDBD"
                            ariaLabel="tail-spin-loading"
                            radius="2"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                        ) : (
                        <>Request Reset Link</>
                        )}
                    </BodyDivRequestLinkBtn>
                    <BodyDivBackToLoginBtn onClick={(e)=>navigate("/login")} >Back to Login</BodyDivBackToLoginBtn>
                </ForgetPasswordBodyDiv>
            </ForgetPasswordInnerBodyDiv>
        </ForgetPasswordMainDiv>
    );
};
export default ForgetPassword;