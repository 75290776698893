import {
  LogMainDiv,
  LogHeader,
  LogDetails,
  LogUpdateField,
  LogUpdateFieldValue,
  LogEditField,
  LogButton,
} from "../../../styles/dashBoardStyles/secureLogs/LogsStyle";
import PropTypes from "prop-types";
import { SubHeaderText_H2 } from "../../../styles/reusable/TextStyles";
import {
  DropDownStyle,
  MainButtonStyle,
} from "../../../styles/reusable/inputTypes";
import Select from "react-select";

const LogsComponent = ({
  open,
  title,
  close,
  expand,
  dataType,
  endpointValue,
  endpointLabel,
  deleteLog,
  editLog
  ,id
}) => {

  const Close = ()=>
  {
    
    deleteLog(id)

  }
  const edit=() =>
  {
  ;
    editLog(id)
  }
  return (
    <LogMainDiv onClick={open}>
      <LogHeader>
        <SubHeaderText_H2 className="logLabel">{title}</SubHeaderText_H2>
      </LogHeader>
      <LogDetails onClick={close} show={expand}>
        <LogUpdateField>
          <LogUpdateField>
            <SubHeaderText_H2 className="logLabel">Data type</SubHeaderText_H2>
            <LogUpdateFieldValue>{dataType}</LogUpdateFieldValue>
          </LogUpdateField>
        </LogUpdateField>

        <LogUpdateField>
          <SubHeaderText_H2 className="logLabel">
            {" "}
            Which of the endpoints do you want this to monitored on?
          </SubHeaderText_H2>

          <Select
            options={[
              {
                value: endpointValue,
                label: endpointLabel,
              },
            ]}
            defaultValue={{
              value: endpointValue,
              label: endpointLabel,
            }}
            onChange={(selected) => {}}
            styles={DropDownStyle}
          />
          <LogEditField>
            <LogButton>
              <MainButtonStyle className="bounce" onClick={Close}>Delete</MainButtonStyle>
            </LogButton>

            <LogButton>
              <MainButtonStyle className="" onClick={edit}>Edit</MainButtonStyle>
            </LogButton>

          
          </LogEditField>
        </LogUpdateField>
      </LogDetails>
    </LogMainDiv>
  );
};

LogsComponent.prototype = {
  onclick: PropTypes.func,
  title: PropTypes.string,
  open: PropTypes.func,
  expand: PropTypes.bool,
  dataType: PropTypes.string,
  deleteLog:PropTypes.func,
  editLog:PropTypes.func,
};

export default LogsComponent;
