import styled from "styled-components";

export const TwoFactorComponentMainDiv = styled.div`
  color: white;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: url("/main-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  @media (max-width: 500px) {
   text-align:center;
  }
`;

export const TwoFactorDiv = styled.div`
  width: 70%;

  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin-top: 2rem;
  gap: 16px;
  box-sizing:border-box;

  @media (max-width: 500px) {
    width: 90%;
    height: 34px;
    padding:auto;
    
    margin-bottom: 10px;
    margin-top: 54px;
    justify-content: center;
  }
`;
export const TwoFactorInvalidCodDiv = styled.div`
width: 70%;
display: flex;
justify-content:start ;
align-items: center;
max-width: 500px;
box-sixing:border-box;
margin-top:1rem;



@media (max-width: 500px) {
  width: 100%;
  justify-content: start;
  
`

export const TwoFactorInputButton = styled.div`
  width: 70%;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin-bottom: 20px;
  margin-top:4% ;
  gap: 16px;
  

  @media (max-width: 728px) {
    width: 90%;
    margin-bottom: 20px;
    margin-top: 34px;
    
`;

export const TwoFactorInput = styled.input`
  width: 4rem;
  height: 4rem;
  max-width: 500px;
  border-radius: 15px;
  font-size: 34px;
  text-decoration:none;
  text-align: center;
  border: 1px solid var(--sixth-purple);
  :hover{
    border: 1.5 px solid var(--sixth-purple);
  }
  &:focus{
    outline:none;
    border: 3px solid var(--sixth-purple);

  }
  @media (max-width: 600px) {
    width: 45px;
    height: 45px;
    border-radius: 10px
  }

  @media (max-width: 550px) {
    width: 25px;
    height: 34px;
    border-radius: 10px;
  }
`;

export const TwoFactorForm = styled.div`
  width: 70%;
  box-sizing: border-box;
  display: flex;
  justify-content: start;
  align-items: left;
  flex-direction: column;
  max-width: 500px;
  color: #8c30f5;
  margin-top:4%;
  @media (max-width: 728px) {
    width: 90%;
    margin-top: 1rem;
  }
`;

export const TwoFactorComponentInnerBorder = styled.div`
  width: 60%;
  height: 65%;
  min-height:500px;
  max-height:750px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 16px;
  border: 5px solid rgba(30, 30, 30, 0.05);
  padding: 4rem 3rem;
  box-sizing: border-box;
  max-width: 950px;
  @media (max-width: 1100px) {
    width: 75%;
   
    
  }

  @media (max-width: 900px) {
    width: 85%;
   
  }

  @media (max-width: 788px) {
    justify-content: flex-start;
  }

  @media (max-width: 500px) {
    padding: auto;
    width: 95%;
    height: 85%;
  }

  @media (max-height: 700px) {
    height: 90%;
  }
`;
export const TwoFactorComponentInnerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;
