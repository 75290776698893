import styled from "styled-components";

export const LogMainDiv = styled.div`
width:70%;
padding:6px;
margin-bottom:10px;
box-sizing: border-box;

`;

export const LogHeader = styled.div
`
width:100%;
height:50px ;
display:flex;
padding:10px;
border-radius:10px;
border:1px solid  rgba(140, 48, 245, 0.4);

box-sizing: border-box;
margin-top:8px;
align-items:center;
`;

export const LogUpdateField = styled.div`
width:100%;
margin-bottom:20px;
box-sizing: border-box;
`;
export const LogDetails  = styled.div
`
display:${(props)=>props.show?"block":"none"};
margin-top:6px;
width:100%;
padding:14px;
border-radius:10px;
box-sizing: border-box;
transition:all 0.3s ease;

`;

export const LogEditField = styled.div
`
display :flex;
gap:25px;
margin-top:10px;
align-items:center;
justify-content:start;
height:50px;
`


export const LogButton = styled.div
`
height:50px;
min-width:100px;
`


export const LogUpdateFieldValue=styled.div`
width:100%;
padding:20px 15px;
border: 1.5px solid rgba(129, 61, 232, 0.3);
font-size:15px;
outline:none;
border-radius:8px;

:active{
    border: 2px solid #8C30F5;

}

:focus{
    border: 2px solid #8C30F5; 
}

::placeholder{
    font-size:15px;
}
`;

