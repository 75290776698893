import styled from "styled-components";

export const ManageKeyApiDecrypt = styled.div`
  width: 40%;
  max-width: 150px;
  height: 30px;
  @media (max-width: 550px) {
    width: 55%;
    font-size:10px;
    height:22px;
  }
  
`;

export const ManageKeyApiActionBtn = styled.div`
  width: 30%;
  max-width: 150px;
  height: 30px;
  color: rgba(140, 48, 245, 0.6);
  cursor: pointer;

  :hover {
    color: #8c30f5;
  }
  @media (max-width: 550px) {
    width: 55%;
    font-size:10px;
  }
`;
