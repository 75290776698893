import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import React, { useState,useEffect } from "react";
import { CancelBtn, CancelBtnDiv, CheckoutButtonStyles, CheckoutMainDiv, TextFieldStyles } from "../../../styles/dashBoardStyles/paymentDialogs/stripeCheckout.styles";
import { AlertStyles } from "../../../styles/registerComponent.styles";
import { Alert } from "@mui/material";
import {FaTimes} from "react-icons/fa";
import { Oval } from "react-loader-spinner";

function CheckoutForm(props) {
  
  
  const [alertForm, setAlertForm] = useState({
    message: "",
    severity: "success",
    show: false,
  });
  const [loading,setLoading]=useState(false);

  const closeDialog=()=>{
    props.getUserInfoApi();
    props.getUserPaymentInfoApi();
    props.setShowPaymentDialog(false);
  };

  
  
  // stripe items
  const stripe = useStripe();
  const elements = useElements();

  /*const confirmPayment=async()=>{
    return await stripe?.confirmCardPayment(
        props.paymentResponse.client_secret
      );
  };*/

  useEffect(()=>{
    //https://app.trysixth.com/dashboard?stripeRedirect=yes
    
    if(Object.keys(props.paymentResponse).length>0){
        
          if(props.paymentResponse.actionRequired){
            
              const fetch=async()=>{
                try{
                  //console.log("NONSENSE PROD DEBUG",elements?.getElement(CardElement),"Responding");
                  const {error, paymentIntent }=await stripe.confirmCardPayment(
                    props.paymentResponse.clientSecret,
                    {
                      payment_method: {
                        card: elements?.getElement(CardElement),
                        billing_details : {
                          name: props.name,
                          email:props.email
                        },
                      },
                      return_url: `http://localhost:3000/dashboard?stripeRedirect=yes&intent_id=${props.paymentResponse.id}&price_id=${props.priceId}&email=${props.email}&user_id=${props.user_id}`
                    },
                    {handleActions: false}
                    )

                    /* .then(function(result){
                      if (result.error){
                        console.log("NONSENSE PROD DEBUG","result",result.error,"INTIAL");
                        setLoading(false);
                        setAlertForm({
                          message: "Error in payment, please try again later",
                          severity: "error",
                          show: true,
                        });
                      }
                      }); */
                    
                    console.log("NONSENSE PROD DEBUG","INTIAL");
              if(error){
                setLoading(false);
                setAlertForm({
                  message: "Error in payment, please try again later",
                  severity: "error",
                  show: true,
                });}
              if(paymentIntent!=null){
                //console.log("NONSENSE PROD DEBUG",paymentIntent,paymentIntent.status);
                if (paymentIntent.status === "succeeded"){
                  console.log("YEA AFTER 3DS");
                  await props.checkPaymentSuccess(props.priceId,props.email,props.user_id,props.paymentResponse.id)
                  setLoading(false);
                  props.setPaid(true);
                  setAlertForm({
                    message: "you've subscribed for premium successfully,check your email for invoice.",
                    severity: "success",
                    show: true,
                  });
                }
                if (paymentIntent.status === "requires_action"){
                  //console.log(paymentIntent.next_action.redirect_to_url.url,"redirection URL");
                  //await stripe.handleCardAction(props.paymentResponse.clientSecret);
                  window.location =paymentIntent.next_action.redirect_to_url.url;
                }
              }else{
                //console.log("NONSENSE PROD DEBUG",paymentIntent,"error");
              }
                }catch(error){
                  setLoading(false);
                  //console.log("FUCK ERROR IN 3DS",error);
                  setAlertForm({
                    message: "Error in payment, please try again later",
                    severity: "error",
                    show: true,
                  });}
              };
              fetch();
              
            }else{
            //console.log("INSPECT",props.paymentResponse.message);
            props.setPaid(true);
            setLoading(false);
            setAlertForm({
              message: "you've subscribed for premium successfully,check your email for invoice.",
              severity: "success",
              show: true,
            });
          }
          
    }
  },[props.paymentResponse]);

  useEffect(()=>{
    setLoading(false);
    
    if(props.apiErrors.error_type!=null){
      if(props.apiErrors.error_type=="stripeError"){
        setAlertForm({
          message: props.apiErrors.message,
          severity: "error",
          show: true,
        });
      }
    }
  },[props.apiErrors])


  // main function
  const createSubscription = async (priceId,name,email) => {
    setLoading(true);
    
    try{
      // create a payment method
    const paymentMethod = await stripe?.createPaymentMethod({
      type: "card",
      card: elements?.getElement(CardElement),
      billing_details: {
        name,
        email
      },
    });

    // call the backend to create subscription
    
    
    const payload={
      paymentMethod: paymentMethod?.paymentMethod?.id,
      name:props.name,
      email:props.email,
      price_id:props.priceId,
      user_id:props.user_id,
      payment_type:"stripe"
    }
    const response = await props.createSubscriptionForUser(payload);
    }catch(error){
      setAlertForm({
        message: error,
        severity: "error",
        show: true,
      });
    }
  };

  return (
    <CheckoutMainDiv>
      <CancelBtnDiv>
        <CancelBtn onClick={()=>closeDialog()} ><FaTimes/></CancelBtn>
      </CancelBtnDiv>
      {alertForm.show ? (
        <Alert
          sx={AlertStyles}
          onClose={()=>setAlertForm((prev)=>({...prev,show:false}))}
          severity={alertForm.severity}
        >
          {alertForm.message}
        </Alert>
      ) : (
        <></>
      )}
      <TextField  // this should not be a text field. maybe a radio button ro something
        label="Price Id"
        variant="outlined"
        sx={TextFieldStyles}
        InputProps={{
          readOnly: true,
        }}
        defaultValue={props.priceId}
      />
      
      <TextField
        label="Name"
        variant="outlined"
        sx={TextFieldStyles}
        InputProps={{
          readOnly: true,
        }}
        defaultValue={props.name}
      />
      <TextField
        label="Email"
        variant="outlined"
        sx={TextFieldStyles}
        InputProps={{
          readOnly: true,
        }}
        defaultValue={props.email}
      />

      <CardElement />
      <Button sx={CheckoutButtonStyles} onClick={()=>createSubscription(props.priceId,props.name,props.email)} variant="contained" disabled={!stripe}>
        {
        loading?
        <Oval
                height="20"
                width="20"
                color="#757575"
                secondaryColor="#BDBDBD"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
          :
          "Subscribe"
        }
      </Button>
    </CheckoutMainDiv>
  );
}

export default CheckoutForm;