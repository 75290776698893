import { Alert, IconButton, TextField } from "@mui/material";
import {
  LoginForm,
  LoginHeader,
  LoginInnerDiv,
  LoginInputButton,
  LoginMainDiv,
  OtherText,
  OtherTextSpan,
  LoginHeaderImage,
  LoginHeaderLogo,
  LoginHeaderText,
  GoogleLoginButton,
  Loginwithemailoption,
  Loginwithemailoptionbar,
  LoginInnerDivBorder,
  ForgetpasswordText,
  GoogleIcon,
  LoginHeadertext,
} from "../styles/loginComponent.styles";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
import { Oval } from "react-loader-spinner";
import { UserAuth } from "../contextApis/authContext";
import api from "../API/baseURL";
import { Link, useNavigate } from "react-router-dom";
import { AlertStyles, PasswordInput } from "../styles/registerComponent.styles";
import { useState, useEffect } from "react";
import sixthLogo from "../assets/sixth_logo_white.png";
import googleicon from "../assets/flat-color-icons_google.png";
import { RegularText } from "../styles/reusable/TextStyles";
import TwoFactorComponent from "./twoFactorComponent";

const LoginComponent = ({ SetKey = () => {} }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
 //
  const { signIn } = UserAuth();

  const [btnActive, setBtnActive] = useState(false);
  const navigate = useNavigate();
  const [codeSent, hasSentCode] = useState(false);
  const [alertForm, setAlertForm] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const handleSubmit = async () => {
    setAlertForm({
      message: "",
      severity: "success",
      show: false,
    });
    setBtnActive(true);

    if (email.length == 0) {
      setAlertForm({
        message: "invalid email input",
        severity: "error",
        show: true,
      });
      setBtnActive(false);

      return;
    }

    if (password.length > 0) {
      localStorage.clear();
      try {
        //const login = await signIn(email, password);
      

        try {
          const response = await api.post("/auth/init_login", {
            email: email,
            password: password,
          });

          hasSentCode(true);

         
        } catch (err) {
          console.log(err);
          if (err.response.data.detail == "Email or Password Incorrect") {
            setAlertForm({
              message: "incorrect email or password",
              severity: "error",
              show: true,
            });
          }
          else if(err.response.data.detail){
            setAlertForm({
              message: err.response.data.detail,
              severity: "error",
              show: true,
            });
          }
        }
      } catch (err) {
        console.log(err,"err loh")
        if (err.message == "Firebase: Error (auth/user-not-found).") {
          setAlertForm({
            message: "email not found",
            severity: "error",
            show: true,
          });
        } else if (err.message == "Firebase: Error (auth/wrong-password).") {
          setAlertForm({
            message: "incorrect password",
            severity: "error",
            show: true,
          });
        } else {
          setAlertForm({
            message: "something went wrong",
            severity: "error",
            show: true,
          });
        }
      }
      setBtnActive(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      navigate("/dashboard");
    }
  }, []);
  return (
    <>
      {codeSent ? (
        <>
        <TwoFactorComponent userEmail={email}  closePage ={hasSentCode} userKey={password}>
          {password}

        </TwoFactorComponent>
        </>
      ) : (
        <LoginMainDiv>
          {alertForm.show ? (
            <Alert
              sx={AlertStyles}
              onClose={() => setAlertForm((prev) => ({ ...prev, show: false }))}
              severity={alertForm.severity}
            >
              {alertForm.message}
            </Alert>
          ) : (
            <></>
          )}
          <LoginHeaderLogo>
            <LoginHeaderImage src={sixthLogo} alt="sixth-logo" />
          </LoginHeaderLogo>
          <LoginInnerDiv>
            <LoginInnerDivBorder style={{background:"var(--border-bg", color:"white"}}>
              <LoginHeader> Sign in </LoginHeader>
              <LoginHeaderText> Sign in to your sixth account</LoginHeaderText>
             

              <LoginForm
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                autoComplete="off"
                placeholder="Email"
              />

              <TextField
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                sx={PasswordInput}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                style={{color:"white"}}
                autocomplete="new-password"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() =>
                        setShowPassword((prev) => (prev == true ? false : true))
                      }
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff style={{color:"white"}} /> : <Visibility  style={{color:"white"}}/>}
                    </IconButton>
                  ),
                  style: {
                    color: "white", 
                    border: "none",
                  }
                }}
                placeholder="Password"
              />
           
              <LoginInputButton
                disabled={btnActive}
                onClick={(e) => handleSubmit()}
              >
                {btnActive ? (
                  <Oval
                    height="20"
                    width="20"
                    color="#757575"
                    secondaryColor="#BDBDBD"
                    ariaLabel="tail-spin-loading"
                    radius="2"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <>Sign in</>
                )}
              </LoginInputButton>
              <ForgetpasswordText onClick={() => navigate("/forget-password")}>
                <RegularText>forgot password?</RegularText>
              </ForgetpasswordText>
              

              <Loginwithemailoption>
                {" "}
                <RegularText>
                  {" "}
                  Don't have an account?{" "}
                  <OtherTextSpan onClick={() => navigate("/signUp")}>
                    {" "}
                    SIGN UP
                  </OtherTextSpan>
                </RegularText>{" "}
              </Loginwithemailoption>
            </LoginInnerDivBorder>
          </LoginInnerDiv>
        </LoginMainDiv>
      )}
    </>
  );
};
export default LoginComponent;
