import { useState } from "react";
import { DocItemDropDownIcon, DocItemDropDownText, DocumentationSideBarMainDiv, DropDownList, DropDownListItem, SideBarMainDivDocItem, SideBarMainDivDocItemDropDown } from "../../styles/documentationStyles/documentationSideBar.styles";
import {AiOutlineDown,AiOutlineUp} from 'react-icons/ai';


const DocumentationSideBar=(props)=>{

    const [sdkDropdown,setSdkDropDown]=useState(false);

    return(
        <DocumentationSideBarMainDiv>
            <SideBarMainDivDocItem onClick={()=>props.clickToggle("get_started")} >Get Started</SideBarMainDivDocItem>
            <SideBarMainDivDocItem onClick={()=>props.clickToggle("encryption")} >Encryption</SideBarMainDivDocItem>
            <SideBarMainDivDocItem onClick={()=>props.clickToggle("rate_limiting")} >Rate Limiting</SideBarMainDivDocItem>
            <SideBarMainDivDocItemDropDown onClick={()=>setSdkDropDown((prev)=>prev==true?false:true)} >
                <DocItemDropDownText>SDKs</DocItemDropDownText>
                <DocItemDropDownIcon>{sdkDropdown?<AiOutlineUp/>:<AiOutlineDown/>}</DocItemDropDownIcon>
            </SideBarMainDivDocItemDropDown>
            {
                sdkDropdown?
                <DropDownList>
                    <DropDownListItem onClick={()=>props.clickToggle("sdk_python")} >Python</DropDownListItem>
                    <DropDownListItem onClick={()=>props.clickToggle("sdk_node")} >Node (express) </DropDownListItem>
                    <DropDownListItem onClick={()=>props.clickToggle("sdk_js")} >Client side javascript</DropDownListItem>
                </DropDownList>
                :
                <></>
            }
        </DocumentationSideBarMainDiv>
    );
};
export default DocumentationSideBar;