import sixthLogo from "../../assets/waves.png";
import {
    Header_div,
    Header_img_div,
    Header_img_div_img,
    Header_div_text,
} from "../../styles/reusable/sixthHeader";
import { Header_Text } from "../../styles/reusable/TextStyles";

const SixthHeaderComponent = () => {
    return (
        <Header_div>
            <Header_img_div>
                <Header_img_div_img src={sixthLogo} />
            </Header_img_div>
            <Header_div_text>
                <Header_Text>Sixth</Header_Text>
            </Header_div_text>
        </Header_div>
    );
};


export default SixthHeaderComponent;
