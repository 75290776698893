import styled from "styled-components";


export const GetStartedMainDiv=styled.div`
width:83%;
height:100%;
box-sizing: border-box;

@media (max-width: 550px) {
 width:100%;
}
`;

export const GetStartedContent=styled.section`
height:88%;
display:flex;
justify-content:center;
align-items:${(props) =>props.started === true ? "start":"center"};
box-sizing: border-box;
overflow-y:scroll;

@media (max-width: 550px) {
 width:100%;
}
`;

export const GetStartedButton=styled.div`
width:12%;
padding:15px 0px;
text-align:center;
background:#F1E4FF;
color:#8C30F5;
font-size:14px;
border-radius: 8px;
outline:none;
user-select:none;
transition:background 0.3s ease;

:hover{
    opacity:0.7;
`;

export const BaseUrlInputDiv=styled.div`
display:flex;
flex-direction:column;
gap:4rem;
align-items:center;
box-sizing: border-box;
`;


export const BaseUrlCodeSampleDiv=styled.div`
display:flex;
width:-webkit-fill-available;
flex-direction:column;
background: rgb(40, 44, 52);
align-items:center;
border-radius:10px;
box-sizing: border-box;
font-size:1.2rem;
overflow:hidden;
`;



export const BaseUrlCodeSampleHead=styled.div`
display:flex;
justify-content:space-between;
color:white;
width: 95%;
background: rgb(40, 44, 52);

margin:1rem 2rem;
>p{
    cursor:pointer;
    :hover{
        color:var(--sixth-purple);

    }
}
`;


export const BaseUrlCodeTypes=styled.div`
display:flex;

flex-wrap:wrap;
justify-content:start;
color:rgb(40, 44, 52);
margin:1rem 2rem;
gap:50px;

`;



export const BaseUrlCodeType=styled.div`
cursor:pointer;
padding: 1rem 2rem;
border-radius:10px;
color:${(props) => props.active ? "white":"var(--border-bg"};
background:${(props) => props.active ? "var(--border-bg)":"white"};
transition:background 1s, color .7s;

`;




export const BaseUrlInputButton=styled.div`
width:100%;
height:56px;
display:flex;
text-align:center;
`;

