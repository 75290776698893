import { useContext, useEffect, useState } from "react";
import { ConfigEndpointDiv, ConfigHeader, EndpointDropDownDiv, EndpointNormDiv, EndpointUpdateField, NormEndPointUrl, RateLimitConfigMainDiv, SubmitUpdate, SubmitUpdateDiv, UpdateFieldInput, UpdateFieldLabel, UpdateFieldKeyValueInput, EndpointErrorFieldsDiv, ErrorFieldsRow, AddErrorBtn, FieldsRowCancelBtn, EndpointHeadersFieldsDiv, EndpointToggleDiv, EndpointToggleIcon, ConfigContent, SelectWrapperDiv, SelectWrapperHeader } from "../../../styles/dashBoardStyles/rateLimiting/configuration.styles";
import { DashBoardContext } from "../../../contextApis/dashboardContext";
import { useNavigate } from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';
import useRateLimitConfig from "../../../apiLayer/dashBoardAPIs/rateLimiting/configuration";
import api from "../../../API/baseURL";
import GlobalLoader from "../../globalLoader";
import { FaTimes } from "react-icons/fa";
import Select from "react-select";
import Switch from "react-switch";
import Header from "../../header";



const RateLimitConfig=()=>{

    const [show,setShow]=useState(null);
    const {baseUrl}=useContext(DashBoardContext);
    const [copyRoutes,changeCopyVal,updateRoute,loading,updateErrorPayload,updateErrorPayloadKeyVal,
    newErrors,updateNewErrorKey,updateNewErrorVal,addNewError,removeError,rateLimitByVals,
    changeUniqueIdVal,setNewErrors,changeActiveStatus,addNewRule,removeNewRule,updateRule,updateRuleValue]=useRateLimitConfig();

    useEffect(()=>{
        setNewErrors([]);
    },[show])    

    

    return(
        <RateLimitConfigMainDiv >
            <Header />
            <ConfigContent>
            {loading?<div style={{display:"flex", justifyContent:"center", width:"100%", height:"100%"}} >
                <div>
                <Player
                    src='https://assets6.lottiefiles.com/packages/lf20_t9gkkhz4.json'
                    className="player"
                    loop
                    autoplay
                    background='#FFFFFF'
                    style={{ width: '80%' }}
                />
                <div style={{textAlign:"center", fontSize:"24px"}}>Loading endpoints of {baseUrl}..</div>
                </div>
            </div>:<ConfigHeader>Rate limiting configuration for {baseUrl}</ConfigHeader>}
            
            {
                Object.keys(copyRoutes).map((item,index)=>(
            <ConfigEndpointDiv onClick={()=>setShow((prev)=>prev==index?null:index)} >
                <EndpointNormDiv>
                    <NormEndPointUrl>{item.replace(/~/g, "/")}</NormEndPointUrl>
                </EndpointNormDiv>
                <EndpointDropDownDiv onClick={(e)=>e.stopPropagation()} show={show==index}>
                    <EndpointToggleDiv>
                            <EndpointToggleIcon>
                                <label>
                                    
                                        <Switch
                                        onChange={(e)=>changeActiveStatus(item,copyRoutes[item]["is_active"]?false:true)}
                                        checked={copyRoutes[item]["is_active"]}
                                        
                                        />
                                </label>
                            </EndpointToggleIcon>
                    </EndpointToggleDiv>
                    <EndpointUpdateField>
                        <UpdateFieldLabel>Rate limit</UpdateFieldLabel>
                        <UpdateFieldInput 
                        value={copyRoutes[item]["rate_limit"]}
                        onChange={(e)=>changeCopyVal(item,"rate_limit",e.target.value)}
                        placeholder="rate limit" />
                    </EndpointUpdateField>
                    <EndpointUpdateField>
                        <UpdateFieldLabel>Interval</UpdateFieldLabel>
                        <UpdateFieldInput
                        value={copyRoutes[item]["interval"]}
                        onChange={(e)=>changeCopyVal(item,"interval",e.target.value)}
                        placeholder="interval" />
                    </EndpointUpdateField>
                    <EndpointUpdateField>
                        <UpdateFieldLabel>Unique id to monitor for rate limiting(this can be a field in the payload this endpoint expects)</UpdateFieldLabel>
                        <UpdateFieldInput
                        value={copyRoutes[item]["unique_id"]}
                        onChange={(e)=>changeCopyVal(item,"unique_id",e.target.value)}
                        placeholder="unique_id" />
                    </EndpointUpdateField>
                    <EndpointUpdateField>
                        <UpdateFieldLabel> Rate limit by (Indicate option where the unique_id should be located)</UpdateFieldLabel>
                        <Select options={rateLimitByVals} defaultValue={{label:copyRoutes[item]["rate_limit_type"],value:copyRoutes[item]["rate_limit_type"]}} onChange={(selected)=>changeUniqueIdVal(item,selected.value)} />
                    </EndpointUpdateField>
                    <EndpointUpdateField>
                        <UpdateFieldLabel>Rate limit by Rules</UpdateFieldLabel>
                        
                        {
                            
                            Object.keys(copyRoutes[item]["rate_limit_by_rules"]).map((item2)=>(
                                
                                    copyRoutes[item]["rate_limit_by_rules"][item2]!=null?
                                    <SelectWrapperDiv>
                                        <Select options={rateLimitByVals} defaultValue={{label:item2,value:item2}} onChange={(selected)=>updateRule(item,item2,selected.value,copyRoutes[item]["rate_limit_by_rules"][item2])} />
                                        <SelectWrapperHeader>Rule's unique id</SelectWrapperHeader>
                                        <UpdateFieldInput
                                        value={copyRoutes[item]["rate_limit_by_rules"][item2]}
                                        onChange={(e)=>updateRuleValue(item,item2,e.target.value)}
                                        placeholder="rule's unique_id" />
                                    </SelectWrapperDiv>  
                                    :
                                    <></>
                                
                            ))

                        }
                        <SubmitUpdateDiv>
                            <AddErrorBtn onClick={()=>addNewRule(item)} >Add</AddErrorBtn>
                            <AddErrorBtn onClick={()=>removeNewRule(item)} >Remove</AddErrorBtn>
                        </SubmitUpdateDiv>
                    </EndpointUpdateField>
                    <EndpointErrorFieldsDiv>
                        <UpdateFieldLabel>Error Payload</UpdateFieldLabel>
                        {
                            Object.keys(copyRoutes[item]["error_payload"]).map((id,index)=>(
                                <>
                                {
                                    Object.keys(copyRoutes[item]["error_payload"][id]).map((item2,index)=>(
                                        <>
                                        {
                                        item2!="uid"?
                                        <ErrorFieldsRow>
                                            <UpdateFieldKeyValueInput value={item2} 
                                            onChange={(e)=>updateErrorPayloadKeyVal(item,id,item2,e.target.value)} />
                                            <UpdateFieldKeyValueInput value={copyRoutes[item]["error_payload"][id][item2]} 
                                            onChange={(e)=>updateErrorPayload(item,id,item2,e.target.value)} />
                                        </ErrorFieldsRow>
                                        :
                                        <></>
                                        }
                                        </>
                                    ))
                                }
                                </>
                            ))
                        }
                        {
                            newErrors.map((item,index)=>(
                                Object.keys(item).map((newError,indexTwo)=>(
                                    <>
                                {
                                    newError!="uid"&&newError!="route"?
                                <ErrorFieldsRow>
                                    <UpdateFieldKeyValueInput value={newError} 
                                    onChange={(e)=>updateNewErrorKey(index,newError,e.target.value)} />
                                    <UpdateFieldKeyValueInput value={newErrors[index][newError]} 
                                    onChange={(e)=>updateNewErrorVal(index,newError,e.target.value)} />
                                    <FieldsRowCancelBtn onClick={()=>removeError(index,newError)} ><FaTimes/></FieldsRowCancelBtn>
                                </ErrorFieldsRow>
                                :
                                <></>
                                }
                                </>
                                ))
                            ))
                        }
                        <SubmitUpdateDiv>
                            <AddErrorBtn onClick={()=>addNewError(item)} >Add</AddErrorBtn>
                        </SubmitUpdateDiv>
                    </EndpointErrorFieldsDiv>
                    <SubmitUpdateDiv>
                        <SubmitUpdate isActive={true} onClick={(e)=>updateRoute(item)} >submit</SubmitUpdate>
                    </SubmitUpdateDiv>
                </EndpointDropDownDiv>
            </ConfigEndpointDiv>
                ))
            }
            </ConfigContent>
        </RateLimitConfigMainDiv>
    );
};  

export default RateLimitConfig;