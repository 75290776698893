import {
  VsExtensionFile,
  VsExtensionEditor,
  VsExtensionMainDiv,
  VsExtensionView,
  VsCodeLinesDiv,
  VsExtensionHeader,
} from "../../../styles/dashBoardStyles/OverviewComponents/vsReportstyle";
import {
  RegularText,
  SubHeaderText_H2,
} from "../../../styles/reusable/TextStyles";
import vsImg from "../../../assets/Group1261152411.png";
import PropTypes from "prop-types";

const VsReportComponent = ({
  report,
  filename,
  owner,
  snippet = [],
  error,
}) => {
  snippet.sort(function (a, b) {
    return a.line - b.line; // Sort by 'age' in ascending order
  });
  return (
    <VsExtensionMainDiv>
      <VsExtensionHeader>
        <VsExtensionFile>
          <RegularText>
            {filename} &nbsp;({owner})
          </RegularText>
        </VsExtensionFile>

        <VsExtensionView>
          <RegularText> view details</RegularText>
        </VsExtensionView>
      </VsExtensionHeader>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <VsExtensionEditor>
          {snippet[0] ? (
            <VsCodeLinesDiv error={error.line == snippet[0].line}>
              <SubHeaderText_H2 className="noSpace">
                {snippet[0].line + " "}
                {snippet[0].code}
              </SubHeaderText_H2>
            </VsCodeLinesDiv>
          ) : (
            <></>
          )}
          {snippet[1] ? (
            <VsCodeLinesDiv error={error.line == snippet[1].line}>
              <SubHeaderText_H2 className="noSpace">
                {snippet[1].line + " "}
                {snippet[1].code}
              </SubHeaderText_H2>
            </VsCodeLinesDiv>
          ) : (
            <></>
          )}

          {snippet[2] ? (
            <VsCodeLinesDiv error={error.line == snippet[2].line}>
              <SubHeaderText_H2 className="noSpace">
                {snippet[2].line + " "} {snippet[2].code}
              </SubHeaderText_H2>
            </VsCodeLinesDiv>
          ) : (
            <></>
          )}
        </VsExtensionEditor>
      </div>
    </VsExtensionMainDiv>
  );
};

VsReportComponent.prototype = {
  report: PropTypes.object,
};

export default VsReportComponent;
