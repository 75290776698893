import { useState,useEffect } from "react";
import { PaymentGatewayMainDiv, PaystackLoadingDiv } from "../../../styles/dashBoardStyles/paymentDialogs/paymentGatewayDialog.styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./stripeCheckout";
import { paymentPlansObject, userTypeEnum } from "../../../utils/enums";
import { RotatingLines } from "react-loader-spinner";




const PaymentGatewayDialog=(props)=>{
    //console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,"yoma");
    const stripeKey=process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
    const stripePromise = loadStripe(stripeKey);

    useEffect(()=>{
        if(Object.keys(props.paymentResponse).length>0){
            if(props.paymentResponse.authorization_url!=null){
                window.location.href =props.paymentResponse.authorization_url;
            }
        }
    },[props.paymentResponse])

    useEffect(()=>{
        
        if(props.userInfo.country=="Not yet"){
            if(props.userInfo.user_type==userTypeEnum.DEVELOPER){
                const planCode=props.userInfo.user_type==userTypeEnum.DEVELOPER?process.env.REACT_APP_PAYSTACK_NGN_DEVELOPER_PLAN_ID:process.env.REACT_APP_PAYSTACK_COMPANY_PLAN_ID;
                const amount=props.userInfo.user_type==userTypeEnum.DEVELOPER?"5":"100";
                const payload={
                    user_id:props.userInfo.id,
                    email:props.userInfo.email,
                    plan_code:planCode,
                    amount:parseInt(amount.replace(/\D/g, ''), 10),
                    payment_type:"paystack"
                }
                props.createSubscriptionForUser(payload)
            }
        }
    },[]);

    return(
        <PaymentGatewayMainDiv>
            {
                props.userInfo.country==null?
                <Elements stripe={stripePromise} >
                    <CheckoutForm email={props.userInfo.email} name={props.userInfo.fullname} 
                    apiErrors={props.apiErrors} setShowPaymentDialog={props.setShowPaymentDialog} 
                    createSubscriptionForUser={props.createSubscriptionForUser} 
                    paymentResponse={props.paymentResponse} setUserPlan={props.setUserPlan} 
                    user_id={props.userInfo.id} 
                    priceId={props.price} 
                    getUserInfoApi={props.getUserInfoApi} getUserPaymentInfoApi={props.getUserPaymentInfoApi} 
                    setPaid={props.setPaid} checkPaymentSuccess={props.checkPaymentSuccess}
                    />
                </Elements>
                :
                <PaystackLoadingDiv>
                    <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                            />
                </PaystackLoadingDiv>
            }
        </PaymentGatewayMainDiv>
    )

};
export default PaymentGatewayDialog;