import { useState } from "react";
import api from "../../../API/baseURL";
import { useEffect } from "react";
import useTokenExpired from "../../expirationEvent";

const useEncryptionStartAPI=()=>{

    const [checkTokenExpiration]=useTokenExpired();

    const [data,setData]=useState(null);

    const getUserEncryptionStatus=async()=>{
        try{
            const response= await api.get("/encryption-service/get-encryption-service",{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            setData(response.data);
        }catch(err){
            checkTokenExpiration(err);
            console.log(err);
        }
    };

    useEffect(()=>{
        getUserEncryptionStatus();
    },[])

    return [data]
};
export default useEncryptionStartAPI;