import {
  PenTestSection,
  DetailItemHeader,
  PenTestDetailItem,
  PenTestDetailMainDiv,
  PenTestVid,
  PenTestVid_div,
  PenTestSection_Text,
  PenTestImg,
} from "../../../styles/dashBoardStyles/penTesting/penTestDetail.styles";
import { SubHeaderText_H2, SubText } from "../../../styles/reusable/TextStyles";
import { DashboardAlertElementStatusDiv } from "../../../styles/reusable/BorderStyles";
import { useState } from "react";

import PropTypes from "prop-types";

const PenTestDetail = ({
  attackCount,
  pending = false,
  Category = [],
  title = "attack",
  status,
  description,
  Medias = [],
  mitigation,
}) => {
  const [readMore, SetReadMore] = useState(true);

  const Loger = (value) => {
    console.log(value);
  };
  return (
    <PenTestDetailMainDiv>
      <DetailItemHeader>
        <PenTestSection>
          <SubHeaderText_H2> Number of attack: {attackCount}</SubHeaderText_H2>
        </PenTestSection>
        <PenTestSection>
          <SubHeaderText_H2> Status:</SubHeaderText_H2>
          {pending == false ? (
            <DashboardAlertElementStatusDiv
              className="security noMargin"
              variant={"pending"}
            >
              Pending
            </DashboardAlertElementStatusDiv>
          ) : (
            <DashboardAlertElementStatusDiv className="security">
              Mitigated
            </DashboardAlertElementStatusDiv>
          )}
        </PenTestSection>

        <PenTestSection>
          <SubHeaderText_H2> Category:</SubHeaderText_H2>
          {Category.map((value, i) => {
            return (
              <DashboardAlertElementStatusDiv className="payment">
                {value}
              </DashboardAlertElementStatusDiv>
            );
          })}
        </PenTestSection>
      </DetailItemHeader>

      <PenTestDetailItem>
        {readMore ? (
          <PenTestSection_Text>
            <PenTestSection>
              <SubHeaderText_H2> {title} </SubHeaderText_H2>
            </PenTestSection>
            <SubText>
              {description}
              <SubText className="link" onClick={() => SetReadMore(false)}>
                see more ...
              </SubText>
            </SubText>
          </PenTestSection_Text>
        ) : (
          <PenTestSection_Text>
            <PenTestSection>
              <SubHeaderText_H2> {title} </SubHeaderText_H2>
            </PenTestSection>
            <SubText>{description}</SubText>
          </PenTestSection_Text>
        )}

        {Medias.map((medias) => (
          <>
            {medias.type === "VIDEO" ? (
              <PenTestVid_div>
                <PenTestVid
                  src={medias.link}
                  frameborder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                  autoPlay={true}
                >
                  Your browser does not support the video tag.
                </PenTestVid>
              </PenTestVid_div>
            ) : (
              <PenTestVid_div>
                <PenTestImg
                  src={medias.link}
                  alt="Your browser does not support the image tag."
                />
               
              </PenTestVid_div>
            )}
          </>
        ))}
        
                {readMore ? (
                  <></>
                ) : (
                  <PenTestSection_Text>
                    <PenTestSection>
                    <SubHeaderText_H2>
                      Solution to {title}
                    </SubHeaderText_H2>

                    </PenTestSection>
                   
                    <SubText>{mitigation}</SubText>
                    <SubText className="link" onClick={() => SetReadMore(true)}>
                      see less ...
                    </SubText>
                  </PenTestSection_Text>
                )}
      </PenTestDetailItem>
    </PenTestDetailMainDiv>
  );
};

PenTestDetail.propTypes = {
  attackCount: PropTypes.string,
  pending: PropTypes.bool,
  Category: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.string,
  Medias: PropTypes.array,
  mitigation: PropTypes.string,
};

export default PenTestDetail;
