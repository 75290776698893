import styled from "styled-components";


export const ComingSoonMainDiv=styled.div`
position:fixed;
top:0;
left:0;
width:100vw;
height:100vh;
display:flex;
justify-content:center;
align-items:center;
box-sizing: border-box;
background-color: rgba(0, 0, 0, 0.5);
z-index:100;
`;

export const ComingSoonInnerDiv=styled.div`
width:30%;
border-radius:10px;
padding:30px;
background:white;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
box-sizing: border-box;
`;

export const ComingSoonIcon=styled.img`
width:70%;
margin=bottom:15px;
`;

export const ComingSoonHeader=styled.div`
font-size:18px;
font-weight:600;
width:100%;
align-items:center;
text-align:center;
margin-bottom:10px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;

`;

export const ComingSoonCloseButton=styled.button`
width:100%;
border-radius:5px;
padding:13px 0px;
display:flex;
justify-content:center;
align-items:center;
background:#F1E4FF;
border:none;
color:#8C30F5;
font-size:14px;
outline:none;
user-select:none;
transition:background 0.3s ease;
margin-bottom:5px;

:hover{
    opacity: 0.7;
}
`;