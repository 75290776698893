import { useEffect, useRef, useState } from "react";
import { PenStartSecMainDiv } from "../../../styles/dashBoardStyles/penTesting/penStart.styles";
import DashboardStyledTab from "../../ReuseableComponent/dashboardStyledTab";
import Header from "../../header";
import {
  ConfigEndpointDiv,
  FieldInputDiv,
  ConfigHeader,
  EndpointDropDownDiv,
  UpdateFieldInputLong,
  EndpointNormDiv,
  NormEndPointUrl,
  RateLimitConfigMainDiv,

  SubmitUpdateDiv,

  UpdateFieldLabel,
  
} from "../../../styles/dashBoardStyles/rateLimiting/configuration.styles";
import Select from "react-select";
import api from "../../../API/baseURL";
import { Alert } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PenTestLoading from "../penTesting/penTestLoading";
import { CreateLogDiv ,CreateLogNormDiv,CreateLogField,CreateLogDropDownDiv, TabDiv, SecureLogContent} from "../../../styles/dashBoardStyles/secureLogs/securedLogsStyle";

import {
  InputStyle_Large,
  DropDownStyle,
  DropDownStyle_full,
  MainButtonStyle,
} from "../../../styles/reusable/inputTypes";
import { AlertStyles } from "../../../styles/registerComponent.styles";
import LogsComponent from "./LogsComponent";
import ConfirmDelete from "../../confirmDelete";
import useTokenExpired from "../../../apiLayer/expirationEvent";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3  }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 70,
    width: "100%",
    backgroundColor: "#F1E4FF",
  },
});

const SecureMonitorDashboard = () => {
  const [value, setValue] = useState(0);
  const [type, setType] = useState("");
  const [monitorTxt, setMonitorTxt] = useState("");
  const typeOptions = [
    { value: "bool", label: "Boolean" },
    { value: "str", label: "String" },
    { value: "int", label: "Integer" },
    { value: "dict", label: "JSON" },
    { value: "list", label: "Array" },
  ];
  const handleChange = (newValue) => {
    setValue(newValue);
    setEndPointSuccess({
      message: "",
      severity: "",
      show: false,
    });
  };
  const [show, setShow] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [logRoute, setLogRoute] = useState("");
  const [checkTokenExpiration]=useTokenExpired();

  const [btnActive, setBtnActive] = useState(true);
  const [endPointSuccess, setEndPointSuccess] = useState({
    message: "",
    severity: "",
    show: false,
  });
  const [secureLogsLoading, setSecureLogsLoading] = useState(false);
  const [allSecureLogs, setAllSecureLogs] = useState([]);
  {
  }

  const DeleteLog = (id) => {
    SetDeleteLog({
      newDelete: true,
      id: id,
      message: "Delete log for " + allSecureLogs[id].value.replace(/~/g, "/"),
    });
  };

  const DeleteLogAsync = async () => {
    console.log(localStorage.getItem("token"));
    try {
      const response = await api.delete(
        "/secure-monitoring/delete-secure-log",
        {
          params: {
            apikey: localStorage.getItem("user_id"),
            id: allSecureLogs[deleteLog.id].id,
          },

          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            accept: "application/json",
          },
        }
      );

      if (response.status == 200) {
        SetDeleteLog({
          id: "-1",
          newDelete: false,
          message: "Delete Log",
        });

        await getAllSecureLogs();

        return "success";
      } else {
        return "failed";
      }
    } catch (e) {
      checkTokenExpiration(e);
    }
  };

  const [deleteLog, SetDeleteLog] = useState({
    id: "-1",
    newDelete: false,
    message: "Delete Log",
  });

  const [editLog, SetEdit] = useState({
    id: "-1",
    newEdit: false,
    message: "Edit Log",
  });

  const EditLog = (id) => {
    updateType(allSecureLogs[id].type);
    updateLogRoute(allSecureLogs[id].route.replace(/~/g, "/"));
    setMonitorTxt(allSecureLogs[id].value);
    SetEdit({ id: id, newEdit: true, message: "editLog" });
    handleChange(0);
    console.log(editLog.newEdit);
  };

  const editSecureLogAsync = async () => {
    setBtnActive(false);
    setEndPointSuccess({
      message: "",
      severity: "",
      show: false,
    });
    if (monitorTxt.length < 1) {
      setEndPointSuccess({
        message: "check your input and  try again ",
        severity: "error",
        show: true,
      });

      return;
    } else if (logRoute == "" || logRoute == null) {
      setEndPointSuccess({
        message: "please provide a log route ",
        severity: "error",
        show: true,
      });
      return;
    } else if (type == "" || type == null) {
      setEndPointSuccess({
        message: "please provide a type ",
        severity: "error",
        show: true,
      });
      return;
    }

    try {
      const response = await api.patch(
        "/secure-monitoring/update-secure-log",
        {
          apikey: localStorage.getItem("user_id"),
          type: type,
          value: monitorTxt.replace(/\s/g, ""),
          route: logRoute,
          is_active: true,
          id: allSecureLogs[editLog.id].id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status != 200) {
        setEndPointSuccess({
          message: "something went wrong try again ",
          severity: "fail",
          show: true,
        });
      } else {
        await getAllSecureLogs();
        setTimeout(() => {
          handleChange(1);
        }, 2000);
        updateType("");
        updateLogRoute("");
        setMonitorTxt("");
        SetEdit({
          id: "-1",
          newEdit: false,
          message: "Delete Log",
        });

        setEndPointSuccess({
          message:
            " Log has been updated  — check it out in the view all logs section!",
          severity: "success",
          show: true,
        });
      }
    } catch (e) {
      checkTokenExpiration(e);
    }

    setBtnActive(true);
  };

  const createSecureLog = async () => {
    setBtnActive(false);
    setEndPointSuccess({
      message: "",
      severity: "",
      show: false,
    });
    if (monitorTxt.length < 1) {
      setEndPointSuccess({
        message: "check your input and  try again ",
        severity: "error",
        show: true,
      });

      return;
    } else if (logRoute == "" || logRoute == null) {
      
      setEndPointSuccess({
        message: "please provide a log route ",
        severity: "error",
        show: true,
      });
      return;
    } else if (type == "" || type == null) {
      setEndPointSuccess({
        message: "please provide a type ",
        severity: "error",
        show: true,
      });
      return;
    }

    try {
      const response = await api.post(
        "/secure-monitoring/create-secure-log",
        {
          apikey: localStorage.getItem("user_id"),
          type: type,
          value: monitorTxt.replace(/\s/g, ""),
          route: logRoute,
          is_active: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status != 200) {
        setEndPointSuccess({
          message: "something went wrong try again ",
          severity: "fail",
          show: true,
        });
      } else {
        await getAllSecureLogs();
        setEndPointSuccess({
          message: " Log created — check it out in the view all logs section!",
          severity: "success",
          show: true,
        });
      }
    } catch (e) {
      checkTokenExpiration(e);
    }

    setBtnActive(true);
  };

  const updateLogRoute = async (route) => {
    setLogRoute(route);
  };

  const getAllSecureLogs = async () => {
    try {
      setSecureLogsLoading(true);
      const response = await api.get(
        `/secure-monitoring/get-all-secure-log?apikey=${localStorage.getItem(
          "user_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setAllSecureLogs([]);
        setAllSecureLogs((prevJsonList) => [
          ...prevJsonList,
          ...response.data["data"],
        ]);
        setSecureLogsLoading(false);
      }

      console.log(
        "response of loggsss is ",
        response.status,
        "  data ",
        allSecureLogs
      );
    } catch (e) {
      checkTokenExpiration(e);
      setTimeout(() => {
        getAllSecureLogs();
      }, 3000);
    }
  };

  const loadRoutes = async () => {
    try {
      const response = await api.get(
        `/project-config/list-project-routes-names/${localStorage.getItem(
          "user_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status == 200) {
        const data = response.data["data"];
        const updatedJsonList = data.map((item, index) => {
          return { value: item, label: item.replace(/~/g, "/") };
        });

        setRoutes((prevJsonList) => [...prevJsonList, ...updatedJsonList]);
      }
    } catch (e) {
      checkTokenExpiration(e);
    }
  };

  const updateType = async (type) => {
    setType(type);
  };

  const updateMonitorTxt = async (txt) => {
    setMonitorTxt(txt);
  };
  useEffect(() => {
    loadRoutes();
    getAllSecureLogs();
  }, []);

  return (
    <PenStartSecMainDiv>
      {endPointSuccess.show ? (
        <Alert
          sx={AlertStyles}
          severity={endPointSuccess.severity}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={(e) =>
                setEndPointSuccess({
                  show: false,
                })
              }
            >
              Close
            </Button>
          }
        >
          {endPointSuccess.message}
        </Alert>
      ) : (
        <></>
      )}
      {deleteLog.newDelete ? (
        <ConfirmDelete
          action={deleteLog.message}
          setDelete={SetDeleteLog}
          deleteAction={DeleteLogAsync}
        />
      ) : (
        <></>
      )}
      <Header />
      <DashboardStyledTab
        text={["Create log", "View all logs"]}
        active={value}
        handleClick={handleChange}
      />
      <SecureLogContent  clicked={ value==0} >
        <TabDiv
          
        >
          <CreateLogDiv>
            <CreateLogNormDiv>
              {editLog.newEdit ? (
                <NormEndPointUrl>Edit Log </NormEndPointUrl>
              ) : (
                <NormEndPointUrl>Create a new Log</NormEndPointUrl>
              )}
            </CreateLogNormDiv>
            <CreateLogDropDownDiv
              onClick={(e) => e.stopPropagation()}
              show={true}
            >
              <CreateLogField>
                <UpdateFieldLabel>
                  What do you want to monitor? It could be a string, boolean,
                  Integer, float, an array or a Json
                </UpdateFieldLabel>

                <FieldInputDiv>
                  <InputStyle_Large
                    value={monitorTxt}
                    onChange={(e) => updateMonitorTxt(e.target.value)}
                    multiline
                    placeholder="type here"
                    label="what you want monitored "
                  />
                </FieldInputDiv>
              </CreateLogField>
              <CreateLogField>
                <UpdateFieldLabel> What type of data is this?</UpdateFieldLabel>
                <Select
                  options={typeOptions}
                  defaultValue={
                    type == ""
                      ? typeOptions[0]
                      : typeOptions[
                          typeOptions.findIndex(
                            (value) => value.value.replace(/~/g, "/") == type
                          )
                        ]
                  }
                  onChange={(selected) => {
                    updateType(selected.value);
                  }}
                  styles={DropDownStyle_full}
                />
              </CreateLogField>

              <CreateLogField>
                <UpdateFieldLabel>
                  {" "}
                  Which of the endpoints do you want this to monitored on?
                </UpdateFieldLabel>
                <Select
                  options={routes}
                  defaultValue={
                    logRoute == ""
                      ? routes[0]
                      : routes[
                          routes.findIndex(
                            (value) =>
                              value.label.replace(/~/g, "/") == logRoute
                          )
                        ]
                  }
                  onChange={(selected) => {
                    updateLogRoute(selected.value);
                  }}
                  styles={DropDownStyle_full}
                />
              </CreateLogField>

              <SubmitUpdateDiv>
                {editLog.newEdit ? (
                  <MainButtonStyle onClick={(e) => editSecureLogAsync()}>
                    Make changes
                  </MainButtonStyle>
                ) : (
                  <MainButtonStyle onClick={(e) => createSecureLog()}>
                    Apply changes
                  </MainButtonStyle>
                )}
              </SubmitUpdateDiv>
            </CreateLogDropDownDiv>
          </CreateLogDiv>
        </TabDiv>
      </SecureLogContent>
      <SecureLogContent clicked ={value == 1} >
        {secureLogsLoading ? (
          <PenTestLoading></PenTestLoading>
        ) : (
          <div style={{ width: "100%", height: "100%" }}>
            {allSecureLogs.map((item, index) => (
              <LogsComponent
                title={item["value"].replace(/~/g, "/")}
                open={() => setShow((prev) => (prev == index ? null : index))}
                expand={show == index}
                dataType={
                  typeOptions[
                    typeOptions.findIndex(
                      (value) =>
                        value.label == item["type"] ||
                        value.value == item["type"]
                    )
                  ].label
                }
                close={(e) => e.stopPropagation()}
                endpointValue={item["route"].replace(/~/g, "/")}
                endpointLabel={item["route"].replace(/~/g, "/")}
                deleteLog={DeleteLog}
                editLog={EditLog}
                id={index}
              />
            ))}
          </div>
        )}
      </SecureLogContent>
    </PenStartSecMainDiv>
  );
};

export default SecureMonitorDashboard;
