import styled from "styled-components";


export const DocumentationSideBarMainDiv=styled.div`
width:17%;
position:fixed;
left:0;
top:10%;
height:90%;
padding:20px 5px 20px 0px;
background:white;
border-right: 1px solid #EEEEEE;
box-sizing: border-box;
user-select:none;
`;

export const SideBarMainDivDocItem=styled.div`
width:100%;
padding:10px;
font-size:15px;
font-weight:600;
border-top:1px solid #EEEEEE;
box-sizing: border-box;
:hover{
    cursor:pointer;
}
`;

export const SideBarMainDivDocItemDropDown=styled.div`
width:100%;
padding:10px;
display:flex;
border-top:1px solid #EEEEEE;
border-bottom:1px solid #EEEEEE;
justify-content:space-between;
align-items:center;
box-sizing: border-box;
`;

export const DocItemDropDownText=styled.div`
font-size:15px;
font-weight:600;
:hover{
    cursor:pointer;
}
`;

export const DocItemDropDownIcon=styled.div`
font-size:17px;
:hover{
    cursor:pointer;
}
`;

export const DropDownList=styled.div`
width:100%;
padding:10px;
box-sizing: border-box;
`;

export const DropDownListItem=styled.div`
width:100%;
padding:10px 15px;
font-size:13px;
font-weight:400;
box-sizing: border-box;

:hover{
    cursor:pointer;
    color:#039BE5;
}
`;



