import styled from "styled-components";


export const EncryptionStartMainDiv=styled.div`
width:83%;
height:100%;
box-sizing: border-box;

@media (max-width: 550px) {
 width:100%;
}
`;

export const EncryptionStartContent=styled.section`
height:88%;
display:flex;
justify-content:center;
align-items:center;
box-sizing: border-box;

@media (max-width: 550px) {
 width:100%;
}
`;

export const EncryptionStartInnerDiv=styled.div`
width:45%;
display:flex;
flex-direction:column;
align-items:center;
box-sizing: border-box;

@media (max-width: 550px) {
 width:90%;
}
`;

export const StartInnerHeader=styled.div`
font-size:20px;
font-weight:bold;
margin-bottom:15px;
`;

export const StartInnerSubHeader=styled.div`
font-size:15px;
font-weight:500;
margin-bottom:30px;
color:#757575;
`;

export const StartInnerLearnMore=styled.span`
font-size:15px;
font-weight:500;
color:#8C30F5;
cursor:pointer;
`;

export const StartInnerButton=styled.button`
width:70%;
border-radius:6px;
padding:13px 0px;
text-align:center;
background: #F1E4FF;
border:none;
color:#8C30F5;
font-size:14px;
outline:none;
user-select:none;
transition:background 0.3s ease;

:hover{
    background:#8C30F5;
    color:white;
}
`;