import { useState } from "react";
import api from "../../../API/baseURL";
import { useEffect } from "react";
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';
import useTokenExpired from "../../expirationEvent";


const useRateLimitConfig=()=>{

    const [routes,setRoutes]=useState({});
    const [checkTokenExpiration]=useTokenExpired();

    const [copyRoutes,setCopyRoutes]=useState({});

    const [loading,setLoading]=useState(false);

    const [newErrors,setNewErrors]=useState([]);
    const rateLimitByVals=[{value:"ip-address",label:"ip-address"},{value:"body",label:"body"},{value:"header",label:"header"},{value:"args",label:"args"}]

    useEffect(()=>{
        console.log(newErrors,"errs");
    },[newErrors])

    

    /*New Error Funcs*/
    const updateNewErrorKey=(index,oldKey,newKey)=>{
        const copy=cloneDeep(newErrors);
        for(const key in copy[index]){
            if(key==oldKey){
                const keepVal=copy[index][oldKey];
                delete copy[index][oldKey];
                copy[index][newKey]=keepVal;
            }
        }
        setNewErrors(copy);
    };

    const updateNewErrorVal=(index,key,val)=>{
        const copy=cloneDeep(newErrors);
        copy[index][key]=val;
        setNewErrors(copy);
    };

    const addNewError=(route)=>{
        let newKey=null;
        const newErr={};
        const copy=cloneDeep(newErrors);
        newKey=`error${copy.length}`;
            
        const uid = uuidv4();
        
        newErr[newKey]="new message";
        newErr["uid"]=uid;
        newErr["route"]=route;
        copy.push(newErr);
            
        
        setNewErrors(copy);
        
        
    };

    const removeError=(indexNeed,key)=>{
        const copy=cloneDeep(newErrors);
        
        for(const index in copy){
            console.log(copy[index],"yoh");
            if(copy[index][key]!=null&&indexNeed==index){
                copy.splice(index,1);
            }
        }
        
        setNewErrors(copy);
    };
    /*New Error Funcs*/

    

    const getRoutes=async()=>{
        setLoading(true);
        try{
            const response = await api.get("/project-config/get-project-routes_sec",{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            
            
            if(response.data!=null){
                setRoutes(response.data);
            }
            setLoading(false);
        }catch(err){
            checkTokenExpiration(err);
            console.log(err);
        }
    };

    const updateRoute=async(route)=>{
        
       const uptRoute=copyRoutes[route];
        for(const key in uptRoute){
            
            if(key=="route"){
                
                if(uptRoute[key]!=routes[route]["route"]){
                    console.log("keys is new", key)
                    
                    try{
                        const response=await api.patch("/project-config/update-route",
                        {old_route:routes[route]["route"],new_route:uptRoute[key].replace(/~/g, '/')},{
                            headers:{
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        });
                        console.log("an error occurer ss", response);
                        console.log(response);
                    }catch(err){
                        checkTokenExpiration(err);
                        console.log("an error occurer ss", err);
                    }
                }
            }else{
                if(uptRoute[key]!=routes[route][key]){
                    if (key == "error_payload"){   
                        // Convert keys to strings
                        const payload = {}
                        for (const error_keys in uptRoute[key]){
                            payload["route"]=route;
                            payload["uid"]=error_keys;
                            for(const keyy in uptRoute[key][error_keys]){
                                if(keyy!="uid"){
                                    payload["key"]=keyy;
                                    payload["value"]=uptRoute[key][error_keys][keyy]
                                }
                            }
                            
                            try{
                                const response=await api.patch("/project-config/update_route_data_error_payload",payload,{
                                    headers:{
                                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                        'Content-Type': 'application/json' 
                                    }
                                });
                            }catch(err){
                                checkTokenExpiration(err);
                                console.log("errorpayloadss sscalled, ", err);
                            }
                        }                          
                    }else if(key=="rate_limit_by_rules"){

                    }else{
                        
                        
                        try{
                            const response=await api.patch("/project-config/update_route_data",{route:route,field:key,value:uptRoute[key]},{
                                headers:{
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                    'Content-Type': 'application/json' 
                                }
                            });
                        }catch(err){
                            checkTokenExpiration(err);
                            console.log("an error occurer ss", key, routes[route][key]);
                        }
                    }
                }
                if(key=="rate_limit_by_rules"){
                    
                    if(typeof uptRoute[key]==="object"&& typeof routes[route][key]==="object" &&!objectsHaveSameKeysAndValues(uptRoute[key],routes[route][key])){
                        console.log("fucking work now?2")
                        try{
                            const response=await api.post("/project-config/update_ratelimit_rules",{route:route,update:uptRoute[key]},{
                                headers:{
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                    'Content-Type': 'application/json' 
                                }
                            });
                        }catch(err){
                            checkTokenExpiration(err);
                            console.log("an error occurer ss", key, routes[route][key]);
                        }
                    }
                    if(typeof uptRoute[key]==="object" && routes[route][key]==null){
                        console.log("fucking work now?3")
                        try{
                            const response=await api.post("/project-config/update_ratelimit_rules",{route:route,update:uptRoute[key]},{
                                headers:{
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                    'Content-Type': 'application/json' 
                                }
                            });
                        }catch(err){
                            checkTokenExpiration(err);
                            console.log("an error occurer ss", key, routes[route][key]);
                        }
                    }
                }
                
            }
        }
        if(newErrors.length>0){
        for(const index in newErrors){
            for(const key in newErrors[index]){
                if(key!="uid"&&key!="route"){
                    const payload={
                        route:newErrors[index]["route"],
                        uid:newErrors[index]["uid"],
                        key:key,
                        value:newErrors[index][key]
                    };
                    
                    try{
                        const response=await api.patch("/project-config/update_route_data_error_payload",payload,{
                            headers:{
                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                            }
                        });
                    }catch(err){
                        checkTokenExpiration(err);
                        console.log(err);
                    }
                }
            }
            
        }
        setNewErrors([]);
        }
        setLoading(false);
        setRoutes({});
        setCopyRoutes({});
        getRoutes();
    }

    const changeCopyVal=(route,field,val)=>{
        const copy={...copyRoutes};
        copy[route][field]=val;
        setCopyRoutes(copy);
    };

    const changeUniqueIdVal=(route,val)=>{
        
        const copy={...copyRoutes};
        copy[route]["rate_limit_type"]=val;
        setCopyRoutes(copy);
    }

    /* NEW RATE LIMIT BY RULES FUNCTIONS */
    const addNewRule=(route)=>{
        const copy=cloneDeep(copyRoutes);
        
        for(const obj of rateLimitByVals){
            if(!copy[route]["rate_limit_by_rules"].hasOwnProperty(obj["value"])){
                copy[route]["rate_limit_by_rules"][obj["value"]]="";
                break;
            }
        }
        setCopyRoutes(copy);
    };

    const removeNewRule=(route)=>{
        const copy=cloneDeep(copyRoutes);
        const copyKeys=Object.keys(copy[route]["rate_limit_by_rules"]);
        delete copy[route]["rate_limit_by_rules"][copyKeys[copyKeys.length-1]];
        setCopyRoutes(copy);
    };

    const updateRule=(route,oldRule,newRule,val)=>{
        const copy=cloneDeep(copyRoutes);
        delete copy[route]["rate_limit_by_rules"][oldRule];
        copy[route]["rate_limit_by_rules"][newRule]=val;
        setCopyRoutes(copy);
    };

    const updateRuleValue=(route,rule,newVal)=>{
        const copy=cloneDeep(copyRoutes);
        copy[route]["rate_limit_by_rules"][rule]=newVal;
        setCopyRoutes(copy);
    };
    /* NEW RATE LIMIT BY RULES FUNCTIONS */

    const changeActiveStatus=(route,bool)=>{
        console.log(bool,"bool");
        const copy=cloneDeep(copyRoutes);
        
        copy[route]["is_active"]=bool;
        setCopyRoutes(copy);
    };

    
    const updateErrorPayload=(routeKey,id,errorKey,errorVal)=>{
        const copy=cloneDeep(copyRoutes);
        
        copy[routeKey]["error_payload"][id][errorKey]=errorVal;
        setCopyRoutes(copy);
    };

    const updateErrorPayloadKeyVal=(routeKey,id,oldErrorKey,newErrorKey)=>{
        
        const copy=cloneDeep(copyRoutes);
        for(const key in copy[routeKey]["error_payload"][id]){
            
            if(key==oldErrorKey){
                
                const keepVal=copy[routeKey]["error_payload"][id][oldErrorKey];
                delete copy[routeKey]["error_payload"][id][oldErrorKey];
                copy[routeKey]["error_payload"][id][newErrorKey]=keepVal;
            }
        }
        setCopyRoutes(copy);
    };

    useEffect(()=>{
        getRoutes();
    },[])

    useEffect(()=>{
        const copy=cloneDeep(copyRoutes);
        
        for(const key in routes){
           
            if(copy[key]==null){
                copy[key]={};
                copy[key]["route"]=routes[key]["route"]
                copy[key]["rate_limit"]=routes[key]["rate_limit"]
                copy[key]["interval"]=routes[key]["interval"]
                copy[key]["unique_id"]=routes[key]["unique_id"]
                copy[key]["is_active"]=routes[key]["is_active"]
                
                if(routes[key]["error_payload"]!=null){
                    copy[key]["error_payload"]= routes[key]["error_payload"]
                }else{
                    copy[key]["error_payload"]= {}
                }

                

                if(routes[key]["rate_limit_type"]!=null){
                    copy[key]["rate_limit_type"]=routes[key]["rate_limit_type"];
                }else{
                    copy[key]["rate_limit_type"]="ip-address";
                }

                if(routes[key]["rate_limit_by_rules"]!=null){
                    console.log(routes[key]["rate_limit_by_rules"],"the hell")
                    copy[key]["rate_limit_by_rules"]=cloneDeep(routes[key]["rate_limit_by_rules"]) 
                }else{
                    copy[key]["rate_limit_by_rules"]={};
                }
            } 
        }

        
        
        setCopyRoutes(copy);
    },[routes])

    function objectsHaveSameKeysAndValues(obj1, obj2) {
        // Get the keys of both objects
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
      
        // Check if the number of keys is the same
        if (keys1.length !== keys2.length) {
          return false;
        }
      
        // Check if all keys and values match
        for (let key of keys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      
        return true;
      }

      
      
      
      

    

    return [copyRoutes,changeCopyVal,
        updateRoute,loading,updateErrorPayload,
        updateErrorPayloadKeyVal,newErrors,updateNewErrorKey,updateNewErrorVal,
        addNewError,removeError,rateLimitByVals,changeUniqueIdVal,setNewErrors,changeActiveStatus,
        addNewRule,removeNewRule,updateRule,updateRuleValue]
};
export default useRateLimitConfig;