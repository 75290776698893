import TwoFactorComponent from "../components/twoFactorComponent";

const TwoFactorPage= () =>
{
   return(
    
    <TwoFactorComponent>

    </TwoFactorComponent>
    
   ) 
};

export default TwoFactorPage ;