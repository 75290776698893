import styled from "styled-components";


export const SlacKRedirectCompMainDiv=styled.div`
width:100%;
height:100vh;
background:white;
display:flex;
justify-content:center;
align-items:center;
`;

export const SlackRedirectFormDiv=styled.div`
width:35%;
`;

export const FormDivHeader=styled.div`
font-size:30px;
font-weight:700;
width:100%;
display:flex;
align-items:center;
justify-content:center;
text-align:center;
margin-bottom:20px;
`;

export const FormDivForm=styled.input`
width:100%;
border: 1.5px solid #757575;
outline:none;
border-radius:5px;
margin-bottom:20px;
box-sizing: border-box;
font-size:15px;
padding:20px;

:focus{
    border:2.5px solid #03A9F4;
    outline:none;
}
`;

export const FormDivButton=styled.button`
width:100%;
border-radius:5px;
padding:20px 0px;
display:flex;
justify-content:center;
align-items:center;
background:#0277BD;
border:none;
color:white;
font-size:14px;
outline:none;
user-select:none;
transition:background 0.3s ease;
margin-bottom:20px;

:hover{
    background:#039BE5;
}
`;

export const AlertStyles={
    
    position: 'fixed',
    top: '2%',
    left: '15%',
    width:'75%',
    borderRadius:'5px',
    zIndex: 9999,
    
    
    boxShadow: 'none',
    padding: '10px',
    
    animation: 'slide-in 0.3s ease',
  
}