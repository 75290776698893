import styled from "styled-components";



export const DashBoardMainDiv=styled.div`
width:100%;
height:100vh;
display:flex;
box-sizing: border-box;

`;