import styled from "styled-components";


export const PaymentGatewayMainDiv=styled.div`
width:40%;
height:80%;
border-radius:8px;
background:white;
padding:30px 10px;
box-sizing: border-box;
box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);

@media (max-width: 850px) {

    width:80%;
    height:80%;
      
}
`;

export const PaystackLoadingDiv=styled.div`
width:100%;
height:100%;
display:flex;
justify-content:center;
align-items:center;
`;