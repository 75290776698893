import React, { useEffect, useState } from 'react';
import { AccountPopUp, Burger_Menu, Burger_toggle_Div, Burger_toggle_line, IconSection, LeftCont, LoggedAvatar, LoggedSection, MainHeaderCont, MobileMenuController, NotificationCount, RightCont } from '../styles/reusable/header';
import * as Icon from 'iconsax-react';
import { SortRounded } from '@mui/icons-material';
import {FaUserCircle} from "react-icons/fa"
import {CgProfile} from "react-icons/cg"
// import SideBarWidget from './sidebar';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import SideNavBar from './dashBoardComponents/SideNavBar/sideNavBar';

const Header = () => {

    const navigate = useNavigate();
    
    // States
    const [showAccountPopUp, setShowAccountPopUp] = useState(false);
    const [adminInformation, setAdminInformation] = useState({})

    const [openNav, setOpenNav] = useState(false);

    // Open and Close Nav Bar
    const toggleNavBar = () => {
        setOpenNav(!openNav);
    }

    // Onload Calls
    useEffect(() => {
        const loggedAdmin = JSON.parse(localStorage.getItem('lu') || '{}');
        setAdminInformation(loggedAdmin)
    }, [])

    // Log User Out of app

    const redirectAfterLogOut = () => {
        localStorage.clear();
        navigate('/login');
    }

    return(
        <>
            <MainHeaderCont onClick={() => setShowAccountPopUp(false)}>
                <MobileMenuController>
                    
                    <Burger_toggle_Div clicked = {openNav} onClick={() => toggleNavBar()}>
                        <Burger_toggle_line/>
                        <Burger_toggle_line/>
                        <Burger_toggle_line/>
                        

                      

                    </Burger_toggle_Div>

                    <Burger_Menu clicked = {openNav} >
                        <SideNavBar isHeader ={"true"}></SideNavBar>

                    </Burger_Menu>
                    
                    
                </MobileMenuController>
                <LeftCont>
                    <i>
                        <Icon.SearchNormal1 size={18} style={{position:"absolute",right
                    :"-5px",top:"-10px"}} />
                    
                    </i>
                    <input 
                        placeholder='Search'
                    />
                </LeftCont>
                <RightCont>
                    <LoggedSection>
                        <LoggedAvatar>
                            <FaUserCircle size={"100%"}/>
                            {//adminInformation && adminInformation.email ? adminInformation.email[0].toUpperCase() : ''
                            }
                        </LoggedAvatar>
                        <div>
                            <h3>{adminInformation && adminInformation.email ? adminInformation.email : 'Active User'}</h3>
                        </div>
                        <Icon.ArrowDown2 
                            size={18} 
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={(e) => {
                                setShowAccountPopUp(!showAccountPopUp)
                                e.stopPropagation();
                            }}
                        />
                        {
                            showAccountPopUp ?
                                <AccountPopUp>
                                    <Link to='/settings'>
                                        <div>
                                            <Icon.Setting2 />
                                            <p>General Settings</p>
                                        </div>
                                    </Link>
                                    <hr />
                                    <div
                                        onClick={() => redirectAfterLogOut()}
                                    >
                                       <Icon.Login></Icon.Login>
                                        <p>Logout</p>
                                    </div>
                                </AccountPopUp>
                            : null
                        }
                    </LoggedSection>
                </RightCont>
            </MainHeaderCont>

            {/* {
                openNav ? 
                    <SideBarWidget closeNav={toggleNavBar} />
                : null
            } */}
        </>
    )
}

export default Header;
