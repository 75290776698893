import { CodeBodyLineDiv, CodeInnerDivHeader, CodeInnerDivHeaderItem, CodeyBodyLineText, DocumentationBodyCodeBody, DocumentationBodyCodeInnerDiv, DocumentationBodyCodeOuterDiv, DocumentationBodyMainDiv, DocumentationBodyNormalText, DocumentationBodyNormalTextH, DocumentationBodyPrimaryHeader, DocumentationBodySecHeader, DocumentationBodyUlDiv } from "../../../../styles/documentationStyles/documentationBody.styles";




const DocSdkJs=()=>{

    const codeData={
        "javascript":{
            "1":[{"val":"import","color":"keywords"},{"val":"{encryptRequest}","color":"normal"},{"val":"sixthsense-client;","color":"strings"}],
            "2":[{"val":"const","color":"keywords"},{"val":"requestUrl=","color":"normal"},{"val":"https://yourBackendUrl/route","color":"strings"}],
            "3":[{"val":"const","color":"keywords"},{"val":"data=","color":"normal"},{"val":"{'pay':false};","color":"normal"}],
            "4":[{"val":"const","color":"keywords"},{"val":"decryptedResponse=","color":"normal"},{"val":"encryptRequest(requestUrl,data);","color":"strings"}]
            
        }
    }

    const codeLines=[1,2,3,4];

    const colors={
        "keywords":"#039BE5",
        "normal":"#616161",
        "strings":"#4CAF50",
        "method":"#FFC107"
    }

    return(
        <DocumentationBodyMainDiv>
            <DocumentationBodyPrimaryHeader>Javascript client side SDK</DocumentationBodyPrimaryHeader>
            <DocumentationBodyNormalText>Our Javascript client side sdk allows integration with client side  applications.</DocumentationBodyNormalText>
            <DocumentationBodyNormalText>The Javascript client side SDK so far offers the following features:</DocumentationBodyNormalText>
            <DocumentationBodyUlDiv>
                <li>Encryption and decryption of request/response</li>
            </DocumentationBodyUlDiv>
            <DocumentationBodySecHeader>Installation</DocumentationBodySecHeader>
            <DocumentationBodyNormalText>You can install Javascript client side  sdk into your javascript based client side applications with <DocumentationBodyNormalTextH>npm install sixth-node.</DocumentationBodyNormalTextH></DocumentationBodyNormalText>
            <DocumentationBodySecHeader>Example Usage</DocumentationBodySecHeader>
            <DocumentationBodyNormalText>Example below on how to add both decryption and encryption of request and response:</DocumentationBodyNormalText>
            <DocumentationBodyCodeOuterDiv>
                <DocumentationBodyCodeInnerDiv>
                    <CodeInnerDivHeader>
                        <CodeInnerDivHeaderItem show={true}>Javascript</CodeInnerDivHeaderItem>
                    </CodeInnerDivHeader>
                    <DocumentationBodyCodeBody>
                        {
                            codeLines.map((item,index)=>(
                                <CodeBodyLineDiv>
                                    {
                                    codeData["javascript"][item].map((item)=>(
                                        <CodeyBodyLineText color={colors[item.color]} >{item.val}</CodeyBodyLineText>
                                    ))
                                    }
                                </CodeBodyLineDiv>
                            ))
                        }
                    </DocumentationBodyCodeBody>
                </DocumentationBodyCodeInnerDiv>
            </DocumentationBodyCodeOuterDiv>
        </DocumentationBodyMainDiv>
    );
};
export default DocSdkJs;