import {
  BarBodyItem,
  BodyItemText,
  BodyItemIcon,
} from "../../../styles/dashBoardStyles/sideNavBar.styles";
import PropTypes from "prop-types";
import { IoDocumentText } from "react-icons/io5";
import { AiOutlineHome } from "react-icons/ai";
import { IoCardOutline } from "react-icons/io5";
import { TbReportAnalytics } from "react-icons/tb";
import { FaTerminal } from "react-icons/fa";




const DeveloperNavBar = ({ setToggleValue = () => {}, toggle }) => {
  return (
    <>
      <BarBodyItem
        clicked={toggle["overview"]}
        onClick={() => setToggleValue("overview")}
      >
        <BodyItemIcon>
          <AiOutlineHome />
        </BodyItemIcon>
        <BodyItemText>Overview</BodyItemText>
      </BarBodyItem>

      <BarBodyItem
        clicked={toggle["developer_integrations"]}
        onClick={() => setToggleValue("developer_integrations")}
      >
        <BodyItemIcon>
          <FaTerminal />
        </BodyItemIcon>
        <BodyItemText>Integration</BodyItemText>
      </BarBodyItem>

      <BarBodyItem
        clicked={toggle["billing"]}
        onClick={() => setToggleValue("billing")}
      >
        <BodyItemIcon>
          <IoCardOutline />
        </BodyItemIcon>
        <BodyItemText>Billing</BodyItemText>
      </BarBodyItem>

      <BarBodyItem
        clicked={toggle["developer-report"]}
        onClick={() => setToggleValue("developer-report")}
      >
        <BodyItemIcon>
          <TbReportAnalytics />
        </BodyItemIcon>
        <BodyItemText>Report</BodyItemText>
      </BarBodyItem>
    </>
  );
};


DeveloperNavBar.prototype =
{
    setToggleValue :PropTypes.func.isRequired,
    toggle:PropTypes.array.isRequired


}

export default DeveloperNavBar;
