import { useState } from "react";
import DocEncryption from "../components/documentationComponents/documentationBody/docEncryption";
import DocGetStarted from "../components/documentationComponents/documentationBody/docGetStarted";
import DocRateLimiting from "../components/documentationComponents/documentationBody/docRateLimiting";
import DocSdkJs from "../components/documentationComponents/documentationBody/docSDKs/javascript";
import DocSdkNode from "../components/documentationComponents/documentationBody/docSDKs/node";
import DocSdkPython from "../components/documentationComponents/documentationBody/docSDKs/python";
import DocumentationNav from "../components/documentationComponents/documentationNav";
import DocumentationSideBar from "../components/documentationComponents/documentationSideBar";
import { DocInnerDiv, DocumentationMainDiv } from "../styles/documentationStyles/main";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";



const Documentation=()=>{
    

    const [docToggle,setDocToggle]=useState({
        "get_started":true,
        "encryption":false,
        "rate_limiting":false,
        "sdk_python":false,
        "sdk_node":false,
        "sdk_js":false
    })
    const navigate = useNavigate();

    const clickToggle=(field)=>{
        const copy={...docToggle};
        for(const key in copy){
            if(key===field){
                copy[key]=true;
            }else{
                copy[key]=false;
            }
        }
        setDocToggle(copy);
    }

    const showDocumentation=()=>{
        window.open('https://docs.trysixth.com', '_blank');
    }


    useEffect(()=>{
        for(const key in docToggle){
            if(docToggle[key]===true){
                switch (key) {
                    case "get_started":
                        showDocumentation()
                        break;
                    case "rate_limiting":
                        navigate("/documentation/rate_limiting")
                        break;
                    case "encryption":
                        navigate("/documentation/encryption")
                        break;
                    case "sdk_python":
                        navigate("/documentation/sdk/python")
                        break;
                    case "sdk_node":
                        navigate("/documentation/sdk/node")
                        break;
                    case "sdk_js":
                        navigate("/documentation/sdk/js")
                        break;
                
                    default:
                        break;
                }   
            }
        }
    },[docToggle])

    return(
        <DocumentationMainDiv  >
            <DocumentationNav/>
            <DocInnerDiv>
                <DocumentationSideBar clickToggle={clickToggle}/>
                <Routes>
                    <Route index element={<DocGetStarted/>}/>
                    <Route path="encryption" element={<DocEncryption/>}/>
                    <Route path="rate_limiting" element={<DocRateLimiting/>}/>
                    <Route path="sdk/python" element={<DocSdkPython/>}/>
                    <Route path="sdk/node" element={<DocSdkNode/>}/>
                    <Route path="sdk/js" element={<DocSdkJs/>}/>
                </Routes>
            </DocInnerDiv>
        </DocumentationMainDiv>
    );
};
export default Documentation;