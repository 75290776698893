import styled from "styled-components";

export const VsExtensionMainDiv = styled.div`
  width: 100%;
  height: 100%;
`;

export const VsExtensionEditor = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background: rgb(39, 50, 54);
  border-radius: 16px;
  background: linear-gradient(
    140deg,
    rgba(39, 50, 54, 1) 0%,
    rgba(39, 70, 80, 0.8113620448179272) 86%
  );
`;

export const VsExtensionView = styled.div`
  cursor: pointer;
  min-width: 100px;
  display: flex;
  align-items: center;

  justify-content: flex-end;
  :hover {
    color: rgba(140, 48, 245, 1);
 
  }
     
@media (max-width: 550px) {
 justify-content: center;
}
`;

export const VsCodeLinesDiv = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  padding-left: ${(props) => (props.error ? "70px " : " 50px")};
  color: ${(props) => (props.error ? "#fc037b " : "white")};
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 728px) {
  }
`;

export const VsExtensionFile = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: initial;
  word-wrap: break-word;
`;

export const VsExtensionHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 100px;
  gap: 10px;

  @media (max-width: 728px) {
    padding:0px;
    justify-content:center;
    gap:5px
    flex-wrap:wrap;
}

@media (max-width: 550px) {
   flex-direction:column;
   gap:0px;
}
`;
