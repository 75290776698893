import { CodeBodyLineDiv, CodeInnerDivHeader, CodeInnerDivHeaderItem, CodeyBodyLineText, DocumentationBodyCodeBody, DocumentationBodyCodeInnerDiv, DocumentationBodyCodeOuterDiv, DocumentationBodyLinkText, DocumentationBodyMainDiv, DocumentationBodyNormalText, DocumentationBodyPrimaryHeader, DocumentationBodySecHeader } from "../../../styles/documentationStyles/documentationBody.styles";



const DocGetStarted=()=>{

    const colors={
        "keywords":"#039BE5",
        "normal":"#616161",
        "strings":"#4CAF50",
        "method":"#EEFF41"
    }

    return(
        <DocumentationBodyMainDiv>
            <DocumentationBodyPrimaryHeader>Welcome to Sixth Documentation</DocumentationBodyPrimaryHeader>
            <DocumentationBodySecHeader>Introduction</DocumentationBodySecHeader>
            <DocumentationBodyNormalText>Welcome to the Sixth documentation! Sixth is a comprehensive suite of tools and services designed to protect financial applications from various security threats. With Sixth, you can secure your application by leveraging encryption, request and response filtering, and rate limiting on the backend server.</DocumentationBodyNormalText>
            <DocumentationBodyNormalText>This "Get Started" guide will walk you through the necessary steps to integrate Sixth into your financial application. By following this guide, you'll learn how to utilize our services and SDKs effectively. You can get right into the docs by clicking <DocumentationBodyLinkText>here...</DocumentationBodyLinkText></DocumentationBodyNormalText>
        </DocumentationBodyMainDiv>
    );
};
export default DocGetStarted;