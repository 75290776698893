import styled from "styled-components";

export const SideNavBarMainDiv = styled.div`
  position: Relative;
  background: #fff;
  width: 17%;
  height: 100%;
  padding: 15px 5px;
  box-sizing: border-box;
  @media (max-width: 550px) {
    display: ${(props) => (props.header ? "block" : "none")};
    width: ${(props) => (props.header ? "100vw" : "17%")};
    z-index: 100;
  }
`;

export const SideNavBarHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 20px;
  display: ${(props) => (props.header ? "none" : "block")};
`;

export const SideNavHeaderLine = styled.div`
  width: 100%;
  height:5px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin-top:50px;
  background:#8C30F5;
  display: ${(props) => (!props.header ? "none" : "block")};
`;

export const BarHeaderLogo = styled.img`
  width: 35px;
  border-radius: 8px;
  margin-right: 6px;
  margin-bottom: 0px;
  background: white;
`;

export const BarHeaderText = styled.div`
  font-size: 17px;
  font-weight: 600;
  color: #161616;

  @media (max-width: 520px) {
    display: none;
  }
`;

export const SideNavBarBody = styled.div`
  width: 100%;
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  margin-top: ${(props) => (props.header ? "0.5rem" : " 5rem")};

  ::-webkit-scrollbar {
    width: 0px;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-track {
    background: #cbced1;
  }

  ::-webkit-scrollbar-thumb {
    background: grey;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: grey;
    width: 20px;
    cursor: pointer;
  }
`;

export const BarBodyItem = styled.div`
  width: 100%;
  padding: 15px 20px;
  display: flex;
  font-size: 15px;
  user-select: none;
  color: ${(props) => (props.clicked ? "#161616" : "#161616")};
  border-left: ${(props) => (props.clicked ? "5px solid #8C30F5" : "none")};
  transition: border-left color 0.3s ease;
  box-sizing: border-box;
  cursor: pointer;
`;

export const BodyItemIcon = styled.div`
  margin-right: 10px;
`;

export const BodyItemText = styled.div`
  font-weight: 400;

  @media (max-width: 820px) {
    font-size: 10px;
  }
`;

export const SideNavBarFooter = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 20px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 520px) {
    position: relative;
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-indent: -99999999px;
  }
  // padding:0 20px;
`;

export const FooterLogout = styled.div`
  width: 100%;
  color: #e24848;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

export const SideNarHamBurger = styled.div``;
