import { useState } from "react";
import {
  ManageKeyApiActionBtn,
  ManageKeyApiDecrypt,
} from "../../../styles/dashBoardStyles/MangeKey/managekeyCompnentStyle";
import {
  ManageKeyApiBodyBorder_entry,
  ManageKeyApiField,
  ManageKeyApiField_center,
  ManageKeyApiField_end,
} from "../../../styles/dashBoardStyles/MangeKey/managekeyStyles";
import { SubHeaderText_H2 } from "../../../styles/reusable/TextStyles";
import { MainButtonStyle } from "../../../styles/reusable/inputTypes";
import PropTypes from "prop-types";
import { FiEdit } from "react-icons/fi";
import { MdFolderDelete } from "react-icons/md";
import { Oval } from "react-loader-spinner";

const ManageKeyComponent = ({
  value,
  name,
  onEdit,
  onDelete,
  id,
  onDecrypt,
}) => {
  const [encryptedValue, SetEncryptedValue] = useState("");
  const [isEncrypted, SetEncrypted] = useState(true);
  const Close = () => {
    onDelete(id);
  };
  const edit = () => {
    onEdit(id);
  };

  const Clear = () => {
    SetEncrypted(true);
    SetEncryptedValue("*****************");
  };
  const Decrypt = async () => {
    var result = await onDecrypt(value);
    SetEncryptedValue(result);
    SetEncrypted(false);

    setTimeout(() => {
      Clear();
    }, 6000);
  };
  return (
    <ManageKeyApiBodyBorder_entry>
      <ManageKeyApiField>
        {isEncrypted ? (
          <SubHeaderText_H2>{value}</SubHeaderText_H2>
        ) : (
          <SubHeaderText_H2>{encryptedValue}</SubHeaderText_H2>
        )}
      </ManageKeyApiField>

      <ManageKeyApiField_center>
        <SubHeaderText_H2>{name}</SubHeaderText_H2>
      </ManageKeyApiField_center>

      <ManageKeyApiField_center>
        <SubHeaderText_H2>Created</SubHeaderText_H2>
      </ManageKeyApiField_center>

      <ManageKeyApiField_end>
        <ManageKeyApiDecrypt>
        {isEncrypted ? (
            <MainButtonStyle onClick={Decrypt} className="purple">
              Decrypt
            </MainButtonStyle>
          ) : (
            <MainButtonStyle className="purple">
              <Oval
                height="100%"
                width="20"
                color="#757575"
                secondaryColor="#BDBDBD"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />{" "}
            </MainButtonStyle>
          )}
         
        </ManageKeyApiDecrypt>
        <ManageKeyApiActionBtn onClick={edit}>
          <FiEdit size={"100%"} />
        </ManageKeyApiActionBtn>
        <ManageKeyApiActionBtn onClick={Close}>
          <MdFolderDelete size={"100%"} />
        </ManageKeyApiActionBtn>
      </ManageKeyApiField_end>
    </ManageKeyApiBodyBorder_entry>
  );
};

ManageKeyComponent.prototype = {
  value: PropTypes.string,
  name: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDecrypt: PropTypes.func,
};

export default ManageKeyComponent;
