import styled from "styled-components";


export const EncryptionDetailMainDiv=styled.div`
width:83%;
height:100%;
display:flex;
justify-content:center;
align-items:center;
box-sizing: border-box;
`;


export const EncryptionDetailInnerDiv=styled.div`
width:45%;
display:flex;
flex-direction:column;
align-items:center;
box-sizing: border-box;
`;

export const EncryptionDetailHeader=styled.div`
font-size:20px;
font-weight:bold;
margin-bottom:15px;
`;

export const EncryptionDetailSubHeader=styled.div`
font-size:15px;
font-weight:500;
margin-bottom:30px;
color:#757575;
`;

export const EncryptionDetailSDKbutton=styled.button`
width:70%;
border-radius:30px;
padding:13px 0px;
text-align:center;
display:flex;
justify-content:center;
align-items:center;
background:#424242;
border:2px solid black;
color:white;
outline:none;
user-select:none;
transition:background 0.3s ease;
margin-bottom:10px;

:hover{
    background:#757575;
}
`;

export const SDKbuttonImg=styled.img`
width:30px;
border-radius:8px;
margin-right:10px;
margin-bottom:0px;
background:white;
`;

export const SDKbuttonText=styled.div`
font-size:16px;
font-weight:bold;
color:white;
`;