import React, { useState } from 'react';
import '../styles/accountPaymentInfo.css';

function Modal(props) {
  const [accountNumber, setAccountNumber] = useState('5403951829');
  const [accountName, setAccountName] = useState('BRIDGECARD LTD/SixHQ Inc');
  const [bankName, setBankName] = useState('Providus bank')

  

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="close-button" onClick={(e)=>props.setComingSoon(false)}>
          &times;
        </button>
        <h2 className="modal-header">
          Pay to this account to activate premium on your account
        </h2>
        <div className="modal-content">
          <div className="account-info">
            <label>Account Number:</label>
            <input
              type="text"
              defaultValue={accountNumber}
              readOnly
            />
            <label>Account Name:</label>
            <input
              type="text"
              defaultValue={accountName}
              readOnly
            />
          </div>
          <div className="plan-info">
            <p>Bank name - Providus bank</p>
            <p>Basic plan - $3 (N3200)</p>
            <p>Premium plan - $10 (N13,000)</p>
            <p>Business plan - $100 (N130,000)</p>
          </div>
          <div className="payment-instructions">
            <p>
              After you've made payment, you can send your receipt to{' '}
              <a href="mailto:ope@trysixth.com">ope@trysixth.com</a>, and we
              will verify your payment and activate your plan instantly. Thank
              you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
