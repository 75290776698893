import { Switch } from "@mui/material";
import styled from "styled-components";


export const SdkPageMainDiv=styled.div`
width:83%;
height:auto;
display:flex;
flex-direction:column;
align-item:center;
justify-content:flex-start;

@media (max-width: 550px) {
 width:100%;
}

`;

export const SdkPageContent=styled.section`
width:100%;
height:auto;

padding:10px 3.5%;
box-sizing:border-box;
overflow-x:hidden;
overflow-y:scroll;
display:flex;
justify-content:center;
flex-direction:column;
gap:40px;




`;

export const SdkPageCopyApiKeyDiv=styled.div`
width:100%;
height:50px;
position: relative;
border-radius:13px;
margin-bottom:7px;
box-sizing: border-box;
z-index:0;
`;

export const ApiKeyInput=styled.input`
width:100%;
height:100%;
padding:20px ;
border-radius:13px;
box-sizing: border-box;
color: #8C30F5;
border: 2px solid #F1E4FF;
outline:none;



font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;



@media (max-width:1024px) {
 
font-size: 14px;
   padding:10px
}


@media (max-width:450px) {
 
  font-size: 10px;
     padding:6px
  }
`;



export const ShowIcon=styled.div`
font-size:20px;
position:absolute;
right:55px;
top:10px;
color:#424242;

:hover{
    cursor:pointer;
}

@media (max-width: 1024px) {
  right:25px;
 
  }

  @media (max-width: 360px) {
    top:25px;
    width:15px;
    right:50px;
   
    }
`;

export const CopyIcon=styled.div`
font-size:20px;
position:absolute;
right:0px;
top:10px;
color:#26C6DA;


:hover{
    cursor:pointer;
}
  @media (max-width: 360px) {
    width:15px;
   top:25px;
   right:20px;
    }
`;

export const BoxWrap = styled.div`
    background: transparent;
    border-radius:10px;
    display:flex;
    justify-content:flex-start;
    align-items:left;
    flex-direction:column;
    gap:10px;

`

export const IntegrateWrap = styled.div`
    background: #FFFFFF;
    border-radius:10px;
    display:flex;
    justify-content:flex-start;
    align-items:left;
    flex-direction:column;
    gap:34px;
    padding-left:30px;
    margin-top:64px;
    margin-bottom:64px;
    box-shadow: 2px 4px 4px 0px #00000026;
`

export const IntegrateWrapDiv = styled.div`
    width: 100%;
    height: 100%;
`
export const Spacer = styled.div`

    width: 100%;
    display:flex;
    justify-content: space-between;
`

export const BoxWrap_content = styled.div`
    background: transparent;
    padding:30px;
    margin:1rem 0;
    border-radius:10px;
    max-width:700px;
    align-items:center;

`


export const BoxWrap_contentDiv = styled.div`
    background: transparent;
    width:100%;
    height:auto;
    padding:5px  0px;
    box-sizing:border-box;
    display:flex;
    align-items:left;
    justify-content:space-between;
    display:flex;
    gap:20px;
    flex-direction:row;

    @media (max-width: 900px) {
        
        flex-direction:column;
        align-items:center;
        justify-content:center;
      }


`


export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 100,
    height: 90,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: 'grey',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme === true ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme === true ? 'white' : 'white',
      width: 32,
      height: 35,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.80')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme === true ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
      height:20
  
    },
  }));




export const BoxWrap_contentDiv2 = styled.div`
background: transparent;
width:100%;
height:auto;
padding:5px  0px;
box-sizing:border-box;
display:flex;
align-items:left;
justify-content:space-between;
display:flex;
gap:20px;
flex-direction:row;

@media (max-width: 1350px) {
    justify-content:center;
  }
`

export const SdkPageApiKeyDesDiv=styled.p`
width:100%;
font-size: 18px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0em;
text-align: left;

margin-bottom:35px;
margin-top:0px;
color: #757575;

`;

export const SdkPageSdkDocsDiv=styled.div`
font-size:35px;
font-weight:700;
margin-bottom:20px;
`;

export const SdkDocsDivItemDiv=styled.div`
width:70%;
display:flex;
align-items:center;
gap: 40px;
margin-bottom: 20px;


  @media (max-width: 540px) {
    flex-direction:column;
   gap:0px;

   width:100%;
   overflow-x:hidden;
   

  
    }
`;

export const SdkDocsDivItem=styled.div`
display:flex;
align-items:center;
height:40px;
width:200px;

:hover{
    cursor:pointer;
}
`;

export const SdkDocsDivItemImg=styled.img`
width: 30px;
height:30px;
margin-right:12px;
`;


export const SdkIconItemImg=styled.img`
width: 30px;
height:30px;


@media (max-width:1024px) {
  width:20px;
   
  }
`;

export const SdkDocsDivItemText=styled.div`
font-size:16px;
font-weight:bold;
white-space: nowrap;
`;


export const DeveloperError = styled.div`
display:flex;
justify-content:end;
`

