import {
  IntegrationButton,
  IntegrationButtonImg,
  IntegrationButtonText,
  IntegrationContent,
  IntegrationHeader,
  IntegrationInnerDiv,
  IntegrationMainDiv,
  IntegrationSubHeader,
} from "../../styles/dashBoardStyles/integrations.styles";
import slackLogo from "../../assets/slack-logo.png";
import Header from "../header";

const Integration = () => {
  const slackUrl =
    "https://slack.com/oauth/v2/authorize?client_id=5567553163009.5578644592176&scope=channels:history,channels:read,chat:write,groups:history,groups:read,im:history,im:read,mpim:history,mpim:read,groups:write&user_scope=";

  return (
    <IntegrationMainDiv>
      <Header />
      <IntegrationContent>
        <IntegrationInnerDiv>
          <IntegrationHeader>Integrate Slack Bot</IntegrationHeader>
          <IntegrationSubHeader>
            Integrate our slack bot to get security alerts from services you've
            activated and are currently used.
            <br />
            <br />
            You can alerts and logs on usage of various services like the rate
            limit feature on the sdk.
            <br />
            <br />
            To integrate the slack bot,copy your apiKey from the overview page
            as it will be needed on the authorization page,after that click the
            button below and add the bot to your slack workspace.
          </IntegrationSubHeader>
          <IntegrationButton onClick={(e) => window.location.assign(slackUrl)}>
            <IntegrationButtonImg src={slackLogo} />
            <IntegrationButtonText>Integrate slack bot </IntegrationButtonText>
          </IntegrationButton>
        </IntegrationInnerDiv>
      </IntegrationContent>
    </IntegrationMainDiv>
  );
};
export default Integration;
