
export function convertUnixTimestampToMonthAndYear(unixTimestamp) {
    console.log(unixTimestamp,"yogo")
    // Convert Unix timestamp to milliseconds
    const timestampInMilliseconds = unixTimestamp * 1000;
  
    // Create a Date object from the timestamp
    const date = new Date(timestampInMilliseconds);
  
    // Array of month names
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    // Get month and year
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
  
    // Return formatted string
    return `${month}, ${year}`;
  }
  