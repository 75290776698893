import styled from "styled-components";

export const LoginHeader_topDiv = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const LoginHeadertesxt = styled.h3`
  font-size: 1.563rem;
  text-align: center;
  font-weight: 800;
  margin-bottom: 25px;
`;

export const LoginHeader_topDiv_header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  margin-top: 20px;
`;

export const LoginHeader_topDiv_text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const LoginHeader_topDiv_headertext = styled.div`
  width: 100%;

  display: flex;
  text-align: center;
`;

export const LoginHeader_topDiv_goggleBtn = styled.div`
  width: 80%;
  height: 50px;

  @media (max-width: 850px) {
    width: 50%;
    height: 70px;
  }

  @media (max-width: 550px) {
    width: 60%;
    height: 50px;
  }
`;

export const Login_BottomDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  margin: 20px;

  @media (max-width: 550px) {
    margin: 0px;
    justify-content: space-between;
  }
`;

export const LoginInput_div = styled.div`
  width: 100%;
  padding: 10px;
  align-items: left;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (max-width: 550px) {
    width: 85%;
  }
`;

export const LoginInputButton = styled.button`
  width: 60%;
  border-radius: 5px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1e4ff;
  border: none;
  color: #8c30f5;
  margin-bottom: 48px;
  outline: none;
  user-select: none;
  transition: background 0.3s ease;

  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;

  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;

  :hover {
    color: white;
  }

  &:before {
    content: "";
    position: absolute;
    border-radius: 5px;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f1e4ff;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  :hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    background: #8c30f5;
  }

  @media (max-width: 728px) {
    width: 90%;
  }
`;


export const LoginInputSectionDiv = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const SignUp_div = styled.span`
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding-top: 10px;
`;

export const SignUp_div_text = styled.span`
  width: 100%;
`;

export const SignUp_div_link = styled.span`
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  color: rgba(140, 48, 245, 1);
  cursor: pointer;
`;

export const LoginMainDiv = styled.div`
width: 100%;
min-height: 100vh;
padding: 15px;
box-sizing: border-box;
background: url("/main-bg.svg");
background-size: cover;
background-repeat: no-repeat;
`;

export const LoginInnerDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const LoginInnerDivBorder = styled.div`
  width: 50%;
  background:var(--border-bg);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 16px;
  border: 5px solid rgba(30, 30, 30, 0.05);

  min-width: 350px;

  @media (max-width: 1050px) {
    width: 80%;
  }

  @media (max-width: 920px) {
    width: 90%;
  }
`;
export const LoginHeader = styled.div`
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 64px;
  font-weight: 700;
`;

export const LoginHeadertext = styled.div`
  font-size: 25px;
  font-weight: 800;
`;

export const LoginHeaderImage = styled.img`
  width: 100px;
  border-radius: 8px;
  margin-right: 50px;
  background: transparent;
`;

export const LoginHeaderLogo = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: 50px;
  margin-top: 25px;
  margin-bottom: 25px;
  align-items: center;
  justify-content: flex-start;
`;

export const LoginForm = styled.input`
  width: 60%;
  border: 1px solid  #813DE8;
  padding: 20px;
  outline: none;
  border-radius: 5px;
  margin-bottom: 25px;
  box-sizing: border-box;
  font-size: 15px;
  background: #18191F;
  color: white;
  :focus {
    border: 0.5px solid rgba(129, 61, 232, 1);
    outline: none;
  }

  @media (max-width: 728px) {
    width: 90%;
  }
`;

export const OtherText = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin-bottom: 20px;

  :hover {
    cursor: pointer;
  }
`;

export const OtherTextSpan = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: rgba(140, 48, 245, 1);
  cursor: pointer;
`;

export const LoginHeaderText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-bottom: 25px;
`;

export const GoogleLoginButton = styled.div`
  width: 60%;
  border-radius: 5px;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  border: 1px solid #813DE8;

  margin-bottom: 30px;
  cursor: pointer;

  :hover {
    background: rgba(129, 61, 232, 0.7);
    color: white;
  }
`;

export const Loginwithemailoption = styled.div`
  width: 60%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 30px;
`;

export const Loginwithemailoptionbar = styled.div`
  width: 100%;
  height: 1px;
  flex-direction: row;
  background: rgba(0, 1, 3, 0.6);
`;

export const ForgetpasswordText = styled.div`
color:black:
text-align: left;
justify-content: flex-start;
padding-left:1rem;
color: #ffffff;
cursor:pointer;
align-items:center;

`;

export const GoogleIcon = styled.img`
  width: 20px;
  height: 100%;
`;