import styled from "styled-components";

export const IntegrationMainDiv=styled.div`
width:83%;
height:100%;

  @media (max-width: 550px) {
    width: 100%;
  }

`;

export const IntegrationContent=styled.section`
height:88%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
box-sizing: border-box;

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const IntegrationInnerDiv=styled.div`
width:50%;
display:flex;
flex-direction:column;
align-items:center;

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const IntegrationHeader=styled.div`
font-size:35px;
font-weight:700;
text-align:center;
margin-bottom:14px;
`;

export const IntegrationSubHeader=styled.div`
font-size:17px;
font-weight:500;
text-align:center;
color:#424242;
margin-bottom:25px;
`;

export const IntegrationButton=styled.div`
width:70%;
padding:10px 20px;
border-radius:8px;
background:white;
border:1px solid #616161;
outline:none;
border-radius:5px;
display:flex;
justify-content:center;
align-items:center;
cursor:pointer;

:hover{
    background:#F5F5F5;
}
`;

export const IntegrationButtonImg=styled.img`
width:40px;
margin-right:10px;
border-radius:5px;
`;

export const IntegrationButtonText=styled.div`
font-size:15px;
font-weight:600;
`;