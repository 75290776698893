import { useState } from "react";
import { AlertStyles, FormDivButton, FormDivForm, FormDivHeader, SlacKRedirectCompMainDiv, SlackRedirectFormDiv } from "../styles/slackRedirectComp.styles";
import api from '../API/baseURL';
import { useParams } from "react-router-dom";
import GlobalLoader from "./globalLoader";
import { Alert } from "@mui/material";



const SlackRedirectComp=()=>{

    const [apiKey,setApiKey]=useState("");
    const [loading,setLoading]=useState(false);
    const searchParams = new URLSearchParams(document.location.search)
    const sendSlackAuth=async()=>{
        setLoading(true);
        try{
            
            const response=await api.get(`/slack/redirect?code=${searchParams.get("code")}&user_id=${apiKey}`);
            setLoading(false);
            setAlertForm({"message":"slack bot set up successfull","severity":"success","show":true})
        }catch(err){
            setLoading(false);
            setAlertForm({"message":"slack bot set up failed","severity":"error","show":true})
        }
    };
    const [alertForm,setAlertForm]=useState({
        "message":"","severity":"success","show":false
    });

    return(
        <SlacKRedirectCompMainDiv>
            {alertForm.show?<Alert sx={AlertStyles} onClose={()=>setAlertForm((prev)=>({...prev,show:false}))} severity={alertForm.severity} >{alertForm.message}</Alert>:<></>}
            {loading?<GlobalLoader/>:<></>}
            <SlackRedirectFormDiv>
                <FormDivHeader>Paste your copied apiKey</FormDivHeader>
                <FormDivForm value={apiKey} onChange={(e)=>setApiKey(e.target.value)}  />
                <FormDivButton onClick={sendSlackAuth} >submit</FormDivButton>
            </SlackRedirectFormDiv>
        </SlacKRedirectCompMainDiv>
    );
};
export default SlackRedirectComp;