import { CodeBodyLineDiv, CodeInnerDivHeader, CodeInnerDivHeaderItem, CodeyBodyLineText, DocumentationBodyCodeBody, DocumentationBodyCodeInnerDiv, DocumentationBodyCodeOuterDiv, DocumentationBodyLinkText, DocumentationBodyMainDiv, DocumentationBodyNormalText, DocumentationBodyNormalTextH, DocumentationBodyPrimaryHeader, DocumentationBodySecHeader, DocumentationBodyUlDiv } from "../../../styles/documentationStyles/documentationBody.styles";



const DocEncryption=()=>{

    const codeData={
        "javascript":{
            "1":[{"val":"const ","color":"keywords"},{"val":"requestUrl = ","color":"normal"},{"val":"https://yourBackendApiUrl/route","color":"strings"}],
        "2":[{"val":"const ","color":"keywords"},{"val":"data = ","color":"normal"},{"val":"{'id':'user_id','name':'sam'}","color":"normal"}],
        "3":[{"val":"const ","color":"keywords"},
        {"val":"encryptedRequest=","color":"normal"},{"val":"await","color":"keywords"},{"val":"sixth.encryptRequest(requestUrl,data);","color":"normal"}]
        }
    };

    const codeLines=[1,2,3];

    const colors={
        "keywords":"#039BE5",
        "normal":"#616161",
        "strings":"#4CAF50",
        "method":"#EEFF41"
    }

    return(
        <DocumentationBodyMainDiv>
            <DocumentationBodyPrimaryHeader>Encryption</DocumentationBodyPrimaryHeader>
            <DocumentationBodyNormalText>The encryption feature in SixthSense provides secure communication between your  application and the backend server using RSA encryption.</DocumentationBodyNormalText>
            <DocumentationBodyNormalText>With RSA encryption, you can protect sensitive data such as user credentials, transaction details, and other confidential information from unauthorized access.</DocumentationBodyNormalText>
            <DocumentationBodySecHeader>Client-side Encryption SDK</DocumentationBodySecHeader>
            <DocumentationBodyNormalText>We offer a Client-side Encryption SDK that allows you to encrypt data on the client-side before sending it to the server. This SDK provides an easy-to-use interface to encrypt data using the RSA encryption algorithm.</DocumentationBodyNormalText>
            <DocumentationBodyNormalText>To get started with client-side encryption, follow these steps:</DocumentationBodyNormalText>
            <DocumentationBodyUlDiv>
                <li>Include the Client-side Encryption SDK in your client-side code by just <DocumentationBodyNormalTextH>npm install sixth-client</DocumentationBodyNormalTextH></li>
                <li>Generate a public-private key pair on your dashboard</li>
            </DocumentationBodyUlDiv>
            <DocumentationBodyNormalText>Below are snippets of how to make sure your request are encrypted:</DocumentationBodyNormalText>
            <DocumentationBodyCodeOuterDiv>
                <DocumentationBodyCodeInnerDiv>
                    <CodeInnerDivHeader>
                        <CodeInnerDivHeaderItem show={true}>Javascript</CodeInnerDivHeaderItem>
                    </CodeInnerDivHeader>
                    <DocumentationBodyCodeBody>
                        {
                            codeLines.map((item,index)=>(
                                <CodeBodyLineDiv>
                                    {
                                    codeData["javascript"][item].map((item)=>(
                                        <CodeyBodyLineText color={colors[item.color]} >{item.val}</CodeyBodyLineText>
                                    ))
                                    }
                                </CodeBodyLineDiv>
                            ))
                        }
                    </DocumentationBodyCodeBody>
                </DocumentationBodyCodeInnerDiv>
            </DocumentationBodyCodeOuterDiv>
            <DocumentationBodySecHeader>Server-side Encryption SDK</DocumentationBodySecHeader>
            <DocumentationBodyNormalText>In addition to the client-side encryption, we also offer a Server-side Encryption SDK for decrypting the data received from the client. This SDK provides functions to decrypt the encrypted data using the corresponding private key.</DocumentationBodyNormalText>
            <DocumentationBodyNormalText>To integrate the server-side encryption, you need to:</DocumentationBodyNormalText>
            <DocumentationBodyUlDiv>
                <li>Include the server-side Encryption SDK in your server-side code by just <DocumentationBodyNormalTextH>npm install sixth-node</DocumentationBodyNormalTextH></li>
                <li>Generate a public-private key pair on your dashboard if you haven't done that earlier key pairs valid for both client and server side encryption.</li>
                <li>Include the encryption middleware into your middleware list,the middleware automatically takes care of decryption</li>
            </DocumentationBodyUlDiv>
            <DocumentationBodyNormalText>NB: you will have to include your apiKey into your project,you can learn more in our <DocumentationBodyLinkText href="#" >SDK setup section</DocumentationBodyLinkText></DocumentationBodyNormalText>
        </DocumentationBodyMainDiv>
    );
};
export default DocEncryption;