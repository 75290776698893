import styled from "styled-components";

export const ApiComponentMainDiv = styled.div`
width:100%;
overflow-x:hidden;

display:flex;
justify-content:flex-start;
align-items:center;
flex-direction:column;
border: 2px solid rgb(140, 48, 245);
border-radius:10px;
gap:0px;
padding:1x;
margin:0px;
background: rgb(241, 228, 255);
&.POST
{
    background:   #ecfaf4;
    border: 2px solid   #49cc90;
}


&.GET
{
    background: #eff7ff    ;
     border: 2px solid   #61affe;
}

&.PATCH
{
    background:#edfcf9;
    border: 2px solid   #50e3c2;
}

&.DELETE
{
    background: #feebeb;
    border: 2px solid  #f93e3e;
}
`


export const ApiHeadSection = styled.div`
display:flex;
height:40px;
width:100%;
justify-content:flex-start;
align-items:center;
gap:24px;
padding:8x;
margin:0px;
background: transparent;
overflow:hidden;
`;


export const ApiKeyBarSection = styled.div`

height:40px;
max-width:30%;
align-items:left;
padding:8x;
background: transparent;
text-wrap:nowrap;
`;


export const ApiKeyBodySection = styled.div`


max-width:100%;
align-items:left;
padding:8x;
background: transparent;
`;

export const ApiComponentIcon = styled.div`
width:50px;
height:40px;

background: transparent;
color: grey;

height: 40px;
align-items: center;


padding: 0px;
margin: 0px;
transform: rotate(${(props) => (props.active ? "180deg" : "0deg")});
transition: 0.45s;
:hover {
  color: #8c30f5;
  background: transparent;
}
`
export const ApiComponentHead = styled.div`
width:100%;
display:flex;
height:40px;
border-radius:10px;
overflow:hidden;
justify-content:space-between;
`


export const ApiComponentBody= styled.div`
width:100%;
padding-bottom:10px;
display:flex;
flex-direction:column;
gap:24px;


`

export const ApiComponentLine= styled.div`
width:100%;
height:2px;

&.POST
{
    background: #49cc90;
}


&.GET
{
    background: #61affe;
}

&.PATCH
{
    background:#50e3c2;
}

&.DELETE
{
    background: #f93e3e;
}
`


export const ApiComponentSection= styled.div`
width:100%;
padding-left:24px;
display:flex;
gap:34px;
overflow-x:hidden;
text-wrap:wrap;
@media (max-width: 550px) {
  padding-left:4px;
   }
&.POST
{
    background: #49cc90;
}


&.GET
{
    background: #61affe;
}

&.PATCH
{
    background:#50e3c2;
}

&.DELETE
{
    background: #f93e3e;
}

&.plain
{
    height:30px;
    padding-left:0px;
    background:white;

}
`