import { useNavigate } from "react-router-dom";


const useTokenExpired=()=>{
    const navigate=useNavigate();

    const checkTokenExpiration=(error)=>{
        if (error.response && error.response.status === 400) {
            
              // Handle the 400 error
              if(error.response.data.detail=="Expired access token"||error.response.data.detail=="Invalid access token"){
                  localStorage.clear();
                  navigate("/");
              }
              // You can perform custom actions or show error messages here
            }
    };

    return [checkTokenExpiration];

};
export default useTokenExpired;