

export const paymentStatusEnum={
    PENDING:"PENDING",
    FAILED:"FAILED",
    PAID:"PAID",
    UNAVAILABLE:"UNAVAILABLE"
}

export const userTypeEnum={
    DEVELOPER:"developer",
    COMPANY:"company"
}

export const paymentPlansObject={
    "price_1O0X9xHt1vJ10YlXxyhI7rU7":{"planName":"COMPANY_PLAN","price":"$100"},
    "price_1O0X7pHt1vJ10YlXaFNFrD29":{"planName":"DEVELOPER_PLAN","price":"$10"},
    "PLN_rvxichjh9crifxg":{"planName":"NGN_DEVELOPER_PLAN","price":"$5"},
    "PLN_1sj8lk4pxcbfq5q":{"planName":"COMPANY_PLAN","price":"$100"},
    "PLN_jdj5k6tixnj1ohu":{"planName":"DEVELOPER_PLAN","price":"$10"}

}



export const africanCountries = {
    "Africa": {
      "Algeria": "DZ",
      "Angola": "AO",
      "Benin": "BJ",
      "Botswana": "BW",
      "Burkina Faso": "BF",
      "Burundi": "BI",
      "Cameroon": "CM",
      "Cape Verde": "CV",
      "Chad": "TD",
      "Comoros": "KM",
      "Republic of the Congo": "CG",
      "DR Congo": "CD",
      "Djibouti": "DJ",
      "Egypt": "EG",
      "Equatorial Guinea": "GQ",
      "Eritrea": "ER",
      "Eswatini": "SZ",
      "Ethiopia": "ET",
      "Gabon": "GA",
      "Gambia": "GM",
      "Ghana": "GH",
      "Guinea": "GN",
      "Guinea-Bissau": "GW",
      "Ivory Coast": "CI",
      "Kenya": "KE",
      "Lesotho": "LS",
      "Liberia": "LR",
      "Libya": "LY",
      "Madagascar": "MG",
      "Malawi": "MW",
      "Mali": "ML",
      "Mauritania": "MR",
      "Mauritius": "MU",
      "Morocco": "MA",
      "Mozambique": "MZ",
      "Namibia": "NA",
      "Niger": "NE",
      "Nigeria": "NG",
      "Rwanda": "RW",
      "Sao Tome and Principe": "ST",
      "Senegal": "SN",
      "Seychelles": "SC",
      "Sierra Leone": "SL",
      "Somalia": "SO",
      "South Africa": "ZA",
      "South Sudan": "SS",
      "Sudan": "SD",
      "Tanzania": "TZ",
      "Togo": "TG",
      "Tunisia": "TN",
      "Uganda": "UG",
      "Zambia": "ZM",
      "Zimbabwe": "ZW"
    }
  };