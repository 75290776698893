import { useNavigate } from "react-router-dom";
import {
  NotFoundContentDiv,
  NotFoundMainDiv,
  NotFoundOtherText,
  NotFoundReturn,
  NotFoundText,
} from "../styles/notFoundStyle,";
import {
  RegularText,
  SubHeaderText,
} from "../styles/reusable/TextStyles";
import { MainButtonStyle } from "../styles/reusable/inputTypes";
import SixthHeaderComponent from "./ReuseableComponent/sixthheaderComponent";
import { useEffect } from "react";

const NotFoundComponent = () => {
  const navigate = useNavigate();
  const returnToMainPage = () => {
    if (
      localStorage.getItem("token") == "" ||
      localStorage.getItem("token") == null
    ) {
      navigate("/login");
      return;
    }
    navigate("/");
  };
  
  useEffect(() => {
    async function Report() {
      
    }
    Report();
  }, []);
  return (
    <NotFoundMainDiv>
      <SixthHeaderComponent />
      <NotFoundContentDiv>

       
        <NotFoundText>
          <SubHeaderText> Whoops, no matches </SubHeaderText>
        </NotFoundText>
        <NotFoundOtherText>
          <RegularText>
            we couldn't find the page you were looking for.{" "}
          </RegularText>
        </NotFoundOtherText>

        <NotFoundReturn>
          <MainButtonStyle onClick={(e) => returnToMainPage()}>
            {localStorage.getItem("token") == null ? (
              <>Return</>
            ) : (
              <> Back to home</>
            )}
          </MainButtonStyle>
        </NotFoundReturn>
      </NotFoundContentDiv>
    </NotFoundMainDiv>
  );
};

export default NotFoundComponent;
