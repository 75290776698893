import {
  EncryptionDetailHeader,
  EncryptionDetailInnerDiv,
  EncryptionDetailMainDiv,
  EncryptionDetailSDKbutton,
  EncryptionDetailSubHeader,
  SDKbuttonImg,
  SDKbuttonText,
} from "../../../styles/dashBoardStyles/encryption/encryptionDetail.styles";
import waves from "../../../assets/waves.png";
import useTokenExpired from "../../../apiLayer/expirationEvent";
import api from "../../../API/baseURL";
import { AlertStyles } from "../../../styles/registerComponent.styles";
import { Alert } from "@mui/material";
import { useState } from "react";
import { StartInnerButton } from "../../../styles/dashBoardStyles/encryption/encryptionStart.styles";

const EncryptionDetail = () => {

  const [checkTokenExpiration]=useTokenExpired();

  const [disable,setDisable]=useState(false);

  const [alertForm, setAlertForm] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const showDocumentation = () => {
    window.open("https://docs.trysixth.com/encryption", "_blank");
  };

  const disableEncryption=async()=>{
    setDisable(true);
    try{
      const response= await api.patch("/encryption-service/update_encryption_service",{"status":false},{
        headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
        
    });
    setAlertForm({
      message: "You disabled encryption successfully",
      severity: "success",
      show: true,
    });
    }catch(err){
      checkTokenExpiration(err);
      setDisable(false);
      setAlertForm({
        message: "could not enable encryption,try again later",
        severity: "error",
        show: true,
      });
    }
  };

  return (
    <EncryptionDetailMainDiv>
      {alertForm.show ? (
        <Alert
          sx={AlertStyles}
          onClose={() => setAlertForm((prev) => ({ ...prev, show: false }))}
          severity={alertForm.severity}
        >
          {alertForm.message}
        </Alert>
      ) : (
        <></>
      )}
      <EncryptionDetailInnerDiv>
        <EncryptionDetailHeader>
          Encryption has been added to your apps successfully!
        </EncryptionDetailHeader>
        <EncryptionDetailSubHeader>
          You can now have your requests and responses end to end encrypted.
          Click below to add our SDK into your apps if you haven't already.
        </EncryptionDetailSubHeader>
        <EncryptionDetailSDKbutton onClick={(e)=>showDocumentation()} >
          <SDKbuttonImg src={waves} />
          <SDKbuttonText>SDK</SDKbuttonText>
        </EncryptionDetailSDKbutton>
        <StartInnerButton disabled={disable} onClick={(e) => disableEncryption()}>
              Disable Encryption
        </StartInnerButton>
      </EncryptionDetailInnerDiv>
    </EncryptionDetailMainDiv>
  );
};
export default EncryptionDetail;
