import styled from "styled-components";

export const Header_Text = styled.h1`
  user-select: none;
  font-size: 25px;
  font-weight: 800;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  height: 100%;

  @media (max-width: 788px) {
    font-size: 25px;
    line-height: 10px;
  }
`;

export const SubHeaderText = styled.h3`
  text-align: center;
  user-select: none;

  margin-bottom: 20px;
  margin-top: 20px;

  font-weight: 600;
  line-height: 32.78px;

  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  width: 100%;

  @media (max-width: 788px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

export const SubHeaderText_H3 = styled.h3`
  user-select: none;

  margin-bottom: 20px;
  margin-top: 20px;

  font-weight: 600;
  line-height: 32.78px;

  font-size: 34px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;

  @media (max-width: 788px) {
    font-size: 25px;
    margin-bottom: 10px;
  }
`;


export const SubHeaderText_H2 = styled.h3`
  user-select: none;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 550;
  line-height: 38px;
  letter-spacing: 0em;
  


  &.logLabel {
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &.noMarginBottom {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  &.noSpace {
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 24px;
  }
  &.pointer {
    cursor: pointer;
  }
  &.des{
  
 
   
  }
  @media (max-width: 788px) {
    font-size: 11px;
    margin-bottom: 10px;
    line-height:18px;
  }
`;

export const SubText = styled.p`
  font-size: 16px;
  user-select: none;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #18191f;
  margin-top: 1.5rem;
  letter-spacing: 0em;

  &.link {
    color: #8c30f5;
    cursor: pointer;
  }

  @media (max-width: 788px) {
    margin-top: 0.5rem;
    font-size: 14px;
  }
`;

export const RegularText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  user-select: none;

  @media (max-width: 728px) {
    font-size: 12px;
  }
`;

export const UserText = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  color: ${(props) => (props.problem = true ? "#000103CC " : "#00010399")};

  @media (max-width: 520px) {
  }
`;

export const SubDangerText = styled.p`
  font-size: 16px;
  user-select:none;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #CC0000;
  margin-top: 1.5rem;
  letter-spacing: 0em;


  &.link
  {
    color:#8C30F5;
    cursor:pointer;
  }
  
  @media (max-width: 788px) {
    margin-top: 0.5rem;
    font-size: 14px;
    
    
  }
`