import styled from "styled-components";


export const PickPricingMainDiv=styled.div`
width:35%;
height:80%;
border-radius:8px;
background:white;
padding:30px 30px;
box-sizing: border-box;
box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);

@media (max-width: 850px) {

  width:80%;
  height:80%;
    
}
`;

export const PickPricingHeaderDiv=styled.div`
width:100%;
display:flex;
flex-direction:row-reverse;
margin-bottom:15px;
`;

export const PricingHeaderTitleDiv=styled.div`
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-right:45%;
`;

export const PricingHeaderIcon=styled.div`
font-size:30px;
font-weight:800;
`;

export const PickPriceInnerDiv=styled.div`
width:100%;
height:93%;
display:flex;
flex-direction:column;

align-items:center;

overflow-y: auto;
overflow-x: hidden;
box-sizing: border-box;

::-webkit-scrollbar {
    width: 5px;
    border-radius: 1rem;
  }
  
  
::-webkit-scrollbar-track {
    background:#cbced1;
  }
  
  
::-webkit-scrollbar-thumb {
    background: grey;
    cursor:pointer;
  }
  
  
::-webkit-scrollbar-thumb:hover {
    background: grey;
    width: 20px;
    cursor:pointer;
  }
`;

export const PickPricingItemDiv=styled.div`
width:80%;
border:1px solid #8C30F5;
border-radius:8px;
padding:10px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin-bottom:10px;

:hover{
    cursor:pointer;
}
`;

export const PricingItemIcon=styled.img`
width:30px;
margin-right:7px;
`;

export const PricingItemHeader=styled.div`
font-size:16px;
font-weight:500;
margin-bottom:20px;
display:flex;
align-items:center;
`;

export const PricingItemPrice=styled.div`
margin-bottom:20px;
display:flex;
align-items:bottom;
`;

export const PriceMainText=styled.div`
font-weight:50px;
font-weight:800;
`;

export const PriceMainSlash=styled.div`
font-weight:13px;
font-weight:800;
`;

export const PriceMainPeriod=styled.div`
font-weight:13px;
font-weight:500;
color:#BDBDBD;
`;

export const PricingItemFeaturesList=styled.div`
width:100%;
display:flex;
flex-direction:column;
box-sizing: border-box;
`;

export const FeaturesListItemDiv=styled.div`
display:flex;
`;

export const FeaturesListItemDivIcon=styled.div`
color:#8C30F5;
font-size:17px;
margin-right:7px;
`;

export const FeaturesListItemDivText=styled.div`
font-size:14px;
`;