import { useEffect, useState } from "react";
import { LoginForm } from "../styles/loginComponent.styles";
import api from "../API/baseURL";
import { AlertStyles } from "../styles/registerComponent.styles";
import {
  RegularText,
  SubHeaderText_H3,
  SubHeaderText_H2,
} from "../styles/reusable/TextStyles";
import {
  InputStyle_White,
  MainButtonStyle,
} from "../styles/reusable/inputTypes";
import {
  TwoFactorComponentInnerBorder,
  TwoFactorComponentInnerDiv,
  TwoFactorComponentMainDiv,
  TwoFactorDiv,
  TwoFactorForm,
  TwoFactorInnerBorder,
  TwoFactorInput,
  TwoFactorInputButton,
  TwoFactorInvalidCodDiv,
} from "../styles/twoFactorComponentStyles";
import { Alert } from "@mui/material";
import { Oval } from "react-loader-spinner";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FiAlertCircle } from "react-icons/fi";

const TwoFactorComponent = ({
  userKey = "",
  userEmail = "",
  closePage = () => {},
}) => {
  const [codeSent, hasSentCode] = useState(true);
  const [btnActive, setBtnActive] = useState(false);
  const navigate = useNavigate();
  
  const [alertForm, setAlertForm] = useState({
    message: "",
    severity: "success",
    show: false,
  });
  
const ClosePage = () => {
    closePage(false);
  };
//OTP CODE 
 
  const [activeInput, setActiveInput] = React.useState(0);
  const inputs = useRef(["","","","","","","",]);
  const inputRefs = useRef([]);
  const getOTPValue = () => ( inputs.current);
  
  const handleOTPChange = (otp) => {
   //  const otpValue = otp.join('');
   // inputs.current[activeInput]= otpValue 
   
    console.log(inputs.current)
    
    
    
   
  };

  


  
  const handleChange = (event) => {
    const { value } = event.target;

    if (isInputValueValid(value)) {
      changeCodeAtFocus(value);
      focusInput(activeInput + 1);
    }
  };
  const isInputValueValid = (value) => {
    const isTypeValid = !isNaN(Number(value));
    return isTypeValid && value.trim().length === 1;
  };
  
  const changeCodeAtFocus = (value) => {
    const otp = getOTPValue();
    otp[activeInput] = value[0];
    handleOTPChange(otp);
  };
  
  const focusInput = (index) => {
    const activeInput = Math.max(Math.min(7 - 1, index), 0);

    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput]?.focus();
      inputRefs.current[activeInput]?.select();
      setActiveInput(activeInput);
     
    }
  };
  const handleFocus = (event,index)  => {
    setActiveInput(index);
   
  };
  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };
 
  const handleInputChange = (event) => {
    const { nativeEvent } = event;
    const value = event.target.value;

    if (!isInputValueValid(value)) {
      // Pasting from the native autofill suggestion on a mobile device can pass
      // the pasted string as one long input to one of the cells. This ensures
      // that we handle the full input and not just the first character.
      if (value.length === 7) {
        const hasInvalidInput = value.split('').some((cellInput) => !isInputValueValid(cellInput));
        if (!hasInvalidInput) {
          handleOTPChange(value.split(''));
          focusInput(7 - 1);
        }
      }

      // @ts-expect-error - This was added previously to handle and edge case
      // for dealing with keyCode "229 Unidentified" on Android. Check if this is
      // still needed.
      if (nativeEvent.data === null && nativeEvent.inputType === 'deleteContentBackward') {
        event.preventDefault();
        changeCodeAtFocus('');
        focusInput(activeInput - 1);
      }

      // Clear the input if it's not valid value because firefox allows
      // pasting non-numeric characters in a number type input
      event.target.value = '';
    }
  };

  const handleKeyDown = (event) => {
    const otp = getOTPValue();
    if ([event.code, event.key].includes('Backspace')) {
      event.target.value =""
      event.preventDefault();
      changeCodeAtFocus('');

      focusInput(activeInput - 1);
    } else if (event.code === 'Delete') {
      event.target.value =""
      event.preventDefault();
      changeCodeAtFocus('');
    } else if (event.code === 'ArrowLeft') {
      event.preventDefault();
      focusInput(activeInput - 1);
    } else if (event.code === 'ArrowRight') {
      event.preventDefault();
      focusInput(activeInput + 1);
    }
    else if (event.key === otp[activeInput]) {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (
      event.code === 'Spacebar' ||
      event.code === 'Space' ||
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    ) {
      event.preventDefault();
    }
  };
  
   const handlePaste = (event) => {
    event.preventDefault();

    const otp = getOTPValue();
    let nextActiveInput = activeInput;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = event.clipboardData
      .getData('text/plain')
      .slice(0, 7 - activeInput)
      .split('');

    // Prevent pasting if the clipboard data contains non-numeric values for number inputs
    if (pastedData.some((value) => isNaN(Number(value)))) {
      return;
    }

    // Paste data from focused input onwards
    for (let pos = 0; pos < 7; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? '';
        nextActiveInput++;
        const element = inputRefs.current[pos];
        element.value = inputs.current[pos]
      }
    }

    focusInput(nextActiveInput);
    handleOTPChange(otp);


};


  const RequestCode = async () => {
    setAlertForm({
      message: "",
      severity: "success",
      show: false,
    });
    setBtnActive(true);

    if (userKey.length == 0) {
      setAlertForm({
        message: "invalid email input",
        severity: "error",
        show: true,
      });
      setBtnActive(false);
      return;
    }

    if (true) {
      try {
        try {
          console.log(userKey);
          const response = await api.post("/auth/init_login", {
            email: userEmail,
            password: userKey,
          });

          setAlertForm({
            message: "otp code has been sent to you email ",
            severity: "success",
            show: true,
          });
          hasSentCode(true);
        } catch (err) {
          setAlertForm({
            message: err.message,
            severity: "error",
            show: true,
          });
        }
      } catch (err) {
        setAlertForm({
          message: err.message,
          severity: "error",
          show: true,
        });
      }
      setBtnActive(false);
    }
  };

  const VerifyCode = async () => {
    setAlertForm({
      message: "",
      severity: "success",
      show: false,
    });
    if (btnActive == true) return;
    setBtnActive(true);

    if (userEmail.length == 0) {
      setAlertForm({
        message: "invalid email input",
        severity: "error",
        show: true,
      });
      setBtnActive(false);
      return;
    }

    if (true) {
      try {
        try {
          const response = await api.post("/auth/login", {
            email: userEmail,
            password: userKey,
            otp: parseInt(
                inputs.current[0] +
                inputs.current[1] +
                inputs.current[2] +
                inputs.current[3] +
                inputs.current[4] +
                inputs.current[5] +
                inputs.current[6]
            ),
          });
          localStorage.setItem("user_id", response.data.data.apikey);
          localStorage.setItem("token", response.data.data.token.access_token);
          localStorage.setItem("user_type", response.data.data.user_type);
          localStorage.setItem("vs_code_user", response.data.data.vs_code_user);
          localStorage.setItem("user", response.data.data.company_name);
          localStorage.setItem(
            "user_des",
            response.data.data.company_description
          );
          console.log(response.data.data);

          navigate("/dashboard");
        } catch (err) {
          console.log(err);
          if (err.response.data.detail == "Invalid otp code") {
            setAlertForm({
              message: "Invalid otp code",
              severity: "error",
              show: true,
            });
          }
        }
      } catch (err) {
        if (err.message == "Firebase: Error (auth/user-not-found).") {
          setAlertForm({
            message: "email not found",
            severity: "error",
            show: true,
          });
        } else if (err.message == "Firebase: Error (auth/wrong-password).") {
          setAlertForm({
            message: "incorrect password",
            severity: "error",
            show: true,
          });
        } else {
          setAlertForm({
            message: "something went wrong",
            severity: "error",
            show: true,
          });
        }
      }
      setBtnActive(false);
    }
  };

  return (
    <TwoFactorComponentMainDiv>
      {alertForm.show ? (
        <Alert
          sx={AlertStyles}
          onClose={() => setAlertForm((prev) => ({ ...prev, show: false }))}
          severity={alertForm.severity}
        >
          {alertForm.message}
        </Alert>
      ) : (
        <></>
      )}
      <TwoFactorComponentInnerDiv>
        <TwoFactorComponentInnerBorder
          style={{ background: "var(--border-bg)" }}
        >
          <SubHeaderText_H3> Two- factor authentication</SubHeaderText_H3>
          <SubHeaderText_H2 className="noSpace">
            Enter the verification code sent to {userEmail} below
          </SubHeaderText_H2>
          {!codeSent ? (
            <>
              <SubHeaderText_H2 className="noSpace">
                Verify that it is your account
              </SubHeaderText_H2>

              <TwoFactorForm>
                <InputStyle_White
                  autoComplete="off"
                  label="Email"
                  value={userEmail}
                  id="custom-css-outlined-input"
                ></InputStyle_White>
              </TwoFactorForm>

              <TwoFactorInputButton>
                <MainButtonStyle
                  onClick={(e) => {
                    RequestCode();
                  }}
                  className="purple"
                >
                  {btnActive ? (
                    <Oval
                      height="20"
                      width="20"
                      color="#757575"
                      secondaryColor="#BDBDBD"
                      ariaLabel="tail-spin-loading"
                      radius="2"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    <> Send verification code</>
                  )}
                </MainButtonStyle>
              </TwoFactorInputButton>
            </>
          ) : (
            <>
              <TwoFactorDiv>
                {Array.from({ length: 7 }, (_, index) => index).map((index) => (
            
                  <TwoFactorInput
                    type="numeric"
                    inputMode="numeric"
                    placeholder="-"
                    ref={(element) => (inputRefs.current[index] = element)} 
                    onChange={(event) =>(handleChange(event)) }
                    maxLength={1}
                    onFocus={(event) =>( handleFocus(event,index))}
                    onBlur={() =>( handleBlur())}
                    onKeyDown={(event) =>( handleKeyDown(event))}
                    onPaste={(event) =>(handlePaste(event)) }
                    onInput={(event) =>(handleInputChange(event)) }
                    
                  />
                ))}
              </TwoFactorDiv>
              <TwoFactorInvalidCodDiv>
                {alertForm.show && alertForm.message == "Invalid otp code" ? (
                  <SubHeaderText_H2 style={{ color: "red", margin: "0px" }}>
                    <FiAlertCircle /> Incorrect Code
                  </SubHeaderText_H2>
                ) : (
                  <></>
                )}
              </TwoFactorInvalidCodDiv>

              <TwoFactorForm>
                {btnActive ? (
                  <Oval
                    height="20"
                    width="20"
                    color="#757575"
                    secondaryColor="#BDBDBD"
                    ariaLabel="tail-spin-loading"
                    radius="2"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <RegularText
                    onClick={() => RequestCode()}
                    style={{ cursor: "pointer" }}
                  >
                    Resend Code
                  </RegularText>
                )}
              </TwoFactorForm>

              <TwoFactorInputButton>
                <MainButtonStyle
                  onClick={() => ClosePage()}
                  style={{ background: "transparent", padding: "0px" }}
                >
                  Cancel
                </MainButtonStyle>
                <MainButtonStyle
                  onClick={() => VerifyCode()}
                  className="purple"
                >
                  Verify
                </MainButtonStyle>
              </TwoFactorInputButton>
            </>
          )}
        </TwoFactorComponentInnerBorder>
      </TwoFactorComponentInnerDiv>
    </TwoFactorComponentMainDiv>
  );
};

TwoFactorComponent.prototype = {
  userKey: PropTypes.string.isRequired,
};


export default TwoFactorComponent;
