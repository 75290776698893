import {
  ManageKeyMainDiv,
  ManageKeyBodyDiv,
  ManageKeyApiDiv,
  ManageKeyApiHeader,
  ManageKeyApiHeader_left,
  ManageKeyApiHeader_right,
  ManageKeyApiBodyBorder,
  ManageKeyApiBodyBorder_head,
  ManageKeyApiField,
  ManageKeyApiField_end,
  ManageKeyApiField_center,
  ManageKeyApiCreateKeyField,
  ManageKeyApiCreateBtn,
  ManageKeyApiCreateKeyClose,
  ManageKeyApiCreateKeyField_head,
  ManageKeyApiCreateDiv,
} from "../../../styles/dashBoardStyles/MangeKey/managekeyStyles";
import Header from "../../header";
import { DashBoardElementBackground } from "../../../styles/reusable/BorderStyles";
import { useState } from "react";
import {
  RegularText,
  SubHeaderText,
  SubHeaderText_H2,
} from "../../../styles/reusable/TextStyles";
import {
  InputStyle,
  MainButtonStyle,
} from "../../../styles/reusable/inputTypes";
import { FaArrowLeft } from "react-icons/fa";

import { Oval } from "react-loader-spinner";
import api from "../../../API/baseURL";
import { Alert } from "@mui/material";
import { AlertStyles } from "../../../styles/registerComponent.styles";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import AES256 from "aes-everywhere";
import ConfirmDelete from "../../confirmDelete";
import ManageKeyComponent from "./manageKeyComponent";

const ManageKey = () => {
  const [createKey, SetCreateKey] = useState(false);
  const [manageApiKey, setManagekey] = useState([]);
  const [isloading, setLoadingResult] = useState(true);
  const [apikeyname, setapikeyName] = useState("");
  const [value, setValue] = useState("");
  const [btnActive, setBtnActive] = useState(true);
  const [allManagedApiKey, setAllManagedApiKey] = useState([]);
  const [deleteManagedKey, setDeleteManagdeApiKey] = useState({
    id: "-1",
    newDelete: false,
    message: "Delete log",
  });

  const DeleteManagedApiKey = (id) => {
    setDeleteManagdeApiKey({
      newDelete: true,
      id: id,
      message: "Delete api key for " + allManagedApiKey[id].name,
    });
  };
  const [endPointSuccess, setEndPointSuccess] = useState({
    message: "",
    severity: "",
    show: false,
  });

  const CloseCreateKey = () => {
    setValue("");
    setapikeyName("");
    SetEdit({
      id: "-1",
      newEdit: false,
      message: "",
    });

    SetCreateKey(false);
  };

  const [secureLogsLoading, setManageApiKeyLoading] = useState(false);

  const createSecureLog = async () => {
    setBtnActive(false);
    setEndPointSuccess({
      message: "",
      severity: "",
      show: false,
    });

    if (apikeyname == "" || apikeyname == null) {
      setEndPointSuccess({
        message: "please provide a apikey name ",
        severity: "error",
        show: true,
      });
      setBtnActive(true);
      return;
    } else if (value == "" || value == null) {
      setEndPointSuccess({
        message: "please provide a apikey ",
        severity: "error",
        show: true,
      });
      setBtnActive(true);
      return;
    }

    try {
      const response = await api.post(
        "/manage-apikey/create-apikey",
        {
          apikey: localStorage.getItem("user_id"),
          name: apikeyname,
          value: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status != 200) {
        setEndPointSuccess({
          message: "something went wrong try again ",
          severity: "fail",
          show: true,
        });
      } else {
        await getAllManagedApiKey();
        setapikeyName("");
        setValue("");
        setTimeout(() => {
          SetCreateKey(false);
        }, 3000);
        setEndPointSuccess({
          message:
            " Apikey created — check it out in the view apikey  section!",
          severity: "success",
          show: true,
        });
      }
    } catch (e) {
      setEndPointSuccess({
        message: "something went wrong try again ",
        severity: "fail",
        show: true,
      });
    }

    setBtnActive(true);
  };

  const getAllManagedApiKey = async () => {
    try {
      setManageApiKeyLoading(true);
      const response = await api.get(
        `/manage-apikey/get-all-apikey?apikey=${localStorage.getItem(
          "user_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setAllManagedApiKey([]);

        setAllManagedApiKey((prevJsonList) => [
          ...prevJsonList,
          ...response.data["data"],
        ]);

        setManageApiKeyLoading(false);
      } else {
        await getAllManagedApiKey();
      }
    } catch (e) {
      setTimeout(() => {
        getAllManagedApiKey();
      }, 3000);
    }
  };

  useEffect(() => {
    async function loadAllApikeys() {
      const response = await getAllManagedApiKey();
    }

    loadAllApikeys();
  }, []);

  const DeleteMangedApiKeyAsync = async () => {
    console.log(localStorage.getItem("token"));
    try {
      const response = await api.delete("/manage-apikey/delete-apikey", {
        params: {
          apikey: localStorage.getItem("user_id"),
          id: allManagedApiKey[deleteManagedKey.id].id,
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          accept: "application/json",
        },
      });

      if (response.status == 200) {
        setDeleteManagdeApiKey({
          id: "-1",
          newDelete: false,
          message: "Delete Log",
        });

        await getAllManagedApiKey();

        return "success";
      } else {
        return "failed";
      }
    } catch (e) {}
  };

  const [editManageApi, SetEdit] = useState({
    id: "-1",
    newEdit: false,
    message: "Edit Log",
  });

  const EditManageApiKey = async (id) => {
    var realValue = await postOrderDecrypt(allManagedApiKey[id].value);
    setValue(realValue);
    setapikeyName(allManagedApiKey[id].name);
    SetEdit({ id: id, newEdit: true, message: "edit api key" });
    SetCreateKey(true);
  };

  const editSecureLogAsync = async () => {
    setBtnActive(false);
    setEndPointSuccess({
      message: "",
      severity: "",
      show: false,
    });
    if (apikeyname == "" || apikeyname == null) {
      setEndPointSuccess({
        message: "please provide a apikey name ",
        severity: "error",
        show: true,
      });
      setBtnActive(true);
      return;
    } else if (value == "" || value == null) {
      setEndPointSuccess({
        message: "please provide a apikey ",
        severity: "error",
        show: true,
      });
      setBtnActive(true);
      return;
    }
    try {
      const response = await api.patch(
        "/manage-apikey/update-apikey",
        {
          apikey: localStorage.getItem("user_id"),
          name: apikeyname,
          value: value,
          id: allManagedApiKey[editManageApi.id].id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status != 200) {
        setEndPointSuccess({
          message: "something went wrong try again ",
          severity: "fail",
          show: true,
        });
      } else {
        await getAllManagedApiKey();
        setTimeout(() => {
          SetCreateKey(false);
        }, 2000);
        setValue("");
        setapikeyName("");
        SetEdit({
          id: "-1",
          newEdit: false,
          message: "Delete Log",
        });

        setEndPointSuccess({
          message:
            " Api key has been updated  — check it out in the view all Api key section!",
          severity: "success",
          show: true,
        });
      }
    } catch (e) {}

    setBtnActive(true);
  };
  const _vu_msh_ie8 = AES256;

  const postOrderDecrypt = async (_hdh_po_hhhd) => {
    var __v3_n_i_j23_jgf = "";
    var r_nn_jj_rsyuv = _hdh_po_hhhd;

    __v3_n_i_j23_jgf =
      _hdh_po_hhhd.substring(4, 8) +
      _hdh_po_hhhd.substring(12, 16) +
      _hdh_po_hhhd.substring(20, 24) +
      _hdh_po_hhhd.substring(28, 32);
    r_nn_jj_rsyuv =
      _hdh_po_hhhd.substring(0, 4) +
      _hdh_po_hhhd.substring(8, 12) +
      _hdh_po_hhhd.substring(16, 20) +
      _hdh_po_hhhd.substring(24, 28) +
      _hdh_po_hhhd.substring(32);

    return _vu_msh_ie8.decrypt(r_nn_jj_rsyuv, __v3_n_i_j23_jgf);
  };

  return (
    <DashBoardElementBackground>
      <Header></Header>

      <ManageKeyBodyDiv>
        {endPointSuccess.show ? (
          <Alert
            sx={AlertStyles}
            severity={endPointSuccess.severity}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={(e) =>
                  setEndPointSuccess({
                    show: false,
                  })
                }
              >
                Close
              </Button>
            }
          >
            {endPointSuccess.message}
          </Alert>
        ) : (
          <></>
        )}

        {deleteManagedKey.newDelete ? (
          <ConfirmDelete
            action={deleteManagedKey.message}
            setDelete={setDeleteManagdeApiKey}
            deleteAction={DeleteMangedApiKeyAsync}
          />
        ) : (
          <></>
        )}

        {createKey ? (
          <ManageKeyApiDiv>
            <ManageKeyApiCreateDiv>
              <ManageKeyApiCreateKeyField_head>
                <ManageKeyApiCreateKeyClose onClick={() => CloseCreateKey()}>
                  <FaArrowLeft
                    style={{
                      height: "40px",
                      width: "40px",
                    }}
                  />
                </ManageKeyApiCreateKeyClose>
                {editManageApi.newEdit ? (
                  <SubHeaderText>Edit Api key</SubHeaderText>
                ) : (
                  <SubHeaderText>Create new API Key </SubHeaderText>
                )}
              </ManageKeyApiCreateKeyField_head>

              <ManageKeyApiCreateKeyField>
                <SubHeaderText_H2>Value of API key</SubHeaderText_H2>
                <InputStyle
                  label={"api key"}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                ></InputStyle>
              </ManageKeyApiCreateKeyField>

              <ManageKeyApiCreateKeyField>
                <SubHeaderText_H2>Name of API key</SubHeaderText_H2>
                <InputStyle
                  label={"api name"}
                  value={apikeyname}
                  onChange={(e) => setapikeyName(e.target.value)}
                ></InputStyle>
              </ManageKeyApiCreateKeyField>

              <ManageKeyApiCreateBtn>
                {editManageApi.newEdit ? (
                  <>
                    {btnActive ? (
                      <MainButtonStyle onClick={(e) => editSecureLogAsync()}>
                        UpdateKey
                      </MainButtonStyle>
                    ) : (
                      <MainButtonStyle>
                        <Oval
                          height="20"
                          width="20"
                          color="#757575"
                          secondaryColor="#BDBDBD"
                          ariaLabel="tail-spin-loading"
                          radius="2"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />{" "}
                      </MainButtonStyle>
                    )}
                  </>
                ) : (
                  <>
                    {btnActive ? (
                      <MainButtonStyle onClick={(e) => createSecureLog()}>
                        Save
                      </MainButtonStyle>
                    ) : (
                      <MainButtonStyle>
                        <Oval
                          height="20"
                          width="20"
                          color="#757575"
                          secondaryColor="#BDBDBD"
                          ariaLabel="tail-spin-loading"
                          radius="2"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />{" "}
                      </MainButtonStyle>
                    )}
                  </>
                )}
              </ManageKeyApiCreateBtn>
            </ManageKeyApiCreateDiv>
          </ManageKeyApiDiv>
        ) : (
          <ManageKeyApiDiv>
            <ManageKeyApiHeader>
              <ManageKeyApiHeader_left>
                <SubHeaderText_H2>My API keys</SubHeaderText_H2>
                <RegularText>
                  You have {allManagedApiKey.length} API keys.
                </RegularText>
              </ManageKeyApiHeader_left>
              <ManageKeyApiHeader_right>
                <MainButtonStyle
                  onClick={() => SetCreateKey(true)}
                  className="purple"
                >
                  Create api
                </MainButtonStyle>
              </ManageKeyApiHeader_right>
            </ManageKeyApiHeader>
            <ManageKeyApiBodyBorder>
              <ManageKeyApiBodyBorder_head>
                <ManageKeyApiField>
                  <SubHeaderText_H2>API(S)</SubHeaderText_H2>
                </ManageKeyApiField>

                <ManageKeyApiField_center>
                  <SubHeaderText_H2>API key(s) name created</SubHeaderText_H2>
                </ManageKeyApiField_center>

                <ManageKeyApiField_center>
                  <SubHeaderText_H2>Created</SubHeaderText_H2>
                </ManageKeyApiField_center>

                <ManageKeyApiField_end>
                  <SubHeaderText_H2>action</SubHeaderText_H2>
                </ManageKeyApiField_end>
              </ManageKeyApiBodyBorder_head>
              {allManagedApiKey.length > 0 ? (
                <>
                  {allManagedApiKey.map((item, index) => (
                    <>
                      <ManageKeyComponent
                        name={item.name}
                        value={item.value}
                        id={index}
                        onEdit={EditManageApiKey}
                        onDelete={DeleteManagedApiKey}
                        onDecrypt={postOrderDecrypt}
                      />
                    </>
                  ))}
                </>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <RegularText>you have no api key saved</RegularText>
                </div>
              )}
            </ManageKeyApiBodyBorder>
          </ManageKeyApiDiv>
        )}
      </ManageKeyBodyDiv>
    </DashBoardElementBackground>
  );
};
export default ManageKey;
