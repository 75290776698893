import styled from "styled-components";


export const DocumentationBodyMainDiv=styled.div`
width:65%;
align-self: flex-start;
padding:30px;
border-radius:4px;
background:white;
margin-top:35px;
margin-left:50px;
box-sizing: border-box;
box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
`;

export const DocumentationBodyPrimaryHeader=styled.h1`

`;

export const DocumentationBodySecHeader=styled.h2`

`;

export const DocumentationBodyLinkText=styled.a`
text-decoration: none;
font-size:16px;
font-weight:600;
color:#03A9F4;

:hover{
    cursor:pointer;
}
`;

export const DocumentationBodyNormalText=styled.p`
font-size:16px;
font-weight:500;
color:#424242;
line-height:1.8;
box-sizing: border-box;
`;

export const DocumentationBodyNormalTextH=styled.span`
font-size:16px;
padding:10px 15px;
border-radius:5px;
text-align:center;
background:#E0E0E0;
font-weight:500;
box-sizing: border-box;
`;

export const DocumentationBodyUlDiv=styled.ul`
width:100%;
font-size:16px;
font-weight:500;
color:#424242;
box-sizing: border-box;

>li{
    line-height:2;
}
`;

export const DocumentationBodyCodeOuterDiv=styled.div`
width:100%;
padding:25px;
display:flex;
justify-content:center;
align-items:center;
box-sizing: border-box;
`;

export const DocumentationBodyCodeInnerDiv=styled.div`
width:100%;
height: 100%;
border-radius:4px;
border:1px solid #E0E0E0;
box-sizing: border-box;

`;

export const CodeInnerDivHeader=styled.div`
width:100%;
border-radius:4px 4px 0px 0px;
background:white;
padding:0px 15px;
display:flex;
align-items:center;
box-sizing: border-box;
`;

export const CodeInnerDivHeaderItem=styled.div`
padding:15px;
border-bottom:${(props)=>props.show?"2px solid #0277BD":"none"};
font-size:17px;
font-weight:bold;
text-align:center;
color:${(props)=>props.show?"#0277BD":"#424242"};

:hover{
    cursor:pointer;
}
`;

export const DocumentationBodyCodeBody=styled.div`
padding:5px 20px 5px 20px;
background:#E0E0E0;
border-radius:0px 0px 4px 4px;
box-sizing: border-box;
overflow-x:auto;
overflow-y:auto;
`;

export const CodeBodyLineDiv=styled.div`
width:100%;
padding: 7px 0px;
display:flex;
align-items:center;
font-family: 'Roboto Mono', monospace;
box-sizing: border-box;
`;

export const CodeyBodyLineText=styled.div`
font-size:15px;
padding:4px 3px;
font-weight:600;
color:${(props)=>props.color};
`;