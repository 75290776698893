import api from "../../API/baseURL";
import { useState } from "react";
import cloneDeep from 'lodash/cloneDeep';


const useBillingAPIsHook=()=>{

    const [fetchedData,setFetchedData]=useState({"userInfo":{},"userBilling":{},"user_total_bill":""});
    const [apiErrors,setApiErrors]=useState({"status":false,"message":""});
    const [paymentResponse,setPaymentResponse]=useState({});
    const [userBilling,setUserBilling]=useState({});
    const [userInfo,setuserInfo]=useState({});
    const [userTotalBill,setUserTotalBill]=useState("");
    const [userSubscriptions,setUserSubscriptions]=useState({"subscriber":false,"plans":[],"payment_infos":{},"encryption_bill":0,"rate_limit_bill":0})
    
    const getUserPaymentInfoApi=async()=>{
        try{
            const response= await api.get("/payment/get_user_payment_info",{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUserBilling(response.data);
        }catch(err){
            console.log(err,"err");
            setApiErrors({"status":true,"message":"some thing went wrong feching user payment info"})
        }
    };

    const getUserInfoApi=async()=>{
        try{
            const response= await api.get(`/payment/get_user_info?id=${localStorage.getItem('user_id')}`,{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setuserInfo(response.data);
        }catch(err){
            console.log(err,"err");
            setApiErrors({"status":true,"message":"some thing went wrong fetching user  info"})
        }
    };

    const getUserTotalBillApi=async()=>{
        try{
            const response= await api.get("/billing/get_total_bill_amount",{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUserTotalBill(response.data);
        }catch(err){
            console.log(err,"err");
            setApiErrors({"status":true,"message":"some thing went wrong fetching user  info"})
        }
    };

    const createSubscriptionForUser=async(payload)=>{
        try{
            const response=await api.post("/payment/initialize_user_subscription",payload,{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setPaymentResponse(response.data)
        }catch(err){
            console.log(err.response,"err stripe");
            if(err.response!=null){
                if(err.response.data!=null){
                    if(err.response.data.detail.error_type!=null){
                        if(err.response.data.detail.error_type=="stripeError"){
                            setApiErrors({"error_type":"stripeError","message":err.response.data.detail.error,"status":false})
                        }
                    }
                }
            }
        }
    };

    const checkPaymentSuccess=async(priceId,email,userId,subId)=>{
        try{
            const response=await api.post("/payment/check_payment_success",{"price_id":priceId,"email":email,"user_id":userId,"sub_id":subId},{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            //setPaymentResponse(response.data)
        }catch(err){
            console.log(err.response,"err stripe");
            setApiErrors({"error_type":"stripeError","message":err.response.data.detail,"status":false})
        }
    }

    const getUserSubsInfoApi=async()=>{
        try{
            const response= await api.get("/payment/get_user_plans_and_payment_info",{
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUserSubscriptions(response.data);
        }catch(err){
            console.log(err,"err");
            setApiErrors({"status":true,"message":"some thing went wrong feching user subscription list info"})
        }
    };

    

    return [fetchedData,userBilling,userInfo,userTotalBill,paymentResponse,apiErrors,setApiErrors,getUserPaymentInfoApi,getUserInfoApi,createSubscriptionForUser,getUserTotalBillApi,checkPaymentSuccess,userSubscriptions,getUserSubsInfoApi];
};

export default useBillingAPIsHook;