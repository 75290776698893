import { TextField } from "@mui/material";
import styled, { keyframes } from "styled-components";

const Icon_buzz_outAnim = keyframes`
{
    10% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    20% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    30% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    40% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
    50% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    60% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    70% {
      -webkit-transform: translateX(2px) rotate(1deg);
      transform: translateX(2px) rotate(1deg);
    }
    80% {
      -webkit-transform: translateX(-2px) rotate(-1deg);
      transform: translateX(-2px) rotate(-1deg);
    }
    90% {
      -webkit-transform: translateX(1px) rotate(0);
      transform: translateX(1px) rotate(0);
    }
    100% {
      -webkit-transform: translateX(-1px) rotate(0);
      transform: translateX(-1px) rotate(0);
    }
  }
`;

export const MainButtonStyle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  background: ${(props) => (props.variant === "main" ? "#F1E4FF" : "white")};
  border: none;
  color: ${(props) => (props.variant === "main" ? "white" : " #8C30F5")};
  border: ${(props) =>
    props.variant === "main" ? "" : "  0.5px solid rgba(129, 61, 232, 0.3)"};

  outline: none;
  user-select: none;
  transition: background 0.3s ease;

  text-align: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  cursor: ${(props) => (props.disable ? "not-allowed " : " pointer")} ;

  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);



  &:before {
    content: "";
    position: absolute;
    border-radius: 5px;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 1s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  :hover:before {
    background: #8c30f5;
    color: white;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  :hover {
    background: #8c30f5;
    color: white;
  }

  &.bounce {
    background: transparent;

    &:before {
      content: "";
      position: absolute;
      border-radius: 5px;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transform-origin: 0 50%;
      transform-origin: 0 50%;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

    :hover:before {
      background: #8c30f5;
      color: white;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
  }

  &.purple {
    color: ${(props) =>
      props.variant === "main" ? " rgba(140, 48, 245, 1)" : " #8C30F5"};
    :hover {
      color: white;
    }
    @media (max-width: 550px) {
   
    font-size:12px;
  }
   
  }

  &.Icon_buzz_out
  {
    &.icon {
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
      
    }
    :hover{
        & .icon
        {
            -webkit-animation-name: ${Icon_buzz_outAnim};
            animation-name: ${Icon_buzz_outAnim} ;
            -webkit-animation-duration: 0.75s;
            animation-duration: 0.75s;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    
        }
    
  }

  &.shutter_out {
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #e1e1e1;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transform-origin: 50%;
      transform-origin: 50%;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }

    :hover:before {
      background: #8c30f5;
      color: white;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
  }

 
`;

export const GoggleButtonStyle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  background: "red";
  border: none;
  color: black;
  border: 0.5px solid rgba(129, 61, 232, 0.3);

  outline: none;
  user-select: none;
  transition: background 0.3s ease;
  display: flex;
  text-align: center;
  justify-content: center;

  font-size: 16px;
  font-weight: 500;
  line-height: 22px;

  :hover {
    background: #8c30f5;
    color: white;
  }

  @media (max-width: 550px) {
  }
`;
export const InputStyle = styled(TextField)({
  zIndex: 0,
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(129, 61, 232, 0.3)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(129, 61, 232, 1)",
      backgroundColor: "rgba(217, 217, 217, 0.1)",
    },

    "&.Mui-focused fieldset": {
      borderColor: "rgba(129, 61, 232, 1)",
      backgroundColor: " rgba(252, 252, 252, 0)",
    },
  },
});

export const InputStyle_White = styled(TextField)({
  zIndex: 0,
  autoComplete:"off",
  color:"white",
  "& .MuiInputBase-input": {
  
    color: "white",
  
  },

  "& label.Mui-focused": {
    color: "white",
  },
  "& MuiInputBase-inputMultiline": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(129, 61, 232, 0.3)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(129, 61, 232, 1)",
      backgroundColor: "rgba(217, 217, 217, 0.1)",
      color:"red",
    },
    "&.MuiInputBase-root-MuiOutlinedInput-root": {
      borderColor: "rgba(129, 61, 232, 1)",
      backgroundColor: " rgba(252, 252, 252, 0)",
      color: "red",
    },

    "&.Mui-focused fieldset": {
      borderColor: "rgba(129, 61, 232, 1)",
      backgroundColor: " rgba(252, 252, 252, 0)",
    },
  },
});


export const InputStyle_White_large = styled(TextField)({
  zIndex: 0,
  autoComplete:"off",
  color:"white",
  width: "100%",
  minHeight: "100px",

  "& .MuiInputBase-input": {
  
    color: "white",
    minHeight: "100px",
  
  },

  "& label.Mui-focused": {
    color: "white",
    minHeight: "100px",
  },
  "& MuiInputBase-inputMultiline": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
    minHeight: "100px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(129, 61, 232, 0.3)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(129, 61, 232, 1)",
      backgroundColor: "rgba(217, 217, 217, 0.1)",
      color:"red",
    },
    "&.MuiInputBase-root-MuiOutlinedInput-root": {
      borderColor: "rgba(129, 61, 232, 1)",
      backgroundColor: " rgba(252, 252, 252, 0)",
      color: "red",
    },

    "&.Mui-focused fieldset": {
      borderColor: "rgba(129, 61, 232, 1)",
      backgroundColor: " rgba(252, 252, 252, 0)",
    },
  },
});

export const InputStyle_Large = styled(TextField)({
  width: "100%",
  minHeight: "100px",
  zIndex: 0,

  "& label.Mui-focused": {
    color: "#A0AAB4",
    minHeight: "100px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
    minHeight: "100px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      minHeight: "100px",
      borderColor: "rgba(129, 61, 232, 0.3)",
    },
    "&:hover fieldset": {
      minHeight: "100px",
      borderColor: "rgba(129, 61, 232, 1)",
      backgroundColor: "rgba(217, 217, 217, 0.1)",
    },

    "&.Mui-focused fieldset": {
      borderColor: "rgba(129, 61, 232, 1)",
      backgroundColor: " rgba(252, 252, 252, 0)",
    },
    "& .MuiInputBase-inputMultiline": {
      keyboardType: "TextInputType.multiline",
    },
  },
});

export const DropDownStyle = {
  control: (baseStyles, state) => {
    return {
      width: "60%",
      height: "56px",
      borderRadius: "5px",
      border: state.isFocused
        ? `2px solid rgba(129, 61, 232, 1)`
        : "1.5px solid rgba(129, 61, 232, 0.3) ",
      background: `white`,
      display: `flex`,
      flexDirection: `row`,
      ":hover": {
        border: state.isFocused
          ? `2px solid rgba(129, 61, 232, 1)`
          : "2px solid rgba(129, 61, 232, 0.7) ",
      },
    };
  },
  option: (baseStyles, state) => {
    return {
      ...baseStyles,
      height: "56px",
      background: state.isSelected
        ? `rgba(129, 61, 232, 0.7)`
        : state.isFocused
        ? "rgba(129, 61, 232, 0.2) "
        : "white",
      ":active": {
        background: "rgba(129, 61, 232, 0.5)",
      },
    };
  },
};

export const DropDownStyle_full = {
  control: (baseStyles, state) => {
    return {
      width: "100%",
      height: "56px",
      borderRadius: "5px",
      border: state.isFocused
        ? `2px solid rgba(129, 61, 232, 1)`
        : "1.5px solid rgba(129, 61, 232, 0.3) ",
      background: `white`,
      display: `flex`,
      flexDirection: `row`,
      ":hover": {
        border: state.isFocused
          ? `2px solid rgba(129, 61, 232, 1)`
          : "2px solid rgba(129, 61, 232, 0.7) ",
      },
    };
  },
  option: (baseStyles, state) => {
    return {
      ...baseStyles,
      height: "56px",
      background: state.isSelected
        ? `rgba(129, 61, 232, 0.7)`
        : state.isFocused
        ? "rgba(129, 61, 232, 0.2) "
        : "white",
      ":active": {
        background: "rgba(129, 61, 232, 0.5)",
      },
    };
  },
};

export const Icon_buzz_out = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  color: #8c30f5;
  gap: 15px;
  display: flex;
  align-items: center;
  background: red;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  cursor: pointer;

  & .icon {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);

    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  :hover {
    & .icon {
      -webkit-animation-name: ${Icon_buzz_outAnim};
      animation-name: ${Icon_buzz_outAnim};
      -webkit-animation-duration: 0.75s;
      animation-duration: 0.75s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
`;
