import RateLimitConfig from "../components/dashBoardComponents/rateLimiting/configuration";
import GetStarted from "../components/dashBoardComponents/rateLimiting/getStarted";
import SideNavBar from "../components/dashBoardComponents/SideNavBar/sideNavBar";
import { DashBoardMainDiv } from "../styles/dashBoardStyles/main.styles";
import { DashBoardContext } from "../contextApis/dashboardContext";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import useDashBoardHook from "../applicationLayer/dashBoardHooks/main";
import { useNavigate } from "react-router-dom";
import EncryptionStart from "../components/dashBoardComponents/encryption/encryptStart";
import EncryptionDetail from "../components/dashBoardComponents/encryption/encryptionDetail";
import PenStart from "../components/dashBoardComponents/penTesting/penStart";
import PenTestDetail from "../components/dashBoardComponents/penTesting/penTestDetail";
import useEncryptionStartAPI from "../apiLayer/dashBoardAPIs/encryption/encryptionStart";
import useRateLimitStart from "../apiLayer/dashBoardAPIs/rateLimiting/rateLimiStart";
import SdkPage from "../components/dashBoardComponents/OverviewComponents/sdkPage";
import Integration from "../components/dashBoardComponents/integrations";
import Billing from "../components/dashBoardComponents/Billing";
import SecureMonitorDashboard from "../components/dashBoardComponents/secureMonitoring/SecureMonitorDashboard";
import ManageKey from "../components/dashBoardComponents/managekey/manageKey";
import DeveloperReport from "../components/dashBoardComponents/developerReport/developerReport";
import Modal from "../components/accountPaymentInfo";
import { Alert } from "@mui/material";
import { AlertStyles } from "../styles/registerComponent.styles";
import api from "../API/baseURL";

const DashBoard = () => {
  const navigate = useNavigate();
  const [baseUrl, toggle, setBaseUrl, setToggle, setToggleValue, storageValue] =
    useDashBoardHook();
  const [data] = useEncryptionStartAPI();
  const [comingSoon, setComingSoon] = useState(false);
  const [rateLimitExists] = useRateLimitStart();
  const [isDeveloperMode, setDeveloperMode] = useState(false);
  const [alertForm, setAlertForm] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const openDeveloperMode = (value) => {
    setDeveloperMode(value);
    console.log(isDeveloperMode);
  };

  const checkPaymentSuccess=async(priceId,email,userId,subId)=>{
    try{
        const response=await api.post("/payment/check_payment_success",{"price_id":priceId,"email":email,"user_id":userId,"sub_id":subId},{
            headers:{
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        //setPaymentResponse(response.data)
        //navigate()
        setAlertForm({
          message: "you've subscribed for premium successfully,check your email for invoice.",
          severity: "success",
          show: true,
        });
    }catch(err){
        console.log(err.response,"err stripe");
        setAlertForm({
          message: err.response.data.detail,
          severity: "error",
          show: true,
        });
    }
}

  useEffect(() => {
    // Function to get query parameters from URL
    const getQueryParameter = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const paramValue = queryParams.get('stripeRedirect');
      if (paramValue==="yes") {
        // Do something with the query parameter value
        checkPaymentSuccess(queryParams.get('price_id'),queryParams.get('email'),queryParams.get('user_id'),queryParams.get('intent_id'));
        
      }
    };

    // Call the function when the component loads
    getQueryParameter();
  }, []); // Empty dependency array ensures the effect runs only once on mount


  useEffect(() => {
    

    for (const key in toggle) {
      if (toggle[key] === true) {
        switch (key) {
          case "overview":
            navigate("/dashboard");
            break;
          case "rateLimit":
          
            if (rateLimitExists == null) {
              console.log("ggg")
              break;
            }
            
            rateLimitExists
              ? navigate("/dashboard/rate-limit")
              : navigate("/dashboard/get-started/rate-limit");
            break;
           
          case "penTesting":
            //setComingSoon(true);
            navigate("/dashboard/pen-testing");
            break;
          case "encryption":
            navigate(
              `${
                data ? "/dashboard/encryption-detail" : "/dashboard/encryption"
              }`
            );
            break;

          case "integrations":
            navigate(`/dashboard/integrations`);
            break;
          case "developer_integrations":
            setComingSoon(true);
            break;

          case "billing":
            navigate(`/dashboard/billing`)
            break;
         

          case "secureLogs":
            navigate(`/dashboard/secure-logs`);
            break;
          case "developer-report":
            navigate(`/dashboard/developer-report`);
            break;

          case "manageKey":
            console.log(key);
            navigate(`/dashboard/manageKey`);
            break;

          default:
            break;
        }
      }
    }
  }, [toggle]);

  useEffect(() => {
    if (storageValue === null) {
      navigate("/");
    }
  }, [storageValue]);
  return (
    <DashBoardMainDiv>
      <DashBoardContext.Provider
        value={{
          toggle,
          setToggle,
          baseUrl,
          setBaseUrl,
          setToggleValue,
          rateLimitExists,
          data,
        }}
      >
        <SideNavBar developerMode={isDeveloperMode} />
        {comingSoon ? <Modal setComingSoon={setComingSoon} /> : <></>}
        {alertForm.show ? (
        <Alert
          sx={AlertStyles}
          onClose={()=>setAlertForm((prev)=>({...prev,show:false}))}
          severity={alertForm.severity}
        >
          {alertForm.message}
        </Alert>
      ) : (
        <></>
      )}
        <Routes>
          <Route
            index
            element={
              <SdkPage
                developerMode={openDeveloperMode}
                mode={isDeveloperMode}
              />
            }
          />
          <Route path="get-started/rate-limit" element={<GetStarted />} />
          <Route path="rate-limit" element={<RateLimitConfig />} />
          <Route path="encryption" element={<EncryptionStart />} />
          <Route path="encryption-detail" element={<EncryptionDetail />} />
          <Route path="pen-testing" element={<PenStart />} />
          <Route path="pen-detail" element={<PenTestDetail />} />
          <Route
            path="sdk"
            element={
              <SdkPage
                developerMode={openDeveloperMode}
                mode={isDeveloperMode}
              />
            }
          />
          <Route path="integrations" element={<Integration />} />
          <Route path="billing" element={<Billing />} />
          <Route path="coming-soon" element={<Billing />} />
          <Route
            path="secure-logs"
            element={<SecureMonitorDashboard />}
          ></Route>
          <Route path="manageKey" element={<ManageKey />}></Route>
          <Route
            path="developer-report"
            element={
              <DeveloperReport developerMode={openDeveloperMode} mode={true} />
            }
          ></Route>
        </Routes>
      </DashBoardContext.Provider>
    </DashBoardMainDiv>
  );
};

export default DashBoard;