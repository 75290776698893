import { DocumentationBodyMainDiv, DocumentationBodyNormalText, DocumentationBodyNormalTextH, DocumentationBodyPrimaryHeader, DocumentationBodySecHeader, DocumentationBodyUlDiv } from "../../../styles/documentationStyles/documentationBody.styles";



const DocRateLimiting=()=>{

    return(
        <DocumentationBodyMainDiv>
            <DocumentationBodyPrimaryHeader>Rate Limiting</DocumentationBodyPrimaryHeader>
            <DocumentationBodyNormalText>The Rate Limiting service in Sixth provides an effective mechanism to prevent abuse and protect your financial application from excessive requests. By implementing rate limiting, you can enforce limits on the number of requests a client can make within a certain time period.</DocumentationBodyNormalText>
            <DocumentationBodyNormalText>At Sixth, we offer a Rate Limiting SDK and middleware that seamlessly integrates with your server-side application, providing automatic rate limiting functionality.</DocumentationBodyNormalText>
            <DocumentationBodySecHeader>Integrating Rate Limiting Through SDK</DocumentationBodySecHeader>
            <DocumentationBodyNormalText>The Rate Limiting SDK allows you to incorporate rate limiting capabilities into your server-side application. It provides a set of APIs and middleware that handle the enforcement of request limits based on configurable parameters such as request count and time duration.</DocumentationBodyNormalText>
            <DocumentationBodyNormalText>To get started with rate limiting, follow these steps:</DocumentationBodyNormalText>
            <DocumentationBodyUlDiv>
                <li>Go to your dashboard and enable the rate limiting service.</li>
                <li>Install our Sixth sdk, <DocumentationBodyNormalTextH>npm i sixth-node</DocumentationBodyNormalTextH> for node and <DocumentationBodyNormalTextH>pip install Sixth</DocumentationBodyNormalTextH> for python.</li>
                <li>Add the rate limiting middleware that's in the SDK to your middlewares (should probably be the first middleware in the list) </li>
            </DocumentationBodyUlDiv>
        </DocumentationBodyMainDiv>
    );
};
export default DocRateLimiting;