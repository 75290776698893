import { useState,useEffect } from "react";
import useBillingAPIsHook from "../../apiLayer/dashBoardAPIs/billingCompAPIs";
import cloneDeep from 'lodash/cloneDeep';
import { paymentPlanAndPriceIdsObject, paymentPlansObject, paymentStatusEnum } from "../../utils/enums";


const useBillingHook=()=>{
    
    const [fetchedData,userBilling,userInfo,userTotalBill,paymentResponse,apiErrors,setApiErrors,
          getUserPaymentInfoApi,getUserInfoApi,createSubscriptionForUser,getUserTotalBillApi,checkPaymentSuccess,userSubscriptions,getUserSubsInfoApi] =useBillingAPIsHook();
    const [paymentStatus,setPaymentStatus]=useState({"oneTime":"pending","monthlyPay":"pending"});
    const [userPlan,setUserPlan]=useState(null);

    useEffect(()=>{
        const copiedData=cloneDeep(paymentStatus);
        if(fetchedData.userInfo.subscriber){
            copiedData["monthlyPay"]=paymentStatusEnum.PAID
            
        }else{
            copiedData["monthlyPay"]=paymentStatusEnum.UNAVAILABLE
        }

        if(fetchedData.userBilling!=null){
            if(fetchedData.userBilling.total_billing!=null){
                setUserPlan(paymentPlansObject[fetchedData.userBilling.total_billing]);
            }
        }

        setPaymentStatus(copiedData);
    },[fetchedData]);

    useEffect(()=>{
        getUserInfoApi();
        getUserPaymentInfoApi();
        getUserTotalBillApi();
    },[]);

    return [fetchedData,userBilling,userInfo,userTotalBill,paymentResponse,apiErrors,setUserPlan,createSubscriptionForUser,getUserPaymentInfoApi,getUserInfoApi,checkPaymentSuccess,userSubscriptions,getUserSubsInfoApi];

};
export default useBillingHook;