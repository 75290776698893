const PenTestLoading = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p style={{ fontSize: "24px", fontWeight: "800" }}>Loading..</p>
      <video autoPlay loop style={{ width: "40%" }}>
        <source
          src="/pen_test_loading.mp4"
          type="video/mp4"
          style={{ width: "100%" }}
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
export default PenTestLoading;
