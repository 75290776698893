import {
  EncryptionStartContent,
  EncryptionStartInnerDiv,
  EncryptionStartMainDiv,
  StartInnerButton,
  StartInnerHeader,
  StartInnerLearnMore,
  StartInnerSubHeader,
} from "../../../styles/dashBoardStyles/encryption/encryptionStart.styles";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import useTokenExpired from "../../../apiLayer/expirationEvent";
import api from "../../../API/baseURL";
import { AlertStyles } from "../../../styles/registerComponent.styles";
import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import useBillingHook from "../../../applicationLayer/dashBoardHooks/billingHook";
import MainPaymentDialog from "../paymentDialogs/mainPaymentDialog";


const EncryptionStart = () => {
  const [checkTokenExpiration]=useTokenExpired();
  const [disable,setDisable]=useState(false);
  const [userIsSubscribed,setUserIsSubscribed]=useState(null);
  const [buttonText,setButtonText]=useState("Enable Encryption");
  const navigate = useNavigate();
  const [showPaymentDialog,setShowPaymentDialog]=useState(false);
  const [paid,setPaid]=useState(false);
  const [selectedPlan,setSelectedPlan]=useState("");

  const [fetchedData,userBilling,userInfo,userTotalBill,paymentResponse,apiErrors,
    setUserPlan,createSubscriptionForUser,getUserPaymentInfoApi,getUserInfoApi,checkPaymentSuccess,userSubscriptions,getUserSubsInfoApi]=useBillingHook();

  const [alertForm, setAlertForm] = useState({
    message: "",
    severity: "success",
    show: false,
  });

  const showDocumentation = () => {
    window.open("https://docs.trysixth.com/encryption", "_blank");
  };

  useEffect(()=>{
    if(paid==true){
      setButtonText("Enable Encryption");
    }
  },[paid]);

  useEffect(()=>{
    console.log("KILODE???")
    checkIfUserAddedCard();
  },[]);


  const enableEncryption=async()=>{
    setDisable(true);
    if(buttonText==="Enable Encryption"){
      try{
        const response= await api.patch("/encryption-service/update_encryption_service",{"status":true},{
          headers:{
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
          
      });
      setAlertForm({
        message: "You enabled encryption successfully",
        severity: "success",
        show: true,
      });
      }catch(err){
        checkTokenExpiration(err);
        setDisable(false);
        setAlertForm({
          message: "could not enable encryption,try again later",
          severity: "error",
          show: true,
        });
      }
    }
    if(buttonText==="Disable Encryption"){
      try{
        const response= await api.patch("/encryption-service/update_encryption_service",{"status":false},{
          headers:{
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
          
      });
      setAlertForm({
        message: "You disabled encryption successfully",
        severity: "success",
        show: true,
      });
      }catch(err){
        checkTokenExpiration(err);
        setDisable(false);
        setAlertForm({
          message: "could not disable encryption,try again later",
          severity: "error",
          show: true,
        });
      }
    }

    if (buttonText==="Add Billing Details"){
      //navigate(`/dashboard/billing`)
      setShowPaymentDialog(true);
    }
  };

  const getEncryptionService=async()=>{
    setDisable(true);
    console.log("------------MONEY OH----------");
    try{
      const response= await api.get(`/encryption-service/get-encryption-service`,{
        headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
        
    });
    console.log(response.data,"CHECK AM")
    if (response.data===true){
      setButtonText("Disable Encryption");
    }
    setDisable(false);

    
    }catch(err){
      checkTokenExpiration(err);
      setDisable(false);
      
    }
  };

  const checkIfUserAddedCard=async()=>{
    setDisable(true);
    try{
      const response= await api.get(`/payment/get_user_subscriptions_and_plans`,{
        headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
        
    });
    //console.log("------------MONEY OH----------",response.data.plans);
    setUserIsSubscribed(response.data.subscriber);
    if (response.data.subscriber===false){
      setButtonText("Add Billing Details");
      setAlertForm({
          message: "Oops,seems you haven't added your billing details,you have to add your billing details to use this service.",
          severity: "error",
          hasAlert: true,
        });
      
    }else{
      if(response.data.plans!==null||response.data.plans!==undefined){
        if (!response.data.plans.includes("ENCRYPTER")){
          setButtonText("Add Billing Details");
          setAlertForm({
            message: "Oops,seems you haven't added your billing details,you have to add your billing details to use this service.",
            severity: "error",
            hasAlert: true,
          });
        }else{
          //setButtonText("Enable Encryption");
          getEncryptionService();
        }
        
      }else{
          setButtonText("Add Billing Details");
          setAlertForm({
            message: "Oops,seems you haven't added your billing details,you have to add your billing details to use this service.",
            severity: "error",
            hasAlert: true,
          });
      }
      
    }
    setDisable(false);
    

    
    }catch(err){
      checkTokenExpiration(err);
      setDisable(true);
      setAlertForm({
        message: "something went wrong,try again later",
        severity: "error",
        show: true,
      });
    }
  };

  
  return (
    <>
    {alertForm.show ? (
        <Alert
          sx={AlertStyles}
          onClose={() => setAlertForm((prev) => ({ ...prev, show: false }))}
          severity={alertForm.severity}
        >
          {alertForm.message}
        </Alert>
      ) : (
        <></>
      )}
      {
        showPaymentDialog?
        <MainPaymentDialog setShowPaymentDialog={setShowPaymentDialog} 
          createSubscriptionForUser={createSubscriptionForUser}
          apiErrors={apiErrors}  
          userData={{"userInfo":userInfo,"userBilling":userBilling,"user_total_bill":userTotalBill}} setUserPlan={setUserPlan} paymentResponse={paymentResponse} 
          getUserPaymentInfoApi={getUserPaymentInfoApi} getUserInfoApi={getUserInfoApi}  checkPaymentSuccess={checkPaymentSuccess}
          setPaid={setPaid} setSelectedPlan={setSelectedPlan} initPlan={"ENCRYPTER"}
          />
        :
        <></>
      }
      <EncryptionStartMainDiv>
        <Header />
        <EncryptionStartContent>
          <EncryptionStartInnerDiv>
            <StartInnerHeader>
              Encrypt all your application's request and response
            </StartInnerHeader>
            <StartInnerSubHeader>
              {
              userIsSubscribed==true||userIsSubscribed==null?
              <>
              Activating the encryption service enables an end to end encryption
              between your client and server side apps.Click to{" "}
              <StartInnerLearnMore onClick={(e) => showDocumentation()}>
                learn more....
              </StartInnerLearnMore>
              </>
              :
              <>
              Oops,seems you haven't added your billing details,you have to add your billing details to use this service.
              When you add you card successfully,come back to enable encryption.
              </>
              }
            </StartInnerSubHeader>
            <StartInnerButton disabled={disable} onClick={(e) => enableEncryption()}>
              {buttonText}
            </StartInnerButton>
          </EncryptionStartInnerDiv>
        </EncryptionStartContent>
      </EncryptionStartMainDiv>
    </>
  );
};
export default EncryptionStart;
