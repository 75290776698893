import { useEffect, useState } from "react";
import { MainPaymentDialogDiv } from "../../../styles/dashBoardStyles/paymentDialogs/mainPaymentDialog.styles";
import PickPricingDialog from "./pickPricingDialog";
import PaymentGatewayDialog from "./paymentGatewayDialog";


const MainPaymentDialog=(props)=>{

    const [plan,setPlan]=useState(props.initPlan);

    useEffect(()=>{
        if(plan!==null){
            props.setSelectedPlan(plan);
        }
    },[plan])

    return(
        <MainPaymentDialogDiv>
            {
                plan==null?
                <PickPricingDialog userInfo={props.userData.userInfo} clickPlan={setPlan} setShowPaymentDialog={props.setShowPaymentDialog} />
                :
                <PaymentGatewayDialog apiErrors={props.apiErrors} setShowPaymentDialog={props.setShowPaymentDialog} 
                createSubscriptionForUser={props.createSubscriptionForUser} userInfo={props.userData.userInfo} 
                paymentResponse={props.paymentResponse} getUserInfoApi={props.getUserInfoApi} 
                getUserPaymentInfoApi={props.getUserPaymentInfoApi} setPaid={props.setPaid} checkPaymentSuccess={props.checkPaymentSuccess} price={plan} 
                
                />
            }
        </MainPaymentDialogDiv>
    );
};
export default MainPaymentDialog;