import { useEffect, useState, useRef } from "react";
import { PenTestAPISection } from "../../../styles/dashBoardStyles/penTesting/penStart.styles";
import {
  ApiComponentBody,
  ApiComponentHead,
  ApiComponentIcon,
  ApiComponentLine,
  ApiComponentMainDiv,
  ApiComponentSection,
  ApiHeadSection,
  ApiKeyBarSection,
} from "../../../styles/dashBoardStyles/penTesting/penTestApiComponentStyle";
import { DashboardAlertElementStatusDiv } from "../../../styles/reusable/BorderStyles";
import {
  RegularText,
  SubHeaderText,
  SubHeaderText_H2,
} from "../../../styles/reusable/TextStyles";
import { RiArrowDropDownLine } from "react-icons/ri";

const PenTestApiComponent = ({ endpoint, url = "" }) => {
  const [openPreview, setOpenPreview] = useState(false);
  const endpointResults = useRef([]);

  const sortData = () => {
    for (const key in endpoint) {
      if (endpoint.hasOwnProperty(key) && key != "meta_data") {
        endpointResults.current.push({
          id: key,
          value: endpoint[key],
        });
      }
    }
  };
  useEffect(() => {
    sortData();
  });

  return (
    <>
      <ApiComponentMainDiv className={endpoint.method}>
        <ApiComponentHead>
          <ApiHeadSection>
            <DashboardAlertElementStatusDiv
              className={endpoint.method + " " + " thick"}
            >
              {endpoint.method}
            </DashboardAlertElementStatusDiv>

            <SubHeaderText_H2> {url} </SubHeaderText_H2>
          </ApiHeadSection>
          <ApiComponentIcon
            active={openPreview}
            onClick={() => {
              setOpenPreview(!openPreview);
            }}
          >
            <RiArrowDropDownLine size={"100%"} />
          </ApiComponentIcon>
        </ApiComponentHead>
        {openPreview ? (
          <ApiComponentBody>
            <ApiComponentLine className={endpoint.method} />
            <ApiComponentSection className="plain"></ApiComponentSection>

            {endpointResults.current.map((result) => (
              <ApiComponentSection>
                <ApiKeyBarSection>
                  <DashboardAlertElementStatusDiv
                    className={endpoint.method + " " + " thick"}
                  >
                    {result.id}
                  </DashboardAlertElementStatusDiv>
                </ApiKeyBarSection>
        
                <RegularText>{JSON.stringify(result.value)}</RegularText>
              </ApiComponentSection>
            ))}

            <ApiComponentSection className="plain"></ApiComponentSection>
            <SubHeaderText> Mitigation</SubHeaderText>
            <ApiComponentSection>
              <ApiKeyBarSection>
                <DashboardAlertElementStatusDiv
                  className={endpoint.method + " " + " thick"}
                >
                  Mitigation
                </DashboardAlertElementStatusDiv>
              </ApiKeyBarSection>

              <RegularText>{endpoint["meta_data"]["mitigation"]}</RegularText>
            </ApiComponentSection>

            <ApiComponentSection>
              <ApiKeyBarSection>
                <DashboardAlertElementStatusDiv
                  className={endpoint.method + " " + " thick"}
                >
                  Vulnerability
                </DashboardAlertElementStatusDiv>
              </ApiKeyBarSection>

              <RegularText className="des">
                {endpoint["meta_data"]["vulnerability"]}
              </RegularText>
            </ApiComponentSection>
          </ApiComponentBody>
        ) : (
          <></>
        )}
      </ApiComponentMainDiv>
    </>
  );
};

export default PenTestApiComponent;
