import { useEffect, useState } from "react";
import {
  ActivationBillDurationDiv,
  ActivationBillText,
  AmountTitleDiv,
  AmountValueDiv,
  BillingMainDiv, BillingPeriodButton, BillingPeriodSubText, BillingSumDiv, BillingSumHeaderDiv, BillingSumInnerBillItem, BillingSumInnerDiv, BillingTotalBillDiv, CurrentBillDurationDiv, CurrentBillText, InnerBillItemActivationBillDiv, InnerBillItemCurrentBillDiv, MainBillingHeader, SubBillingHeader, SubListDurationDiv, SubListItemTimeDiv, SubListParentDiv, SubListText, TotalBillAmountDiv, TotalBillLinkCardButton, TotalBillTitleDiv,
} from "../../styles/dashBoardStyles/BillingStyles";

import useBillingHook from "../../applicationLayer/dashBoardHooks/billingHook.js";
import MainPaymentDialog from "./paymentDialogs/mainPaymentDialog";
import {
  SubDangerText
} from "../../styles/reusable/TextStyles";
import { convertUnixTimestampToMonthAndYear } from "../../utils/gen_utils.js";
import { useNavigate } from "react-router-dom";

const Billing = () => {

  const [fetchedData,userBilling,userInfo,userTotalBill,paymentResponse,apiErrors,
    setUserPlan,createSubscriptionForUser,getUserPaymentInfoApi,getUserInfoApi,checkPaymentSuccess,userSubscriptions,getUserSubsInfoApi]=useBillingHook();

  const [showPaymentDialog,setShowPaymentDialog]=useState(false);
  const navigate = useNavigate();

  const [paid,setPaid]=useState(false);
  const [selectedPlan,setSelectedPlan]=useState("");

  const [initiatePlan,setInitiatePlan]=useState(null);

  const showBillingDueDate=()=>{
    
    if(userBilling){
      if(userBilling.payment_info){
        const date=convertUnixTimestampToMonthAndYear(userBilling.payment_info.subscription_at);
        return `(Renews ${date})`
      }else{
        return ""
      }
    }else{
      return ""
    }
  }

  const subForVscodeEnterprise=()=>{
    setInitiatePlan("VSCODE_EXTENSION");
    setShowPaymentDialog(true);
  }

  useEffect(()=>{
    if(showPaymentDialog===false){
      console.log("CALLED OH!")
      if(selectedPlan==="RATE_LIMITER"){
        navigate("/dashboard/get-started/rate-limit");
      }
      if(selectedPlan==="ENCRYPTER"){
        navigate("/dashboard/encryption");
      }
    }
  },[showPaymentDialog]);

  useEffect(()=>{
    getUserSubsInfoApi();
  },[])

  

  return (
    <BillingMainDiv>
      {
          showPaymentDialog?
          <MainPaymentDialog setShowPaymentDialog={setShowPaymentDialog} 
          createSubscriptionForUser={createSubscriptionForUser}
          apiErrors={apiErrors}  
          userData={{"userInfo":userInfo,"userBilling":userBilling,"user_total_bill":userTotalBill}} setUserPlan={setUserPlan} paymentResponse={paymentResponse} 
          getUserPaymentInfoApi={getUserPaymentInfoApi} getUserInfoApi={getUserInfoApi}  checkPaymentSuccess={checkPaymentSuccess}
          setPaid={setPaid} setSelectedPlan={setSelectedPlan} initPlan={initiatePlan}
          />
          :
          <></>
        }
      <MainBillingHeader>Billing</MainBillingHeader>
      <SubBillingHeader>Manage you billing payment details</SubBillingHeader>
      {
          userInfo.subscriber || paid ?
          <></>
          :
          <SubDangerText>You're currently not on a payment plan,click the Add credit/debit card on the line account section to get started.</SubDangerText>
        }
      <BillingSumDiv>
        <BillingSumHeaderDiv>Plan</BillingSumHeaderDiv>
        <BillingSumInnerDiv>

          <BillingSumInnerBillItem>
            <InnerBillItemActivationBillDiv>
              <ActivationBillText>{userInfo.subscriber?"Subscription Activated":"Activate Subscription"}</ActivationBillText> 
              <ActivationBillDurationDiv>Monthly</ActivationBillDurationDiv>
            </InnerBillItemActivationBillDiv>
            <InnerBillItemCurrentBillDiv>
                <CurrentBillText> {userTotalBill?`$${userTotalBill/100}`:"$0"}/current month</CurrentBillText>
                <CurrentBillDurationDiv bg={userInfo.subscriber?"#198754":"#E24848"}>
                { 
                    userInfo.subscriber? 
                    "SUBCRIBED"
                    :
                    <>{
                      paid==true?
                      "PENDING"
                      :
                      "UNAVAILABLE"
                      }
                </>}
                  
                </CurrentBillDurationDiv>
              </InnerBillItemCurrentBillDiv>
          </BillingSumInnerBillItem>


          <BillingSumInnerBillItem>
            <ActivationBillText>Billing Period</ActivationBillText> 
            <BillingPeriodSubText>Monthly{showBillingDueDate()}</BillingPeriodSubText>
            <BillingPeriodButton disabled={!userInfo.subscriber}>Check Billing History</BillingPeriodButton>
          </BillingSumInnerBillItem>

        </BillingSumInnerDiv>
      </BillingSumDiv>
      {
          Object.keys(userSubscriptions.payment_infos).map((key)=>(
          <>
          {
            ["payment_method_obj","subscription_at","subscription_expiry","updated_at"].includes(key)?
          <></>
        :
        <BillingTotalBillDiv>
          <TotalBillTitleDiv>{key}</TotalBillTitleDiv>
          <TotalBillAmountDiv>
            <SubListParentDiv>
              <SubListDurationDiv>Active</SubListDurationDiv>
              <SubListText>
                {
                  key==="ENCRYPTER"?
                  `$${userSubscriptions.encryption_bill/100}`
                  :
                  (
                    key==="RATE_LIMITER"?
                    `$${userSubscriptions.rate_limit_bill/100}`
                    :
                    <></>
                  )

                }
              </SubListText>
            </SubListParentDiv>
            <SubListItemTimeDiv>{`started at ${convertUnixTimestampToMonthAndYear(userSubscriptions.payment_infos[key]["subscription_at"])}`}</SubListItemTimeDiv>
          </TotalBillAmountDiv>
        </BillingTotalBillDiv>
          }
          </>
          
        ))
      }

      {
        "VSCODE_ENTERPRISE_TIER" in userSubscriptions.payment_infos?
        <>
        </>
      :
      <>
      {
          userInfo.user_type==="company"?
          <BillingTotalBillDiv>
      <TotalBillTitleDiv>VSCODE_ENTERPRISE_TIER</TotalBillTitleDiv>
      <TotalBillAmountDiv>
        <SubListParentDiv>
        <CurrentBillDurationDiv bg="#E24848">
           Not Subscribed 
        </CurrentBillDurationDiv>
          <SubListText>
            $250
          </SubListText>
        </SubListParentDiv>
        <TotalBillLinkCardButton onClick={(e)=>subForVscodeEnterprise()}>Add credit/debit card</TotalBillLinkCardButton>
      </TotalBillAmountDiv>
    </BillingTotalBillDiv>
          :
          <></>
        }
      </>
        
      }
    
      
      <BillingTotalBillDiv>
        <TotalBillTitleDiv>Total Bill Spent</TotalBillTitleDiv>
        <TotalBillAmountDiv>
          <AmountTitleDiv>Total Bill</AmountTitleDiv>
          <AmountValueDiv>{userTotalBill?`$${userTotalBill/100}`:"$0"}</AmountValueDiv>
        </TotalBillAmountDiv>
      </BillingTotalBillDiv>
      <BillingTotalBillDiv>
        <TotalBillTitleDiv>Link accounts</TotalBillTitleDiv>
        <TotalBillAmountDiv>
          <AmountTitleDiv>Card</AmountTitleDiv>
          {
            userInfo.subscriber==true || paid==true?
            <TotalBillLinkCardButton>Cancel Subscription</TotalBillLinkCardButton>
            :
            <TotalBillLinkCardButton onClick={()=>setShowPaymentDialog(true)}>Add credit/debit card</TotalBillLinkCardButton>
          }
        </TotalBillAmountDiv>
      </BillingTotalBillDiv>
      </BillingMainDiv>
  )
};

export default Billing;
