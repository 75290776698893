import styled from "styled-components";

export const Report_line = styled.div`
  width: ${(props) => (props.vertical === true ? "1px" : "100%")};
  height: ${(props) => (props.vertical === true ? "100%" : "1px")};
  background: #18191f40;
  padding: 0px;
  margin: 0px;
  
  @media (max-width: 460px) {
    height: ${(props) => (props.vertical === true ? "0px" : "1px")};

   
    }
`;

export const ReportMainDiv = styled.div`
width:100%;
height:100%;
padding:0px;
display:flex;
flex-direction:column;
margin:0px
align-items:center;
justify-content:start;
background:white;
`;

export const ReportHeader = styled.div`
width:100%;
height:100%;
padding:0px;
display:flex;
flex-direction:column;
margin:0px
align-items:center;
justify-content:start;
background:white;
`;

export const ReportBodyBorder_head = styled.div`
  width: 100%;
  background: #ffffff;
  color: #8c30f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ReportLinkText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  cursor:pointer;
  height:50px;
  margin-top:50px;
  margin-bottom:50px;
  width: 300px;
  :hover
  {
    color:rgba(140, 48, 245, 1);
  
  }

  @media (max-width: 460px) {
    width: 90%;
    }
`;
export const ReportApiBodyBorder = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba(241, 228, 255, 1);
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);
`;

export const ReportDiv = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: start;
  background: white;
  @media (max-width: 460px) {
    flex-direction: column;

   
    }
`;

export const Report_start = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  color: #8c30f5;
  text-align: left;
  overflow-x: hidden;
  @media (max-width: 460px) {
   width:100%;

   
    }
`;

export const Report_description = styled.div`
  width: 70%;
  padding-left: 2%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
  color: #18191f;
  box-sizing:border-box;
  text-align: left;
  overflow:hidden;
  
  @media (max-width: 460px) {
    width: 100%;
    padding-left:10px;

    }
  
`;
