import styled from "styled-components";


export const GlobalLoaderMainDiv=styled.div`
position:fixed;
top:0;
left:0;
width:100vw;
height:100vh;
display:flex;
justify-content:center;
align-items:center;
box-sizing: border-box;
background-color: rgba(0, 0, 0, 0.5);
z-index:100;
`;

export const LoaderIcon=styled.div`
color:#0091EA;
`;