import styled from "styled-components";


export const CheckoutMainDiv=styled.div`
width:100%;
height:100%;
display:flex;
flex-direction:column;
`;

export const TextFieldStyles={
  marginBottom:'10px',
  border:"none !important",
  outline:"none !important",
  '& input':{
    border:'1px solid #F1E4FF',
    '&:focus':{
      borderColor:'transparent'
    }
  },
  
  '& label.Mui-focused': {
    color: "#8C30F5",
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  
  '& .MuiOutlinedInput-root':{
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8C30F5', // Notched outline color when focused
    },
    
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F1E4FF', // Notched outline color
    }
  }
}

export const CheckoutButtonStyles = {
    backgroundColor: '#8C30F5',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#aa69f5',
    },
    marginTop:'12px',
    padding:'13px'
  };

export const CancelBtnDiv=styled.div`
width:100%;
display:flex;
flex-direction:row-reverse;
margin-bottom:10px;
`;

export const CancelBtn=styled.div`
font-size:35px;
font-weight:800;
`;