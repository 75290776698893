import { useState } from "react";
import {
  ComingSoonHeader,
  ComingSoonIcon,
  ComingSoonMainDiv,
} from "../styles/comingSoon.styles";
import {
  CloseButton,
  ConfirmDeleteContent,
  ConfirmDeleteInnerDiv,
  CancelButton,
  ConfirmButton,
  OptionField,
} from "../styles/confirmDeleteStyles";
import { MainButtonStyle } from "../styles/reusable/inputTypes";
import CancelIcon from "@mui/icons-material/Cancel";
import { AiFillCloseCircle } from "react-icons/ai";
import { GiConfirmed } from "react-icons/gi";

const ConfirmDelete = (props) => {
  const closeModal = () => {
    if(inProgress)
    return 
    props.setDelete({
      id:"-1",
      newEdit :false,
      message:"Delete Log",

    });
  };
  const Delete =async () =>
  {
    if(inProgress )
    return 
    deleteInProgress(true)

    const response = await props.deleteAction();
    
    deleteInProgress(false)
    if(response =="success")
    {
      closeModal();

    }
   
  
    

  }

  const [inProgress ,deleteInProgress] = useState(false)

  return (
    <ComingSoonMainDiv>
      <ConfirmDeleteInnerDiv>
        <CloseButton onClick={closeModal} disable ={inProgress}>
          <AiFillCloseCircle
            style={{
              width: "100%",
              height: "100%",
              color: "white",
            }}
          />
        </CloseButton>
        <ConfirmDeleteContent >
          <ComingSoonIcon src={"/coming.png"} />

          <ComingSoonHeader> {props.action} </ComingSoonHeader>

          <p
            style={{
              fontSize: "13px",
              fontWeight: "200",
              color: "#161616",
              textAlign: "center",
            }}
          >
            Do you want to procced with this Action?. 😉
          </p>
          <OptionField>
            <ConfirmButton onClick={Delete}>
              <MainButtonStyle className="Icon_buzz_out" disable ={inProgress}>
                Confirm
              
                <GiConfirmed
                
                className="icon"
                style={{
                    width: "20px",
                    height: "40%",
                  
                  }}
                 

                />
                
                 
                
              </MainButtonStyle>
            </ConfirmButton>

            <CancelButton>
              <MainButtonStyle
               onClick={closeModal}
               className="Icon_buzz_out"  disable ={inProgress} >
                cancel
                <CancelIcon className="icon" />
              </MainButtonStyle>
            </CancelButton>
          </OptionField>
        </ConfirmDeleteContent>
      </ConfirmDeleteInnerDiv>
    </ComingSoonMainDiv>
  );
};
export default ConfirmDelete;
