import styled from "styled-components";


export const RateLimitConfigMainDiv=styled.div`
width:83%;
height:100%;
box-sizing: border-box;


::-webkit-scrollbar {
    width: 5px;
    border-radius: 1rem;
  }
  
  
::-webkit-scrollbar-track {
    background:#cbced1;
  }
  
  
::-webkit-scrollbar-thumb {
    background: grey;
    cursor:pointer;
  }
  
  
::-webkit-scrollbar-thumb:hover {
    background: grey;
    width: 20px;
    cursor:pointer;
  }
  
@media (max-width: 550px) {
 width:100%;
}
`;

export const ConfigContent=styled.section`
height:88%;
padding:30px 3.5%;
overflow-y: auto;
overflow-x: hidden;
box-sizing: border-box;

@media (max-width: 550px) {
 width:100%;
}
`;

export const ConfigHeader=styled.div`
font-size:25px;
font-weight:bold;
margin-bottom:20px;

`;

export const ConfigEndpointDiv=styled.div`
width:70%;
border-radius:10px;
border:1px solid #8C30F5;
padding:6px;
margin-bottom:10px;
box-sizing: border-box;

@media (max-width: 550px) {
 width:100%;
}
`;

export const EndpointNormDiv=styled.div`
width:100%;
display:flex;
padding:10px;
border-radius:10px;
border:1px solid #F1E4FF;
background:#F1E4fF;
box-sizing: border-box;
margin-top:8px;
`;

export const NormEndPointUrl=styled.div`
font-size:16px;
font-weight:600;
`;

export const EndpointDropDownDiv=styled.div`
display:${(props)=>props.show?"block":"none"};
margin-top:6px;
width:100%;
padding:14px;
border-radius:10px;
box-sizing: border-box;
transition:all 0.3s ease;
background:#F1E4fF;
`;

export const EndpointToggleDiv=styled.div`
width:100%;
display:flex;
flex-direction:row-reverse;
`;

export const EndpointToggleIcon=styled.div`
`;

export const EndpointUpdateField=styled.div`
width:50%;
margin-bottom:20px;
box-sizing: border-box;

@media (max-width: 550px) {
 width:90%;
}
`;

export const SelectWrapperDiv=styled.div`
width:100%;
margin:10px 6px;
`;

export const SelectWrapperHeader=styled.div`
font-size:15px;
font-weight:bold;
`;

export const UpdateFieldLabel=styled.div`
font-size:16px;
font-weight:bold;
margin-bottom:10px;
`;

export const UpdateFieldInput=styled.input`
width:100%;
padding:20px 15px;
border: 1px solid #8C30F5;
font-size:15px;
outline:none;
border-radius:8px;

:active{
    border: 2px solid #8C30F5;

}

:focus{
    border: 2px solid #8C30F5; 
}

::placeholder{
    font-size:15px;
}
`;


export const UpdateFieldInputLong=styled.textarea`
width: 100%;
  min-height: 100px;
  padding: 20px;
  border: 1px solid #8C30F5;
  font-size: 15px;
  outline: none;
  border-radius: 8px;
  vertical-align: text-top; /* Use text-top to align text at the top */
  box-sizing: border-box; /* Ensure padding is included in the width */
  justify-content: start;
  
  :active {
    border: 2px solid #8C30F5;
  }

  :focus {
    border: 2px solid #8C30F5;
  }

  ::placeholder {
    font-size: 15px;
  }
`;


export const FieldInputDiv=styled.div`
display:flex;
width: 100%;
margin-top:20px;
margin-bottom:20px;
padding:10px;
align-items:left;
justify-content:center;
display:flex;
flex-direction:column;
min-height: 100px;
box-sizing:border-box;
 
`;



export const EndpointErrorFieldsDiv=styled.div`
width:100%;
margin-top:20px;
margin-bottom:10px;
box-sizing: border-box;
`;

export const ErrorFieldsRow=styled.div`
width:100%;
display:flex;
margin-bottom:7px;
align-items:center;
`;

export const UpdateFieldKeyValueInput=styled.input`
width:40%;
padding:20px 15px;
border: 1px solid #9E9E9E;
font-size:15px;
outline:none;
border-radius:8px;
margin-right:10px;


:active{
    border: 2px solid #0277BD;

}

:focus{
    border: 2px solid #0277BD; 
}

::placeholder{
    font-size:15px;
}
`;

export const FieldsRowCancelBtn=styled.div`
color:#0277BD;
margin-left:30px;
font-size:18px;
`;


export const SubmitUpdateDiv=styled.div`
width:100%;
display:flex;
justify-content:left;
align-items:center;
height:50px;
`;

export const AddErrorBtn=styled.button`
width:40%;
padding:12px 0px;
margin:10px;
border-radius:6px;
background:#8C30F5;
text-align:center;
color: white;
font-size: 14px;
font-weight: 800;
border:none;sty
outline:none;
user-select:none;
transition:background 0.3s ease;
margin-bottom:20px;

:hover{
    background:#8C30F5;
}
`;


export const SubmitUpdate=styled.button`
width:80%;
height: 48px;
padding:15px 0px;
border-radius:6px;
background:${props=>props.isActive? "#8C30F5": "#F1E4FF" };
text-align:center;
color: white;
font-size: 16px;
font-weight: 800;
border:none;
outline:none;
user-select:none;
transition:background 0.3s ease;
cursor: ${props=>props.isActive ? 'not-allowed' : 'pointer'}

:hover{
    background:#8C30F5;
    color:${props=>props.isActive? "white": "#8C30F5" };
}
:active{
    background:#8C30F5;
    color:${props=>props.isActive? "white": "#8C30F5" };
}
`;