import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  BarBodyItem,
  BodyItemText,
  BodyItemIcon,
} from "../../../styles/dashBoardStyles/sideNavBar.styles";

import { BsDatabaseLock } from "react-icons/bs";
import { FiShield, FiServer, FiLock } from "react-icons/fi";
import { IoDocumentText } from "react-icons/io5";
import { AiOutlineHome } from "react-icons/ai";
import { BiCommand } from "react-icons/bi";
import { TbReportAnalytics } from "react-icons/tb";
import { GiGearStickPattern } from "react-icons/gi";
import { IoCardOutline } from "react-icons/io5";
import { DefaultDiv } from "../../../styles/reusable/BorderStyles";
const CompanyNavBar = ({ setToggleValue = () => {}, toggle }) => {
  const showDocumentation = () => {
    window.open("https://docs.trysixth.com", "_blank");
  };

  return (
    <DefaultDiv>
    
      {navigation.map((item, index) => (
        <BarBodyItem
          clicked={toggle[item.path]}
          onClick={() => {
            setToggleValue(item.path);
            if(item.click != null)
            item.click()
          }}
        >
          <BodyItemIcon>{item.icon}</BodyItemIcon>
          <BodyItemText>{item.name}</BodyItemText>
        </BarBodyItem>
      ))}
    </DefaultDiv>
  );
};
CompanyNavBar.prototype = {
  setToggleValue: PropTypes.func.isRequired,
  toggle: PropTypes.array.isRequired,
};

const navigation = [
  { icon: <AiOutlineHome />, path: "overview", name: "Overview" },
  { icon: <BsDatabaseLock />, path: "encryption", name: "Encryption" },
  { icon: <FiShield />, path: "penTesting", name: "Pen Testing" },
  { icon: <FiServer />, path: "rateLimit", name: "Rate Limit" },
  { icon: <BiCommand />, path: "manageKey", name: "Manage Keys" },
  { icon: <FiLock />, path: "secureLogs", name: "Secure Logs" },
  {
    icon: <IoDocumentText />,
    path: "documentation",
    name: "Documentation",
    click: () => {
        window.open("https://docs.trysixth.com", "_blank");
      },
  },
  { icon: <GiGearStickPattern />, path: "integrations", name: "Integrations" },
  { icon: <IoCardOutline />, path: "billing", name: "Billing" },
  { icon: <TbReportAnalytics />, path: "developer-report", name: "Report" },
];
export default CompanyNavBar;
