import { useState } from "react";
import { FeaturesListItemDiv, FeaturesListItemDivIcon, FeaturesListItemDivText, PickPriceInnerDiv, PickPricingHeaderDiv, PickPricingItemDiv, PickPricingMainDiv, PriceMainPeriod, PriceMainSlash, PriceMainText, PricingHeaderIcon, PricingHeaderTitleDiv, PricingItemFeaturesList, PricingItemHeader, PricingItemIcon, PricingItemPrice } from "../../../styles/dashBoardStyles/paymentDialogs/pickPricingDialog.styles";
import { userTypeEnum } from "../../../utils/enums";
import {BsCheckLg} from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import vscode from "../../../assets/akar-icons_vscode-fill.svg";
import ratelimiter from "../../../assets/material-symbols_developer-mode-outline.svg";
import encryption from "../../../assets/material-symbols_no-encryption-outline.svg";
import { MdOutlineVerified } from "react-icons/md";


const PickPricingDialog=(props)=>{

    return(
        <PickPricingMainDiv>
            <PickPricingHeaderDiv>
                <PricingHeaderIcon onClick={()=>props.setShowPaymentDialog(false)} ><FaTimes/></PricingHeaderIcon>
                <PricingHeaderTitleDiv>Plans</PricingHeaderTitleDiv>
            </PickPricingHeaderDiv>
            <PickPriceInnerDiv>
                {
                    props.userInfo.user_type==userTypeEnum.DEVELOPER?
                    <>
                    <PickPricingItemDiv onClick={()=>props.clickPlan('VSCODE_EXTENSION')} >
                            <PricingItemHeader><PricingItemIcon src={vscode}/>VS code Extension(Freemium)</PricingItemHeader>
                            <PricingItemPrice>
                                <PriceMainSlash>$0/</PriceMainSlash><PriceMainPeriod>Monthly</PriceMainPeriod>
                            </PricingItemPrice>
                            <PricingItemFeaturesList>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Security Vulnerability Detection</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Partial access to sixth's coding AI</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                            </PricingItemFeaturesList>
                    </PickPricingItemDiv>

                    <PickPricingItemDiv onClick={()=>props.clickPlan('VSCODE_STANDARD_TIER')} >
                            <PricingItemHeader><PricingItemIcon src={vscode}/>VS code Extension(Standard Tier)</PricingItemHeader>
                            <PricingItemPrice>
                                <PriceMainSlash>$5/</PriceMainSlash><PriceMainPeriod>Monthly</PriceMainPeriod>
                            </PricingItemPrice>
                            <PricingItemFeaturesList>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Security Vulnerability Detection</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Full access to sixth's coding AI</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                            </PricingItemFeaturesList>
                    </PickPricingItemDiv>

                    <PickPricingItemDiv onClick={()=>props.clickPlan('VSCODE_PREMIUM_TIER')} >
                    <PricingItemHeader><PricingItemIcon src={vscode}/>VS code Extension(Premium Tier)</PricingItemHeader>
                    <PricingItemPrice>
                        <PriceMainSlash>$15/</PriceMainSlash><PriceMainPeriod>Monthly</PriceMainPeriod>
                    </PricingItemPrice>
                    <PricingItemFeaturesList>
                    <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Security Vulnerability Detection</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Code Optimization</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Full access to sixth's coding AI</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Unit test auto generation</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                    </PricingItemFeaturesList>
                    </PickPricingItemDiv>
                    </>
                    :
                    <>
                    <PickPricingItemDiv onClick={()=>props.clickPlan('VSCODE_EXTENSION')} >
                            <PricingItemHeader><PricingItemIcon src={vscode}/>VS code Extension(ENTERPRISE)</PricingItemHeader>
                            <PricingItemPrice>
                                <PriceMainSlash>$250/</PriceMainSlash><PriceMainPeriod>Month</PriceMainPeriod>
                            </PricingItemPrice>
                            <PricingItemFeaturesList>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Get protected from our archive of over 1k vulnerabilities.</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>AI powered quick fixes.</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Dashboard to track all developers found vulnerabilities and fixes within your organization.</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Custom Integration with JIRA</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                            </PricingItemFeaturesList>
                    </PickPricingItemDiv>

                    <PickPricingItemDiv onClick={()=>props.clickPlan("RATE_LIMITER")} >
                    <PricingItemHeader><PricingItemIcon src={ratelimiter}/>Rate Limiter</PricingItemHeader>
                    <PricingItemPrice>
                        <PriceMainSlash>$0.1/</PriceMainSlash><PriceMainPeriod>prevented rate limit attack</PriceMainPeriod>
                    </PricingItemPrice>
                    <PricingItemFeaturesList>
                               <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Access to rate limiting users.</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Slack logs.</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                                <FeaturesListItemDiv>
                                    <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Protect your resources dynamically.</FeaturesListItemDivText>
                                </FeaturesListItemDiv>
                    </PricingItemFeaturesList>
                  </PickPricingItemDiv>

                  <PickPricingItemDiv onClick={()=>props.clickPlan("ENCRYPTER")} >
                    <PricingItemHeader><PricingItemIcon src={encryption}/>Encryption</PricingItemHeader>
                    <PricingItemPrice>
                        <PriceMainText>$100</PriceMainText><PriceMainSlash>/</PriceMainSlash><PriceMainPeriod>monthly</PriceMainPeriod>
                    </PricingItemPrice>
                    <PricingItemFeaturesList>
                        <FeaturesListItemDiv>
                            <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>All payloads are encrypted and decrypted during runtime.</FeaturesListItemDivText>
                        </FeaturesListItemDiv>
                        <FeaturesListItemDiv>
                            <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Slack logs of anyone who tries to bypass encryption.</FeaturesListItemDivText>
                        </FeaturesListItemDiv>
                        <FeaturesListItemDiv>
                            <FeaturesListItemDivIcon><MdOutlineVerified /></FeaturesListItemDivIcon><FeaturesListItemDivText>Hack proof end to end encryption.</FeaturesListItemDivText>
                        </FeaturesListItemDiv>
                    </PricingItemFeaturesList>
                  </PickPricingItemDiv>
            </>
                }
            </PickPriceInnerDiv>
        </PickPricingMainDiv>
    );
};

export default PickPricingDialog;