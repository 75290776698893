import styled from "styled-components";

export const PenStartMainDiv = styled.div`
  width: 83%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const PenStartContent_div = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  display: ${(props) => (props.value === props.index ? "flex" : "none")};
`;
export const PenStartSecMainDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const PenStartInnerDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  background: #f9f9f9;
  align-items: left;

  @media (max-width: 550px) {
    width: 100%;
  }
`;
export const PenStartMainContentDiv = styled.div`
  
 

  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 1rem;
  }

  ::-webkit-scrollbar-track {
    background: #cbced1;
  }

  ::-webkit-scrollbar-thumb {
    background: grey;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: grey;
    width: 20px;
    cursor: pointer;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;
export const PenStartMobileDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;

export const PenResultCard = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: white;
  color: #000000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 10px;
  text-wrap: nowrap;
  overflow-x:hidden;
`;


export const PenResultCardHead = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: white;
  color: #000000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 10px;
  text-wrap: nowrap;
  overflow-x:hidden;
  @media (max-width: 550px) {
   flex-direction:column;
  }
`;
export const PenTestStatusText = styled.div`
  display: flex;
  align-items: center;
`;

export const PenTestStatus = styled.div`
  height: 20px;
  background-color: ${(props) => props.backgroundColor};
  color: white;
  font-size: 14px;
  margin-left: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;
`;

export const PenResultHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
`;

export const PenTestDescription = styled.p`
  font-size: 14px;
`;

export const PenResultSection = styled.div`
  height: 50px;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding-left: 57px;
`;

export const PenStartHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 48px;
  margin-left: 24px;
`;

export const PenStartContentHolder = styled.div`
width:80%;
display:flex;
align-items:center;
justify-content:flex-start;
background:white;
margin-top:20px;
margin-bottom:20px;
padding-top:20px;
padding-bottom:20px;
border-radius:5px;
align-items:center;
flex-direction:column;
justify-content:flex-start;
gap:64px;
box-shadow: 0px 4px 4px 0px #00000040;


@media (max-width: 550px) {
 width:100%;
}


`;

export const PenStartContentHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: left;
   @media (max-width: 550px) {
   justify-content:center;
  }
`;

export const PenStartSubHeader = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 30px;
  color: #757575;
  text-align: center;
`;

export const PenStartButton = styled.button`
  width: 51%;
  height: 64px;
  border-radius: 6px;
  padding: 0px 0px;
  text-align: center;
  background: #f1e4ff;
  border: none;
  color: #8c30f5;
  font-size: 18px;
  outline: none;
  user-select: none;
  transition: background 0.3s ease;

  :hover {
    background: #813de8;
    color: white;
  }
`;

export const PenStartAppsInputDiv = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  margin-bottom: 42px;
`;

export const PenStartAppsInput = styled.input`
width:50%;
height: 42px;
padding:10px 10px;
border: 0.5px solid #813DE8CC;
font-size:16px;
outline:none;
border-radius:8px;
margin-bottom:10px;
color: #18191F

:active{
    border: 0.5px solid #813DE8CC;

}

:focus{
    border: 0.5px solid #813DE8CC; 
}

::placeholder{
    font-size:16px;
}
`;

export const PenStartAppsButton = styled.button`
  width: 35%;
  height: 56px;
  border-radius: 6px;
  padding: 13px 0px;
  text-align: center;
  background: #f1e4ff;
  border: none;
  color: #8c30f5;
  font-size: 14px;
  outline: none;
  user-select: none;
  transition: background 0.3s ease;

  :hover {
    background: #8c30f5;
    color: white;
  }
`;

export const PenStartInputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  box-sizing: border-box;
`;

export const PenStartInputLabel = styled.div`
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
  height: 50px;
`;

export const PenStartCompanyIcon = styled.div`
  width: 100px;
  height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background: rgb(179, 179, 179);
  color: white;
  border-radius: 100%;
  margin-right: 50px;
  

  
`;

export const PenStartCompanyIconText = styled.div`
  font-size: 70px;
  font-weight: 1000;
`;

export const PenStartInput = styled.input`
  width: 100%;
  padding: 10px 6px;
  border: 1px solid #9e9e9e;
  font-size: 14px;
  outline: none;
  border-radius: 8px;
  margin-bottom: 10px;

  :active {
    border: 2px solid #0277bd;
  }

  :focus {
    border: 2px solid #0277bd;
  }

  ::placeholder {
    font-size: 14px;
  }
`;

export const PenStartDropDown = styled.div`
  width: 94%;
  display: flex;
  align-items: left;
  justify-content: start;
  flex-direction: column;
  background-color: white;
  color: #000000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  border-radius: 10px;
  text-wrap: nowrap;
  text-align: left;
  padding: 10px;
  color: grey;
  gap: 10px;
`;

export const PenStartDropDownHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
`;

export const PenStartDropDownBody = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  text-wrap:wrap;

  
`;

export const PenTestAPISection = styled.div`
display:flex;
height:40px;
justify-content:
width:100%;
justify-content:flex-start;
align-items:center;
border: 2px solid rgb(140, 48, 245);
border-radius:10px;

gap:24px;
padding:8x;
margin:0px;
background: rgb(241, 228, 255);
&.Post
{
    background:   #ecfaf4;
    border: 2px solid   #49cc90;
}


&.Get
{
    background: #eff7ff    ;
     border: 2px solid   #61affe;
}

&.Patch
{
    background:#edfcf9;
    border: 2px solid   #50e3c2;
}

&.Delete
{
    background: #feebeb;
    border: 2px solid  #f93e3e;
}
`;

export const PenStartDropDownArrow = styled.div`
  background: #ffffff;
  color: grey;

  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 100%;

  padding: 0px;
  margin: 0px;
  transform: rotate(${(props) => (props.active ? "180deg" : "0deg")});
  transition: 0.45s;
  :hover {
    color: #8c30f5;
    background: #ffffff;
  }
`;
