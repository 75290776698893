import {
  SdkPageContent,
  SdkPageMainDiv,
  Spacer,
  DeveloperError,
} from "../../../styles/dashBoardStyles/OverviewComponents/sdkPage.styles";
import { BiError } from "react-icons/bi";
import { useState, useContext, useRef, useEffect } from "react";
import emptyAnimation from "../../../assets/empty_animation.mp4";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import {
  UserText,
  SubHeaderText_H2,
  RegularText,
} from "../../../styles/reusable/TextStyles";
import api from "../../../API/baseURL";
import PropTypes from "prop-types";
import { DashBoardContext } from "../../../contextApis/dashboardContext";
import {
  ReportApiBodyBorder,
  ReportDiv,
  ReportMainDiv,
  Report_description,
  Report_line,
  ReportBodyBorder_head,
  Report_start,
  ReportLinkText,
} from "../../../styles/dashBoardStyles/developerReport/developerReport.Styles";
import { MainButtonStyle } from "../../../styles/reusable/inputTypes";
import useTokenExpired from "../../../apiLayer/expirationEvent";

const DeveloperReport = ({ developerMode, mode }) => {
 

  const [allVsReport, setAllVsReport] = useState([]);
  const sensitiveDataResults = useRef([]);
  const mobilePenTestResults = useRef([]);
  const backendPenTestResults = useRef([]);
  const [reportViewLength, setReportViewLength] = useState(9);
  const viewMore = () => {
    setReportViewLength(reportViewLength + 10);
  };
  const [checkTokenExpiration]=useTokenExpired();

 

  const getAllVsReport = async () => {
    try {
      const response = await api.get(`/vs-code/get-all-diagnostics`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "x-api-key": localStorage.getItem("user_id"),
        },
      });
      if (response.status === 200) {
        setAllVsReport([]);
        setAllVsReport((prevJsonList) => [
          ...prevJsonList,
          ...response.data["data"],
        ]);

        for (let i = 0; i < response.data["data"].length; i++) {
          if (response.data["data"][i].category == "SENSITIVE_DATA_EXPOSURE") {
            sensitiveDataResults.current.push(response.data["data"][i]);
          } else if (response.data["data"][i].category == "MOBILE") {
            mobilePenTestResults.current.push(response.data["data"][i]);
          } else if (response.data["data"][i].category == "BACKEND") {
            backendPenTestResults.current.push(response.data["data"][i]);
          }
        }
      } else {
        await getAllVsReport();
      }
    } catch (e) {
      checkTokenExpiration(e);
      /*setTimeout(() => {
        getAllVsReport();
      }, 3000);*/
    }
  };

  useEffect(() => {
    async function loadAllVsReport() {
      const response = await getAllVsReport();
    }

    loadAllVsReport();
  }, []);
  const openVsExtension = () => {
    window.open(
      "https://marketplace.visualstudio.com/items?itemName=Sixth.sixth&ssr=false#review-details",
      "_blank"
    );
  };
  return (
    <SdkPageMainDiv>
      <Header />
      <SdkPageContent>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {localStorage.getItem("vs_code_user") == false ? (
            <>
              {" "}
              <div
                style={{
                  width: "100%",
                  minHeight: "200px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <video
                  src={emptyAnimation}
                  style={{ height: "170px" }}
                  autoPlay={true}
                ></video>
              </div>
            </>
          ) : (
            <>
              <Spacer>
                {" "}
                <UserText>Hello There! ,</UserText>{" "}
                {
                  // <FormControlLabel
                  // control={
                  //   <MaterialUISwitch
                  //     onChange={(e) => openDeveloperMode(mode ? false : true)}
                  //     checked={mode}
                  //     theme={mode}
                  //     sx={{ m: 2 }}
                  //   />
                  // }
                  // label="Switch to company account"
                  // style={{ color: "rgba(0, 1, 3, 0.6)", fontWeight: "600" }}  />
                }{" "}
              </Spacer>

              {mode == false ? (
                <></>
              ) : (
                <>
                  {allVsReport.length > 0 ? (
                    <DeveloperError>
                      <UserText>
                        <span
                          style={{
                            margin: "0px",
                            marginBottom: "-2px",
                            color: "red",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <BiError
                            style={{
                              padding: "0px",
                              margin: "0px",
                              alignItems: "center",
                              marginBottom: "-4px",
                            }}
                            size={"30px"}
                          />
                        </span>
                        <span style={{ paddingBottom: "20px" }}>
                          {allVsReport.length} Problems
                        </span>
                      </UserText>
                    </DeveloperError>
                  ) : (
                    <DeveloperError>
                      <UserText problem={true}>you have no problems</UserText>
                    </DeveloperError>
                  )}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      marginBottom: "60px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <ReportApiBodyBorder>
                        <ReportBodyBorder_head>
                          <SubHeaderText_H2>Notice</SubHeaderText_H2>
                          <Report_line vertical={false} />
                        </ReportBodyBorder_head>
                        <Report_line></Report_line>
                        {sensitiveDataResults.current.length > 0 ? (
                          <>
                            {allVsReport.map((item, index) =>
                              index <= reportViewLength ? (
                                <ReportMainDiv>
                                  <ReportDiv>
                                    <Report_line vertical={true} />
                                    <Report_start>
                                      <RegularText>{item.category}</RegularText>
                                    </Report_start>
                                    <Report_line vertical={true} />
                                    <Report_description>
                                      <RegularText>
                                        {item.three_line_snippet[0]?.code}
                                        <br></br>
                                        {item.three_line_snippet[1]?.code}
                                        <br></br>
                                        {item.three_line_snippet[2]?.code}
                                      </RegularText>
                                    </Report_description>
                                  </ReportDiv>
                                  <Report_line vertical={false} />
                                </ReportMainDiv>
                              ) : (
                                <></>
                              )
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              flexDirection: "column",
                              color: "black",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <p
                                onClick={(e) => {
                                  openVsExtension();
                                }}
                                style={{ fontSize: "24px", fontWeight: "800" }}
                              >
                                Nothing to see here, please login on your vscode
                                to start viewing your reports
                              </p>
                              <video autoPlay loop style={{ width: "40%" }}>
                                <source
                                  src={emptyAnimation}
                                  autoPlay="true"
                                  type="video/mp4"
                                  style={{ width: "100%" }}
                                />
                                Your browser does not support the video tag.
                              </video>
                              <ReportLinkText>
                                <MainButtonStyle
                                  onClick={(e) => {
                                    openVsExtension();
                                  }}
                                >
                                  Link with vs-code
                                </MainButtonStyle>{" "}
                              </ReportLinkText>
                            </div>
                          </div>
                        )}
                      </ReportApiBodyBorder>
                    </div>
                    {sensitiveDataResults.current.length > reportViewLength ? (
                      <div
                        style={{
                          marginTop: "30px",
                          marginBottom: "30px",
                          height: "50px",
                          width: "150px",
                          display: "flex",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MainButtonStyle onClick={viewMore}>
                          <p>view more</p>
                        </MainButtonStyle>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </SdkPageContent>
    </SdkPageMainDiv>
  );
};

DeveloperReport.prototype = {
  developerMode: PropTypes.func,
  mode: PropTypes.bool,
};
export default DeveloperReport;
