import { GlobalLoaderMainDiv, LoaderIcon } from "../styles/globalLoader.styles";
import { Oval, TailSpin } from 'react-loader-spinner';




const GlobalLoader=()=>{

    return(
        <GlobalLoaderMainDiv>
            <LoaderIcon>
            <Oval
                                        height="100"
                                        width="100"
                                        color="#01579B"
                                        secondaryColor="#0091EA"
                                        ariaLabel="oval-loading"
                                        radius="5"
                                        strokeWidth={4}
                                        strokeWidthSecondary={4}
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        />
            </LoaderIcon>
        </GlobalLoaderMainDiv>
    )
};
export default GlobalLoader;