import styled from "styled-components";

export const Header_div = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
  padding-left: 50px;
  justify-content: flex-start;

  
  text-align: left;

  @media (max-width: 788px) {
    margin: 0px;
    padding: 0px;
    height: 50px;
   
  }
`;

export const Header_img_div =styled.div`
width;60%;
height:60%;

`;


export const Header_img_div_img =styled.img`
width;100%;
height:100%;
`
;



export const Header_div_text =styled.div`
display:flex;
align-items-center;
font-size:17px;
font-weight:600;
color:#161616;
text-align: left;


@media (max-width: 788px) {
    
    height:100%;
  }

`
;
