import { useContext, useEffect, useState } from "react";
import {
  BaseUrlCodeSampleDiv,
  BaseUrlCodeSampleHead,
  BaseUrlCodeType,
  BaseUrlCodeTypes,
  BaseUrlInputButton,
  BaseUrlInputDiv,
  GetStartedButton,
  GetStartedContent,
  GetStartedMainDiv,
} from "../../../styles/dashBoardStyles/rateLimiting/getStarted.styles";
import { DashBoardContext } from "../../../contextApis/dashboardContext";
import { useNavigate } from "react-router-dom";
import api from "../../../API/baseURL";
import Header from "../../header";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { VscCopy } from "react-icons/vsc";
import { Alert } from "@mui/material";

import {
  RegularText,
  SubHeaderText_H3,
} from "../../../styles/reusable/TextStyles";
import { AlertStyles } from "../../../styles/registerComponent.styles";
import { TbBrandPython } from "react-icons/tb";
import { FaNodeJs } from "react-icons/fa";
import { SiCodeigniter } from "react-icons/si";
import { SiLaravel } from "react-icons/si";
import { FaJava } from "react-icons/fa";

import {
  IntegrateWrap,
  IntegrateWrapDiv,
} from "../../../styles/dashBoardStyles/OverviewComponents/sdkPage.styles";
import { MainButtonStyle } from "../../../styles/reusable/inputTypes";
import { Oval } from "react-loader-spinner";
import useTokenExpired from "../../../apiLayer/expirationEvent";
import useBillingHook from "../../../applicationLayer/dashBoardHooks/billingHook";
import MainPaymentDialog from "../paymentDialogs/mainPaymentDialog";

const GetStarted = () => {
  const [getStarted, setGetStarted] = useState(false);
  const [checkTokenExpiration]=useTokenExpired();
  const { baseUrl, setBaseUrl } = useContext(DashBoardContext);
  const [checking, checkProjectConfiguration] = useState(false);
  const [alert, setAlertMessage] = useState({
    message: "",
    severity: "success",
    hasAlert: false,
  });
  const [disable,setDisable]=useState(false);
  const [userIsSubscribed,setUserIsSubscribed]=useState(null);
  const [buttonText,setButtonText]=useState("Check project configuration");
  const [showPaymentDialog,setShowPaymentDialog]=useState(false);
  const [paid,setPaid]=useState(false);
  const [selectedPlan,setSelectedPlan]=useState("");

  useEffect(()=>{
    if(paid==true){
      setButtonText("Check project configuration");
    }
  },[paid]);

  const closeAlert = () => {
    setAlertMessage({ message: "", severity: "success", hasAlert: false });
  };

  const navigate = useNavigate();

  const goToRateLimiter = async () => {
    navigate("/dashboard/rate-limit");
    //if (baseUrl.length > 0) {
    //  try {
    //    const response = await api.post(
    //      "/project-config/set-up-rate-limiter-config",
    //      { base_url: baseUrl },
    //      {
    //        headers: {
    //          Authorization: `Bearer ${localStorage.getItem("token")}`,
    //        },
    //      }
    //    );
    //    navigate("/dashboard/rate-limit");
    //  } catch (err) {}
    //}
  };

  const [fetchedData,userBilling,userInfo,userTotalBill,paymentResponse,apiErrors,
    setUserPlan,createSubscriptionForUser,getUserPaymentInfoApi,getUserInfoApi,checkPaymentSuccess,userSubscriptions,getUserSubsInfoApi]=useBillingHook();

  const copy = () => {
    let Val = codeString;
    navigator.clipboard.writeText(Val);
    document.execCommand("copy");
    setAlertMessage({
      message: "copied to clipboard",
      severity: "success",
      hasAlert: true,
    });
  };
  
  const openDocumentation = (url) => {
    window.open(url, "_blank");
   
  };

  const [codeString, setCodeString] = useState(
    'from sixth.sdk import Sixth  \nfrom fastapi import FastAPI  \napp = FastAPI  \n#initalize app, add routes, middleware, exception handlers etc  \n  \n  \n #....  \nSixth(apikey="api_key", app=app).init()  \nif __name__ == "__main__":  \nuvicorn.run(app, host=host, port=PORT)'
  );

  const [codeLanguage, setCodeLanguage] = useState("python");
  const [activeLanguage, setActiveInput] = useState("python");
  const [url, setUrl] = useState("https://docs.trysixth.com/installation/python/install-configure-and-verify");

  const setCodeTemplate = (snippet, language, name, url) => {
    setCodeLanguage(language);
    setCodeString(snippet);
    setActiveInput(name);
    setUrl(url);
  };



  const checkIfUserAddedCard=async()=>{
    setDisable(true);
    try{
      const response= await api.get(`/payment/get_user_subscriptions_and_plans`,{
        headers:{
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
        
    });
    setUserIsSubscribed(response.data.subscriber);
    if (response.data.subscriber===false){
      setButtonText("Add Billing Details");
        setAlertMessage({
          message: "Oops,seems you haven't added your billing details,you have to add your billing details to use this service.",
          severity: "error",
          hasAlert: true,
        });
      
    }else{
      if(response.data.plans!==null||response.data.plans!==undefined){
        if (!response.data.plans.includes("RATE_LIMITER")){
          setButtonText("Add Billing Details");
          setAlertMessage({
            message: "Oops,seems you haven't added your billing details,you have to add your billing details to use this service.",
            severity: "error",
            hasAlert: true,
          });
        }else{
          setButtonText("Check project configuration");
        }
        
      }else{
          setButtonText("Add Billing Details");
          setAlertMessage({
            message: "Oops,seems you haven't added your billing details,you have to add your billing details to use this service.",
            severity: "error",
            hasAlert: true,
          });
      }
      
    }
    setDisable(false);
    

    
    }catch(err){
      checkTokenExpiration(err);
      setDisable(true);
      setAlertMessage({
        message: "something went wrong,try again later",
        severity: "error",
        hasAlert: true,
      });
    }
  };



  const getExistingRateLimit = async () => {
    if (checking) return;
    closeAlert()
    checkProjectConfiguration(true);
    if(buttonText==="Check project configuration"){
      try {
        const response = await api.get("/payment/get_rate_limiter_enabledd", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if(response.data.rate_limiter_enabled===true){
          goToRateLimiter();
        }
        
      } catch (err) {
        console.log(err);
        if (
          err.response === undefined ||
          err.response.data.detail == "Config doesn't exist"
        ) {
          if(userIsSubscribed){
            setAlertMessage({
              message: "could not find your project config",
              severity: "error",
              hasAlert: true,
            });
          }
        }
        
      }
    }
    if(buttonText==="Add Billing Details"){
      //navigate(`/dashboard/billing`)
      setShowPaymentDialog(true);
    }
    checkProjectConfiguration(false);
  };


  


  useEffect(() => {
    getExistingRateLimit();
    checkIfUserAddedCard();
  }, []);

  return (
    <GetStartedMainDiv>
      {alert.hasAlert ? (
        <Alert
          sx={AlertStyles}
          onClose={() => closeAlert()}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      ) : (
        <></>
      )}
      {
        showPaymentDialog?
        <MainPaymentDialog setShowPaymentDialog={setShowPaymentDialog} 
          createSubscriptionForUser={createSubscriptionForUser}
          apiErrors={apiErrors}  
          userData={{"userInfo":userInfo,"userBilling":userBilling,"user_total_bill":userTotalBill}} setUserPlan={setUserPlan} paymentResponse={paymentResponse} 
          getUserPaymentInfoApi={getUserPaymentInfoApi} getUserInfoApi={getUserInfoApi}  checkPaymentSuccess={checkPaymentSuccess}
          setPaid={setPaid} setSelectedPlan={setSelectedPlan} initPlan={"RATE_LIMITER"}
          />
        :
        <></>
      }
      <Header />
      <GetStartedContent started={!getStarted}>
        {getStarted ? (
          <GetStartedButton onClick={(e) => setGetStarted(false)}>
            Get started
          </GetStartedButton>
        ) : (
          <BaseUrlInputDiv>
            {
              // // <BaseUrlInputHeader>
              //  INPUT YOUR APPLICATION'S BASE URL
              //</BaseUrlInputHeader>
              //<BaseUrlInputForm
              //  value={baseUrl}
              //  onChange={(e) => setBaseUrl(e.target.value)}
              //  placeholder="base url"
              ///>
            }
            <IntegrateWrapDiv style={{ width: "75vw", padding: "1rem" }}>
              <IntegrateWrap style={{ padding: "1rem" }}>
                <SubHeaderText_H3>
                  Chose your application language
                  
                </SubHeaderText_H3>
                <div>
                  <BaseUrlCodeTypes>
                    {languages.map((item, index) => (
                      <BaseUrlCodeType
                        onClick={(e) =>
                          setCodeTemplate(item.syntax, item.language, item.name,item.url)
                        }
                        active={activeLanguage === item.name}
                      >
                        {item.icon}
                        {item.name}
                      </BaseUrlCodeType>
                    ))}{" "}
                  </BaseUrlCodeTypes>
                </div>
                {codeString != "" ? (
                  <>
                    <BaseUrlCodeSampleDiv>
                      <BaseUrlCodeSampleHead>
                        <RegularText onClick={ (e) =>openDocumentation(url)}>view documentation</RegularText>
                        <RegularText
                          onClick={(e) => {
                            copy();
                          }}
                        >
                          <VscCopy size={15} /> copy{" "}
                        </RegularText>
                      </BaseUrlCodeSampleHead>
                      <SyntaxHighlighter
                        language={codeLanguage}
                        style={atomOneDark}
                        customStyle={{
                          padding: "1.2rem",
                          minWidth: "300px",
                          textAlign: "left",
                          width: "-webkit-fill-available",
                        }}
                        wrapLongLines={true}
                        showInlineLineNumbers={true}
                        startingLineNumber={0}
                      >
                        {codeString}
                      </SyntaxHighlighter>
                    </BaseUrlCodeSampleDiv>
                    <BaseUrlInputButton>
                      <MainButtonStyle disable={disable} onClick={(e) => getExistingRateLimit()}>
                        {checking === !true ? (
                          <> {buttonText} </>
                        ) : (
                          <>
                            Checking project configuration
                            <Oval
                              height="20"
                              width="20"
                              color="#757575"
                              secondaryColor="#BDBDBD"
                              ariaLabel="tail-spin-loading"
                              radius="2"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          </>
                        )}
                      </MainButtonStyle>
                    </BaseUrlInputButton>
                  </>
                ) : (
                  <></>
                )}
              </IntegrateWrap>
            </IntegrateWrapDiv>
          </BaseUrlInputDiv>
        )}
      </GetStartedContent>
    </GetStartedMainDiv>
  );
};

const languages = [
  {
    icon: <TbBrandPython size={30} />,
    name: "python",
    language: "python",
    url:"https://docs.trysixth.com/installation/python/install-configure-and-verify",
    syntax:'from sixth.sdk import Sixth  \nfrom fastapi import FastAPI  \napp = FastAPI  \n#initalize app, add routes, middleware, exception handlers etc  \n  \n  \n #....  \nSixth(apikey="api_key", app=app).init()  \nif __name__ == "__main__":  \nuvicorn.run(app, host=host, port=PORT)',
  },
  {
    icon: <FaNodeJs size={30} />,
    name: "nodeJs",
    url:"https://docs.trysixth.com/installation/node-js/install-configure-and-verify",
    language: "nodejs",
    syntax:
      'import  express  from "express";\nimport bodyParser from \'body-parser\'\nimport router from "./routes/user.js";\nimport Sixth from "sixth-node"; \n  \n\n \n \nconst app = express();\nconst sixth = new Sixth("api key", app) \nawait sixth.init()\n//initialize routes, add middlewares, add exception handlers etc \n \n \napp.use(bodyParser.json())\n \n\n//sync sixth with the routes, middlewares and excepetion handlers you add\nsixth.sync_project();\napp.listen(PORT, ()=> console.log(`Server running on port: http://localhost:${PORT}`))',
  },
  {
    icon: <SiCodeigniter size={30} />,
    name: "php( code igniter)",
    language: "php",
    url:"https://docs.trysixth.com/installation/php/install-configure-and-verify",
    syntax:
      "<?php\nuse SixthCodeigniterSdkSixth;\n/* \n \n\n* Code igniter initilaztions ....\n*/\n\n/*\n* ---------------------------------------------------------------\n * GRAB OUR CODEIGNITER INSTANCE\n* ---------------------------------------------------------------\n*\n* The CodeIgniter class contains the core functionality to make\n* the application run, and does all the dirty work to get\n* the pieces all working together.\n*/\n\n\n$app = ConfigServices::codeigniter();\n$app->initialize();\n$context = is_cli() ? 'php-cli' : 'web';\n$app->setContext($context);\n\n$sixth = new Sixth(\"apikey here\");\n$sixth->init();\n\n/*\n*---------------------------------------------------------------\n* LAUNCH THE APPLICATION\n*---------------------------------------------------------------\n* Now that everything is set up, it's time to actually fire\n* up the engines and make this app do its thang.\n*/\n\n$app->run();",
  },
  {
    icon: <SiLaravel size={30} />,
    name: "php (laravel)",
    url:"https://docs.trysixth.com/installation/php/install-configure-and-verify",
    language: "php",
    syntax:
      " <?php\n// app/Http/Controllers/MyController.php                                        \n\nnamespace AppHttpControllers;\n\nuse IlluminateHttpRequest;\nuse SixthSixth; // Import the Sixth SDK class\n\nclass MyController extends Controller\n{\n    public function index()\n    {\n        // Initialize Sixth SDK with API key\n        $apikey = 'api_key'; // Replace with your actual API key\n        $sixth = new Sixth(['apikey' => $apikey]);\n\n        // Initialize Laravel app, add routes, middleware, exception handlers etc        \n        // Call the init method with the Laravel app object\n        $sixth->init(app());\n\n        // Example:\n        return view('welcome/');\n    }\n}",
  },
  {
    icon: <FaJava size={30} />,
    name: "java",
    language: "java",
    url:"https://docs.trysixth.com/installation/java-springboot",
    syntax:
      '@SpringBootApplication                                                          \npublic class DemoApplication {\n\n  public static void main(String[] args) {\n    // initialize an instance of you spring application\n    SpringApplication app = new SpringApplication(DemoApplication.class);\n    // create an instance of Sixth SDK immediately after creating an instance of\n    // of the application\n    Sixth sdk = new Sixth("apikey", app);\n    // run the app\n    ApplicationContext ctx =  app.run(args);\n    // initialize all the necessary resources required for sixth to run properly\n    sdk.init(ctx);\n  }\n\n}',
  },
];
export default GetStarted;
