import styled from "styled-components";


export const ForgetPasswordMainDiv=styled.div`
width:100%;
height:100vh;
padding:20px;
box-sizing: border-box;
background-color:#F1F1F1;
`;

export const ForgetPasswordLogoDiv=styled.div`
width:100%;
display:flex;
align-items:center;
margin-bottom:40px;
box-sizing: border-box;
`;

export const LogoDivImg=styled.img`
width:45px;
border-radius:8px;
margin-right:6px;
margin-bottom:0px;
background:transparent;
`;

export const LogoDivText=styled.div`
font-size:25px;
font-weight:800;
`;

export const ForgetPasswordInnerBodyDiv=styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:center;
`;

export const ForgetPasswordBodyDiv=styled.div`
width:50%;
display:flex;
flex-direction:column;
align-items:center;
border-radius:10px;
padding:15px;
background-color:#FFFFFF;

@media (max-width: 820px) {
    width:90%;
  }
`;

export const BodyDivHeader=styled.div`
text-align:center;
font-size:18px;
font-weight:600;
margin-bottom:10px;
margin-top: 64px;
`;

export const BodyDivSecHeader=styled.div`
max-width:60%;
text-align:center;
font-size:13px;
margin-bottom:15px;
`;

export const BodyDivEmailForm=styled.input`
width:60%;
border: 0.5px solid rgba(129, 61, 232, 0.3);
padding:20px;
outline:none;
border-radius:5px;
margin-bottom:25px;
box-sizing: border-box;
font-size:15px;

:focus{
   
border: 0.5px solid rgba(129, 61, 232, 1);
    outline:none;
}

@media (max-width:728px){
    width:90%;
}
`;

export const BodyDivRequestLinkBtn=styled.button`
width:60%;
border-radius:5px;
padding:20px 0px;
display:flex;
justify-content:center;
align-items:center;
background: #F1E4FF;
border:none;
color:#8C30F5;

outline:none;
user-select:none;
transition:background 0.3s ease;
margin-bottom:20px;
font-family: Manrope;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;

:hover{
    background:#8C30F5;
    color:white;
}
`;

export const BodyDivBackToLoginBtn=styled.button`
width:60%;
border-radius:5px;
padding:20px 0px;
display:flex;
justify-content:center;
align-items:center;
background: transparent;
border:1px solid #8C30F5;
color:#8C30F5;

outline:none;
user-select:none;
transition:background 0.3s ease;
margin-bottom:20px;
font-family: Manrope;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;

:hover{
    background:#8C30F5;
    color:white;
    
}
`;