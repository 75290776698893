import styled from "styled-components";

export const BillingMainDiv = styled.div`
padding:30px 30px;
width:100%;
height:100%;
gap:25px;
box-sizing: border-box;
overflow-x:hidden;
overflow-y:auto;
background: #F9F9F9;
`;

export const MainBillingHeader=styled.div`
font-size: 24px;
font-weight: 600;
margin-bottom:10px;
`;

export const SubBillingHeader=styled.div`
font-size: 20px;
font-weight: 400;
margin-bottom:25px;
`;

export const BillingSumDiv=styled.div`
width:100%;
padding:15px;
display:flex;
flex-direction:column;
align-items:center;
margin-bottom:80px;
border-radius: 8px;
box-sizing: border-box;
box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
`;

export const BillingSumHeaderDiv=styled.div`
font-size: 25px;
font-weight: 600;
margin-bottom:5px;
`;

export const BillingSumInnerDiv=styled.div`
width:100%;
display:flex;
justify-content:space-between;
margin-bottom:60px;
`;

export const BillingSumInnerBillItem=styled.div`
width:21%;
display:flex;
flex-direction:column;
`;

export const InnerBillItemActivationBillDiv=styled.div`
width:100%;
display:flex;
margin-bottom:10px;
justify-content:space-between;
align-items:center;
`;

export const ActivationBillText=styled.div`
font-size: 18px;
font-weight: 500;
`;

export const ActivationBillDurationDiv=styled.div`
border-radius: 8px;
border:0.5px solid #8C30F5;
background:#F1E4FF;
padding:10px 15px;
color:#18191F;
font-size:14px;
`;

export const InnerBillItemCurrentBillDiv=styled.div`
width:100%;
display:flex;
justify-content:space-between;
align-items:center;
`;

export const CurrentBillText=styled.div`
font-size: 15px;
font-weight: 500;
margin-left:20px;
`;

export const CurrentBillDurationDiv=styled.div`
border-radius: 8px;
background:${(props)=>(props.bg?props.bg:"#E24848")};
padding:10px 15px;
color:white;
font-size:14px;
`;

export const BillingPeriodSubText=styled.div`
font-size: 15px;
font-weight: 500;
margin-top:4px;
`;

export const BillingPeriodButton=styled.button`
border:0.5px solid #8C30F5;
background:#F1E4FF;
border-radius: 8px;
padding: 13px 10px;
display:flex;
justify-content: center;
align-items: center;
outline:none;
margin-top:10px;
color:black;
:hover{
outline:none;
background:#8C30F5;
color:white;
}
`;

export const BillingTotalBillDiv=styled.div`
width:100%;
padding:15px;
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:30px;
border-radius: 8px;
box-sizing: border-box;
box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
`;

export const TotalBillTitleDiv=styled.div`
font-size: 25px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

export const TotalBillAmountDiv=styled.div`
display:flex;
flex-direction:column;
height:100%;
align-items:center;
`;

export const  AmountTitleDiv=styled.div`
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

export const AmountValueDiv=styled.div`
font-size: 25px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

export const TotalBillLinkCardButton=styled.button`
background:#8C30F5;
border-radius: 4px;
padding: 12px 10px;
display:flex;
justify-content: center;
align-items: center;
outline:none;
margin-top:6px;
color:white;
`;

export const SubListParentDiv=styled.div`
display:flex;
align-items:center;
`;

export const SubListDurationDiv=styled.div`
border-radius: 8px;
background:#198754;
padding:10px 15px;
color:white;
font-size:12px;
`;

export const SubListText=styled.div`
font-size: 17px;
font-weight: 500;
margin-left:20px;
`;

export const SubListItemTimeDiv=styled.div`
font-size:15px;
color:#49484a;
margin-top:10px;
`;
