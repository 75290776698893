import styled from "styled-components";


export const DocumentationNavMainDiv=styled.div`
width:100%;
padding:1% 1%;
top:0;
left:0;
display:flex;
position:sticky;
z-index: 100;
background:white;
box-shadow: -1px 5px 16px -10px rgba(0,0,0,0.63);
-webkit-box-shadow: -1px 5px 16px -10px rgba(0,0,0,0.63);
-moz-box-shadow: -1px 5px 16px -10px rgba(0,0,0,0.63);
justify-content:space-between;
align-items:center;
box-sizing: border-box;
`;

export const NavMainDivLogoAndName=styled.div`
display:flex;
align-items:center;
box-sizing: border-box;
`;

export const NavMainDivLogo=styled.img`
width:40px;
border-radius:8px;
margin-right:4px;
background:white;
`;

export const NavMainDivName=styled.div`
font-size:18px;
font-weight:800;
margin-left: 12px;
color:#020024;

:hover{
    cursor:pointer;
}
`;

export const NavMainDivSecNavDiv=styled.div`
display:flex;
box-sizing: border-box;
`;

export const SecMainDivItem=styled.div`
padding:10px 20px;
border-radius:25px;
margin-right:15px;
background:#303F9F;
font-size:14px;
font-weight:bold;
color:white;
transition:background 0.3s ease;
box-sizing: border-box;

:hover{
    cursor:pointer;
    background:#3F51B5;
}
`;