import { CodeBodyLineDiv, CodeInnerDivHeader, CodeInnerDivHeaderItem, CodeyBodyLineText, DocumentationBodyCodeBody, DocumentationBodyCodeInnerDiv, DocumentationBodyCodeOuterDiv, DocumentationBodyMainDiv, DocumentationBodyNormalText, DocumentationBodyNormalTextH, DocumentationBodyPrimaryHeader, DocumentationBodySecHeader, DocumentationBodyUlDiv } from "../../../../styles/documentationStyles/documentationBody.styles";




const DocSdkPython=()=>{

    const codeData={
        "python":{
            "1":[{"val":"from","color":"keywords"},{"val":"sixth_sense.sixth_sense","color":"normal"}, {"val":"import","color":"keywords"}, {"val":"SixthSense","color":"method"}],
            "2":[{"val":"from","color":"keywords"},{"val":"fastapi","color":"normal"}, {"val":"import","color":"keywords"}, {"val":"Fastapi","color":"normal"}],
            "3":[{"val":"app=","color":"normal"},{"val":"FastApi()","color":"method"}],
            "4":[{"val":"#","color":"keywords"},{"val":"initalize app, add routes, middleware, exception handlers etc","color":"normal"}],
            "5":[{"val":"SixthSense","color":"method"},{"val":"(apikey='api key', app=app).","color":"normal"},{"val":"init","color":"keywords"}, {"val":"()","color":"normal"}],      
        }
    }

    const codeLines=[1,2,3,4,5];

    const colors={
        "keywords":"#039BE5",
        "normal":"#616161",
        "strings":"#4CAF50",
        "method":"#FFC107"
    }

    return(
        <DocumentationBodyMainDiv>
            <DocumentationBodyPrimaryHeader>Python SDK</DocumentationBodyPrimaryHeader>
            <DocumentationBodyNormalText>Our python sdk allows integration with python based server side applications.</DocumentationBodyNormalText>
            <DocumentationBodyNormalText>The python SDK so far offers the following features:</DocumentationBodyNormalText>
            <DocumentationBodyUlDiv>
                <li>Rate limiting middleware</li>
                <li>Encryption middleware</li>
            </DocumentationBodyUlDiv>
            <DocumentationBodySecHeader>Installation</DocumentationBodySecHeader>
            <DocumentationBodyNormalText>You can install the python sdk into your python based server side applications with <DocumentationBodyNormalTextH>pip install six-python.</DocumentationBodyNormalTextH></DocumentationBodyNormalText>
            <DocumentationBodySecHeader>Example Usage</DocumentationBodySecHeader>
            <DocumentationBodyNormalText>Example below on how to add both rate limiting and encryption middlewares to your fast api application:</DocumentationBodyNormalText>
            <DocumentationBodyCodeOuterDiv>
                <DocumentationBodyCodeInnerDiv>
                    <CodeInnerDivHeader>
                        <CodeInnerDivHeaderItem show={true}>Python</CodeInnerDivHeaderItem>
                    </CodeInnerDivHeader>
                    <DocumentationBodyCodeBody>
                        {
                            codeLines.map((item,index)=>(
                                <CodeBodyLineDiv>
                                    {
                                    codeData["python"][item].map((item)=>(
                                        <CodeyBodyLineText color={colors[item.color]} >{item.val}</CodeyBodyLineText>
                                    ))
                                    }
                                </CodeBodyLineDiv>
                            ))
                        }
                    </DocumentationBodyCodeBody>
                </DocumentationBodyCodeInnerDiv>
            </DocumentationBodyCodeOuterDiv>
        </DocumentationBodyMainDiv>
    );
};
export default DocSdkPython;