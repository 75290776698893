import SignUpComponent from "../components/registerComponent";



const SignUp=()=>{

    return(
        <SignUpComponent/>
    );
};
export default SignUp;