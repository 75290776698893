import {
  BarHeaderLogo,
  BarHeaderText,
  FooterLogout,
  SideNavBarBody,
  SideNavBarFooter,
  SideNavBarHeader,
  SideNavBarMainDiv,
  SideNavHeaderLine,
} from "../../../styles/dashBoardStyles/sideNavBar.styles";

import { AiOutlineLogout } from "react-icons/ai";
import { useContext } from "react";
import { DashBoardContext } from "../../../contextApis/dashboardContext";
import waves from "../../../assets/waves.png";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../../contextApis/authContext";
import PropTypes from "prop-types";
import DeveloperNavBar from "./developerNavBar";
import CompanyNavBar from "./companyNavBar";

const SideNavBar = ({ developerMode, isHeader }) => {
  const navigate = useNavigate();
  const { logout } = UserAuth();

  const logoutUser = async () => {
    await logout();
    localStorage.clear();
    navigate("/login");
  };

  const { toggle, setToggle, setToggleValue } = useContext(DashBoardContext);

  return (
    <SideNavBarMainDiv header={isHeader}>
      <SideNavHeaderLine header={isHeader} />
      <SideNavBarHeader header={isHeader}>
        <BarHeaderLogo src={waves} />
        <BarHeaderText>Sixth</BarHeaderText>
      </SideNavBarHeader>
      <SideNavBarBody header={isHeader}>
        {localStorage.getItem("user_type") != "developer" ? (
          <CompanyNavBar
            setToggleValue={setToggleValue}
            toggle={toggle}
          ></CompanyNavBar>
        ) : (
          <DeveloperNavBar
            setToggleValue={setToggleValue}
            toggle={toggle}
          ></DeveloperNavBar>
        )}
      </SideNavBarBody>
      <SideNavBarFooter>
        <FooterLogout onClick={() => logoutUser()}>
          <AiOutlineLogout /> &nbsp;&nbsp; Sign out
        </FooterLogout>
      </SideNavBarFooter>
    </SideNavBarMainDiv>
  );
};

SideNavBar.prototype = {
  developerMode: PropTypes.bool,
};
export default SideNavBar;
