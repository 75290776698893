import styled, { keyframes } from "styled-components";

const scaleOut  = keyframes`
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
`;
export const StylizedTabMainDiv = styled.div`
width:100%;
height:46px;
box-shadow: 0px 4px 4px 0px #8C30F51A;
background: #18191F;



`;
export const StylizedTabBar_horizontal = styled.div`
height:3px;
width:100%;
background: #8C30F5;
margin:0px;
border-radius:5px;

animation: ${scaleOut} 0.2s linear ;
`;


export const StylizedTabBar_vertical = styled.div`
height:100%;
width:5px;
background: #8C30F5;
margin:0px;



`;


export const StylizedTabBar_Section = styled.div`
height:100%;
align-items:center;
display:flex;
text-align: ${(props) => (props.variant ==="false" ? "center": "left" )};
justify-content: ${(props) => (props.variant ==="false" ? "space-between": "space-between" )};
flex-direction: ${(props) => (props.variant ==="false" ? "column": "row" )};
color: ${(props) => (props.color ==="false" ? "white": "#8C30F5" )};
max-width:"500px";
gap: ${(props) => (props.variant ==="false" ? "0px": "1000px" )};
min-width:100px ;
cursor:pointer;


`;




export const StylizedTabBar_div = styled.div`
height:100%;
width:100%;
display:flex;
gap:10px;
align-item:center;
justify-content:  ${(props) => (props.position ==="" ? "flex-start": props.position )};;
flex-direction: ${(props) => (props.variant ==="false" ? "row": "column" )};

`;

