import { CodeBodyLineDiv, CodeInnerDivHeader, CodeInnerDivHeaderItem, CodeyBodyLineText, DocumentationBodyCodeBody, DocumentationBodyCodeInnerDiv, DocumentationBodyCodeOuterDiv, DocumentationBodyMainDiv, DocumentationBodyNormalText, DocumentationBodyNormalTextH, DocumentationBodyPrimaryHeader, DocumentationBodySecHeader, DocumentationBodyUlDiv } from "../../../../styles/documentationStyles/documentationBody.styles";




const DocSdkNode=()=>{

    const codeData={
        "javascript":{
            "1":[{"val":"from","color":"keywords"},{"val":"SixthSense","color":"method"},{"val":"import","color":"keywords"}, {"val":'"sixth-node";',"color":"strings"}],
            "2":[{"val":"import","color":"keywords"},{"val":"express","color":"method"},{"val":"from","color":"keywords"},{"val":"'express';","color":"strings"}],
            "3":[{"val":"const","color":"keywords"},{"val":"app =","color":"normal"}, {"val":"express","color":"strings"}, {"val":"();","color":"normal"}],
            "4":[{"val":"await","color":"keywords"},{"val":"six =","color":"normal"}, {"val":"new","color":"keywords"},{"val":"SixthSense","color":"method"}, {"val":"(apikey='api key', app=app).","color":"normal"},{"val":"init","color":"keywords"}, {"val":"();","color":"normal"}],
            "5":[{"val":"//add routes, middleware, exception handlers etc","color":"normal"}],
            "6":[{"val":"//after done adding routes, middleware, etc","color":"normal"}], 
            "7":[{"val":"six.","color":"normal"},{"val":"sync_project","color":"keywords"},{"val":"();","color":"normal"} ],
            "8":[{"val":"app.listen(PORT, ()=> console.log(`Server running on port: http://localhost:${PORT}`))","color":"normal"}], 
        }
    }

    const codeLines=[1,2,3,4, 5, 6, 7, 8];

    const colors={
        "keywords":"#039BE5",
        "normal":"#616161",
        "strings":"#4CAF50",
        "method":"#FFC107"
    }

    return(
        <DocumentationBodyMainDiv>
            <DocumentationBodyPrimaryHeader>Node SDK</DocumentationBodyPrimaryHeader>
            <DocumentationBodyNormalText>Our Node sdk allows integration with node/javascript based server side applications.</DocumentationBodyNormalText>
            <DocumentationBodyNormalText>The node SDK so far offers the following features:</DocumentationBodyNormalText>
            <DocumentationBodyUlDiv>
                <li>Rate limiting middleware</li>
                <li>Encryption middleware</li>
            </DocumentationBodyUlDiv>
            <DocumentationBodySecHeader>Installation</DocumentationBodySecHeader>
            <DocumentationBodyNormalText>You can install the node sdk into your node/javascript based server side applications with <DocumentationBodyNormalTextH>npm install sixth-node.</DocumentationBodyNormalTextH></DocumentationBodyNormalText>
            <DocumentationBodySecHeader>Example Usage</DocumentationBodySecHeader>
            <DocumentationBodyNormalText>Example below on how to add both rate limiting and encryption middlewares to your Express application:</DocumentationBodyNormalText>
            <DocumentationBodyCodeOuterDiv>
                <DocumentationBodyCodeInnerDiv>
                    <CodeInnerDivHeader>
                        <CodeInnerDivHeaderItem show={true}>Javascript</CodeInnerDivHeaderItem>
                    </CodeInnerDivHeader>
                    <DocumentationBodyCodeBody>
                        {
                            codeLines.map((item,index)=>(
                                <CodeBodyLineDiv>
                                    {
                                    codeData["javascript"][item].map((item)=>(
                                        <CodeyBodyLineText color={colors[item.color]} >{item.val}</CodeyBodyLineText>
                                    ))
                                    }
                                </CodeBodyLineDiv>
                            ))
                        }
                    </DocumentationBodyCodeBody>
                </DocumentationBodyCodeInnerDiv>
            </DocumentationBodyCodeOuterDiv>
        </DocumentationBodyMainDiv>
    );
};
export default DocSdkNode;