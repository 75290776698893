import React from 'react';
import './App.css';
import DashBoard from './pages/dashBoard';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import SignUp from './pages/signUp';
import { AuthContextProvider } from './contextApis/authContext';
import LoginComponent from './components/loginComponents';
import Documentation from './pages/documentation';
import SlackRedirectComp from './components/slackRedirectComp';
import Forgetpassword from './pages/ForgotPassword';
import NotFoundPage from './pages/notFoundPage';
import { initializeApp } from "firebase/app";
import TwoFactorPage from './pages/twoFactorAunthentication';



function App() 
{
  
  return (
    <Router>
      <AuthContextProvider>
      <Routes>
        <Route path="/" element={<LoginComponent/>}/>
        <Route path="/dashboard/*" element={<DashBoard/>} />
        <Route path="/signup" element={<SignUp/>}/>
        <Route path="/login" element={<LoginComponent/>}/>
        <Route path="/twoFactor" element={<TwoFactorPage/>}/>
       
        
        <Route path="/documentation/*" element={<Documentation/>}/>
        <Route path="/slack/redirect" element={<SlackRedirectComp/>}/>
        <Route path="/forget-password" element={<Forgetpassword/>}/>
        <Route path="*" element={<NotFoundPage/>}/>

        
      </Routes>
      </AuthContextProvider>
    </Router>
  );
}

export default App;